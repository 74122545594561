<script>
/**
 * CSS -> assets/sass/_quickTask.scss
 *
 */
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import VTripleTime from "@/components/Task/Widgets/VTripleTime";

import ActivityService from "@/services/ActivityService";
import TaskService from "@/services/TaskService";

import DayAttrs from "@/libraries/DayAttrs";

import { EventBus } from "@/libraries/EventBus";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import TipTap from "@/components/Editor/TipTap";

export default {
    name: "VQuickTask",
    props: {
        projectId: {
            default: 0
        },
        customerId: {
            required: true,
            description: "Customer ID"
        },
        internalCustomer: {
            type: Boolean,
            default: false,
            description: "Whether given customer is an internal customer"
        },
        allowCreate: {
            type: Boolean,
            default: true,
            description: "Allow creation of new tasks (status of the project)"
        },
        enableDateSync: {
            type: Boolean,
            default: false,
            description:
                "Whether to enable bus-based datepicker sync among all qregs"
        }
    },
    components: { VTripleTime, TipTap },
    data() {
        return {
            loading: false,
            processing: false,
            activities: null,
            details: null,
            time_reg: 0,
            activity_id: null,
            is_billable: true,
            task_date: null,
            project_milestone_id: null,
            attrs: [],
            hover: false,
            updateFromEvent: false,
            milestones: [],
            showDetails: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getIsAdmin", "getAuthUser"]),
        canSubmit: function() {
            if (
                this.time_reg > 0 &&
                this.activity_id > 0 &&
                this.task_date &&
                (this.milestones.length === 0 || this.project_milestone_id > 0)
            ) {
                return true;
            } else {
                return false;
            }
        },
        // Warning for future date
        futureDateSelected: function() {
            return dayjs(this.task_date).isAfter(new Date(), "day");
        },
        // Smart activities
        contextAwareActivities: function() {
            if (this.activities === null) {
                return [];
            }

            if (this.internalCustomer) {
                return this.activities.internal;
            } else {
                return this.activities.customer;
            }

            return [];
        }
    },
    watch: {
        activity_id: function(n) {
            if (!this.updateFromEvent) {
                console.log("EMIT", n);
                EventBus.$emit("qreg-activity-change", n);
            }
            this.updateFromEvent = false;
        }
    },
    async created() {
        this.initializeData();
        this.loading = true;
        await this.fetchActivities(false);
        await this.fetchMilestones();

        // Mark as non billable for internal customer
        if (this.internalCustomer) {
            this.is_billable = false;
        }

        if (this.enableDateSync) {
            //console.log("VQuickTask qreg-date-change $on");
            EventBus.$on("qreg-date-change", this.fixQregDate);
            if (this.getAuthUser.invoice_setup === "projects") {
                EventBus.$on("qreg-activity-change", this.fixActivityChange);
            }
        }

        this.$watch("task_date", this.handleTaskDate);
    },
    beforeDestroy() {
        if (this.enableDateSync) {
            EventBus.$off("qreg-date-change");
            if (this.getAuthUser.invoice_setup === "projects") {
                EventBus.$off("qreg-activity-change");
            }
        }
    },
    methods: {
        fixActivityChange(activity_id) {
            this.updateFromEvent = true;

            this.contextAwareActivities.forEach(e => {
                if (parseInt(e.value) === parseInt(activity_id)) {
                    this.activity_id = activity_id;
                }
            });
            this.updateFromEvent = false;
        },
        /**
         * task_date watcher
         */
        handleTaskDate(ev) {
            if (!this.updatingFromEvent) {
                //console.log("Task date change: EMIT", ev);
                EventBus.$emit("qreg-date-change", ev);
            }
            this.updatingFromEvent = false;
        },

        /**
         * Update from the event bus
         */
        fixQregDate(date) {
            this.updatingFromEvent = true;
            this.task_date = date;
            //console.log("Received date change req: ", date);
        },
        async fetchAttrs(a) {
            this.attrs = await DayAttrs.eventHandler(a);
        },
        async createTask() {
            if (this.canSubmit && this.processing === false) {
                this.processing = true;

                let payload = {
                    customer_id: this.customerId,
                    time_reg: this.time_reg,
                    task_date: this.task_date,
                    is_billable: this.is_billable,
                    activity_id: this.activity_id,
                    project_milestone_id: this.project_milestone_id,
                    details: this.details
                };

                if (this.projectId > 0) {
                    payload["project_id"] = this.projectId;
                }

                try {
                    const r = await TaskService.POST(payload);
                    let task = r.data.data;
                    // Emit that up ;)
                    this.$emit("save", task);
                    EventBus.$emit("task-created", task.id);

                    this.initializeData({ leaveDate: true });
                    this.$notify({
                        message: this.$t(
                            "quick_task.notify_quick_task_created"
                        ),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });
                    this.details = "";
                    this.showDetails = false;
                } catch (e) {
                    this.$notify({
                        message: this.$t(
                            "quick_task.notify_quick_task_error_during_creation"
                        ),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "danger"
                    });
                    console.log("Exception createTask", e);
                }

                this.processing = false;
            }
        },
        /**
         *
         */
        async fetchActivities(loading = true) {
            try {
                const r = await ActivityService.GET_taskDropdown();
                this.activities = r.data.data;
            } catch (e) {
                console.log("fetchActivities exception", e);
            }

            this.loading = loading;
        },
        initializeData({ leaveDate = false } = {}) {
            this.activity_id = null;
            this.time_reg = 0;

            // Important that reinitialization is done correct
            if (this.internalCustomer) {
                this.is_billable = false;
            } else {
                this.is_billable = true;
            }

            if (leaveDate === false) {
                this.task_date = dayjs().format("YYYY-MM-DD");
            }
        },
        /**
         * Fetch milestones dropdown
         * @param {} editing
         */
        async fetchMilestones() {
            try {
                const { data } = await ProjectMilestoneService.GET_dropdown(
                    this.projectId
                );
                this.milestones = data.data;
            } catch (e) {
                console.log("fMile e", e);
            }
        }
    }
};
</script>

<template>
    <div class="v-quick" @mouseenter="hover = true" @mouseleave="hover = false">
        <card body-classes="px-4">
            <template v-if="allowCreate">
                <div class="v-quick__container">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="contextAwareActivities.length">
                        <div class="v-quick__datepicker">
                            <date-picker
                                class=""
                                @update:from-page="fetchAttrs"
                                v-model="task_date"
                                :model-config="{
                                    type: 'string',
                                    mask: 'YYYY-MM-DD'
                                }"
                                :is-required="true"
                                :attributes="attrs"
                                :popover="{
                                    positionFixed: true,
                                    visibility: 'focus',
                                    keepVisibleOnInput: false,
                                    transition: 'none',
                                    showDelay: 1,
                                    hideDelay: 1
                                }"
                            >
                                <template v-slot="{ togglePopover }">
                                    <span
                                        class="m-click v-quick__datepicker--date"
                                        :class="
                                            futureDateSelected
                                                ? 'v-quick__datepicker--date-warn text-danger'
                                                : ''
                                        "
                                        @click="
                                            togglePopover({
                                                placement: 'bottom'
                                            })
                                        "
                                    >
                                        <i class="far fa-calendar-alt"></i>
                                        {{ $d(new Date(task_date), "short") }}
                                    </span>
                                    <div
                                        v-if="futureDateSelected"
                                        class="small text-danger"
                                    >
                                        {{
                                            $t(
                                                "task.notice_future_date_selected"
                                            )
                                        }}
                                    </div>
                                </template>
                            </date-picker>
                        </div>

                        <div class="v-quick__activity mr-2">
                            <FormulateInput
                                type="fselect"
                                v-model="activity_id"
                                :options="contextAwareActivities"
                                :placeholder="
                                    $t('quicktask.placeholder_select_activity')
                                "
                            />

                            <FormulateInput
                                v-if="milestones.length > 0"
                                type="fselect"
                                v-model="project_milestone_id"
                                :options="milestones"
                                validation="required"
                                :placeholder="
                                    $t('quicktask.placeholder_select_milestone')
                                "
                            />
                            <div
                                class="btn btn-link m-click"
                                @click="showDetails = !showDetails"
                            >
                                <i class="far fa-sticky-note fa-lg mt-2"></i>
                            </div>
                        </div>

                        <div class="v-quick__billable">
                            <div
                                class="v-quick__billable--text"
                                v-if="internalCustomer"
                            >
                                {{ $t("task.internal_time") }}
                            </div>
                            <template v-else>
                                <input
                                    type="checkbox"
                                    v-model="is_billable"
                                    :disabled="!getIsAdmin"
                                />
                                <div class="v-quick__billable--text">
                                    {{ $t("task.billable") }}
                                </div>
                            </template>
                        </div>

                        <div class="v-quick__timepicker">
                            <v-triple-time
                                v-model="time_reg"
                                :enable-controls="true"
                                :external-hover="hover"
                            />
                        </div>

                        <div class="v-quick__save">
                            <i
                                @click="createTask"
                                :disabled="processing"
                                class="far fa-check-circle fa-2x"
                                :class="{
                                    'cannot-submit m-na': !canSubmit,
                                    'm-click': canSubmit,
                                    'text-primary': canSubmit && !processing,
                                    'fa-check-circle': !processing,
                                    'fa-spinner fa-pulse text-muted': processing
                                }"
                            ></i>
                        </div>
                    </template>
                </div>
                <div class="row mt-2" v-show="showDetails">
                    <div class="col-6">
                        <FormulateInput
                            type="textarea"
                            :label="$t('quicktask.details')"
                            v-model="details"
                            rows="8"
                            :placeholder="$t('quicktask.placeholder_details')"
                        />
                    </div>
                    <div class="col-6"></div>
                </div>
            </template>

            <div v-else>
                <span class="text-warning">{{
                    $t("project.cannot_create_task_because_of_status")
                }}</span>
            </div>
        </card>
    </div>
</template>

<style></style>
