<script>
import SubscriptionService from "@/services/SubscriptionService";
import VConsumptionForm from "@/components/Forms/VConsumptionForm";

export default {
    props: {
        s: {}
    },
    components: { VConsumptionForm },
    data() {
        return {
            loading: true,
            invoices: [],
            showModal: false,
            context: { empty: true }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await SubscriptionService.GET_invoicePlan(
                    this.s.id
                );
                this.invoices = data.data;
            } catch (e) {
                console.log("fd e", e);
            }
            this.loading = false;
        },
        openModal(invoice) {
            this.context = invoice;
            this.showModal = true;
        },
        closeModal() {
            this.context = { empty: true };
            this.showModal = false;
        },
        async closeRefresh() {
            this.closeModal();
            await this.fetchData();
        }
    }
};
</script>

<template>
    <div>
        <v-loader v-if="loading" :loading="loading" />
        <template v-else-if="invoices.length > 0">
            <v-table class="table table-sm">
                <v-thead>
                    <v-tr>
                        <v-th class="text-center">{{
                            $t("subscriptions.s_hdr_invoice_ready")
                        }}</v-th>
                        <v-th>{{ $t("subscriptions.s_hdr_period") }}</v-th>
                        <v-th class="text-right">{{
                            $t("subscriptions.s_hdr_qty")
                        }}</v-th>
                        <v-th class="text-right">{{
                            $t("subscriptions.s_hdr_item_price")
                        }}</v-th>
                        <v-th class="text-right">{{
                            $t("subscriptions.s_hdr_final_value")
                        }}</v-th>
                        <v-th>{{
                            $t("subscriptions.s_hdr_invoice_text")
                        }}</v-th>
                        <v-th></v-th>
                    </v-tr>
                </v-thead>

                <v-tbody>
                    <v-tr v-for="(invoice, index) in invoices" :key="index">
                        <v-td class="text-center">
                            <i
                                class="fa fa-fw fa-lg"
                                :class="{
                                    'fa-check text-success': invoice.ready,
                                    'fa-times text-danger': !invoice.ready
                                }"
                            ></i>
                        </v-td>
                        <v-td
                            >{{ invoice.period_start }} -
                            {{ invoice.period_end }}</v-td
                        >
                        <template v-if="invoice.ready">
                            <v-td class="text-right">{{
                                $n(invoice.consumption.qty, "usage")
                            }} {{ s.unit_name }}</v-td>
                            <v-td class="text-right">{{
                                $n(invoice.consumption.item_price, "usage")
                            }}</v-td>
                            <v-td class="text-right">{{
                                $n(invoice.amount, {
                                    style: "currency",
                                    currency: invoice.currency,
                                    currencyDisplay: "code"
                                })
                            }}</v-td>
                        </template>
                        <template v-else>
                            <v-td class="text-right">---</v-td>
                            <v-td class="text-right">---</v-td>
                            <v-td class="text-right">---</v-td>
                        </template>

                        <v-td>{{ invoice.invoice_text }} </v-td>
                        <v-td class="text-right">
                            <base-button
                                type="primary"
                                size="sm"
                                @click="openModal(invoice)"
                            >
                                <i class="fa fa-pencil fa-lg"></i>
                            </base-button>
                        </v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
        </template>
        <template v-else> </template>

        <portal to="modals">
            <v-consumption-form
                :context="context"
                v-if="showModal"
                @close="closeModal"
                @close-refresh="closeRefresh"
            />
        </portal>
    </div>
</template>
