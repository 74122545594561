<script>
import Cloner from "@/libraries/Cloner";
import ProductService from "@/services/ProductService";
import SubscriptionLineService from "@/services/SubscriptionLineService";

export default {
    props: {
        subscriptionId: {},
        currency: {},
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    computed: {},
    data() {
        return {
            loading: true,
            editing: false,
            errors: {},
            products: [],
            line: {
                id: null,
                subscription_id: null,
                product_id: null,
                invoice_line_text: null,
                price: null,
                notes: null
            }
        };
    },
    async created() {
        await this.fetchProducts();

        console.log(this.context);
        if (!this.context.empty) {
            this.editing = true;
            this.line = Cloner.pickClone(this.context, [
                "id",
                "subscription_id",
                "product_id",
                "invoice_line_text",
                "price",
                "notes"
            ]);
        } else {
            this.line.subscription_id = this.subscriptionId;
        }

        this.loading = false;
    },
    methods: {
        async fetchProducts() {
            try {
                const { data } = await ProductService.GET_dropdown(
                    "subscription"
                );
                this.products = data.data;
            } catch (e) {
                console.log("pe", e);
            }
        },
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await SubscriptionLineService.PUT(this.line);
                } else {
                    const r = await SubscriptionLineService.POST(this.line);
                }

                this.$emit("close-refresh");
            } catch (e) {
                if (e.response.status == 403) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_no_access"),
                        text: e.response.data.errors.join(".\n")
                    });
                } else {
                    this.errors = e.response.data.errors;
                }
            }
        },
    }
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="subscription-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("consumption.edit") }}
            </template>
            <template v-else>
                {{ $t("consumption.create") }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <FormulateForm v-model="line" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="product_id"
                            type="select"
                            :options="products"
                            :label="$t('subscription.product')"
                            :validation-name="$t('subscription.product')"
                            :placeholder="$t('subscription.select_product')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="price"
                            type="currency"
                            :currency="currency"
                            number-style="money"
                            :label="$t('subscription.amount')"
                            :validation-name="$t('subscription.amount')"
                            :placeholder="$t('subscription.provide_amount')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="notes"
                            type="textarea"
                            :label="$t('subscription.notes')"
                            :validation-name="$t('subscription.notes')"
                            :placeholder="$t('subscription.optional_notes')"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
