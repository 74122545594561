<script>
import VManualInvoiceForm from "@/components/Forms/VManualInvoiceForm";
import InvoiceService from "@/services/InvoiceService";

export default {
    name: "VManualInvoice",
    components: {
        VManualInvoiceForm,
    },
    props: {
        contract: {
            type: Object,
            description: "Contract",
        },
    },
    data() {
        return {
            loading: false,
            invoice: { empty: true },
            editing: false,
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.editing = false;
            this.loading = true;
            try {
                const r = await InvoiceService.GET_ManualInvoice(
                    this.contract.id
                );
                
                this.invoice = r.data.data;                
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
    },
};
</script>

<template>
    <div v-if="contract.contract_type == 2 && ACL_RW" class="mb-4">
        <div class="px-lr">
            <v-loader :loading="loading" v-if="loading" />
            <p class="m-click" @click="editing = true" v-else>
                <template v-if="!invoice.empty">
                    <i class="far fa-edit"></i>

                    Betalt honorar i 2020 (klik for at redigere):
                    <b>{{
                        $n(invoice.total_net_amount, {
                            style: "currency",
                            currency: invoice.currency,
                        })
                    }}</b> 
                </template>
                <template v-else>
                    <i class="far fa-plus"></i> Klik for at tilføje betalt honorar
                    i 2020 (til reguleringsfaktura; hvor meget har kunden betalt i 2020?)
                </template>
            </p>
        </div>
        <div class="">
            <portal to="modals">
                <v-manual-invoice-form
                    v-if="editing"
                    :contract="contract"
                    :context="invoice"
                    @close="editing = false"
                    @saved="fetchData"
                />
            </portal>
        </div>
    </div>
</template>

<style>
</style>