<script>
import BudgetService from "@/services/BudgetService";
import VBudgetForm from "@/components/Forms/VBudgetForm";

export default {
    name: "VBudget",
    components: {
        VBudgetForm,
    },
    props: {
        contract: {
            type: Object,
            description: "Contract",
        },
        defaultLimit: {
            type: Number,
            default: 3,
        },        
    },
    data() {
        return {
            budgets: [],
            meta: {},
            loading: false,
            editing: false,
            editContext: null,
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiSearchFilter = { contract_id: this.contract.id };
        this.apiPaginator.sort = "year";
        this.apiPaginator.direction = "desc";

        if (this.ro) {
            // Show all results for read only
            this.apiPaginator.limit = 999;
        } else {
            this.apiPaginator.limit = this.defaultLimit;
        }
        this.fetchData();
    },
    methods: {
        showAllYears() {
            this.apiPaginator.limit = 999;
            this.fetchData();
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await BudgetService.GET(params);
                this.budgets = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            console.log("Budget context", ctx);
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
    },
};
</script>

<template>
    <div>
        <div class="px-lr">
            <div class="mg-table-title">
                <div class="mg-table-title__title">
                    <h3>{{ $t("budget.budget") }}</h3>
                </div>
                <div class="mg-table-title__actions">
                    <div class="mg-table-title__actions__left">
                        <span
                            v-if="ACL_RW"
                            class="m-click text-primary"
                            @click="setContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{ $t("budget.add_budget") }}</span
                        >
                    </div>

                    <div class="mg-table-title__actions__right">
                        <span
                            class="m-click text-primary"
                            v-if="
                                !loading && meta.pagination.total > defaultLimit
                            "
                            @click="showAllYears"
                            ><i class="far fa-plus"></i>
                            {{ $t("budget.show_all_years") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="mg-budget">
            <v-loader :loading="loading" v-if="loading" />
            <template v-else-if="budgets.length">
                <div class="table-responsive">
                    <v-table class="table table-mg">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("budget.year") }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.kwh_actual")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.kwh_budget")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.turnover_actual")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.turnover_budget")
                                }}</v-th>
                                <v-th v-if="ACL_RW"></v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="budget in budgets" :key="budget.id">
                                <v-td>{{ budget.year }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.actual_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.planned_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.actual_revenue, {
                                        style: "currency",
                                        currency: contract.currency,
                                    })
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.planned_revenue, {
                                        style: "currency",
                                        currency: contract.currency,
                                    })
                                }}</v-td>
                                <v-td class="text-right" v-if="ACL_RW">
                                    <i
                                        class="far fa-pencil text-muted fa-lg m-click"
                                        @click="setContext(budget)"
                                    ></i>
                                </v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </template>

            <div v-else class="alert alert-warning alert-outline ml-4 mr-4">
                {{ $t("budget.no_data") }}
            </div>

            <portal to="modals">
                <v-budget-form
                    v-if="editing"
                    :context="editContext"
                    :contract="contract"
                    @close="editing = false"
                    @close-refresh="closeSave"
                />
            </portal>
        </div>
    </div>
</template>