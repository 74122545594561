<script>
import VSubscriptionLineForm from "@/components/Forms/VSubscriptionLineForm.vue";
import SubscriptionLineService from "@/services/SubscriptionLineService";
import VProduct from "@/components/Momentum/Subscriptions/VProduct";
import Messages from "@/mixins/Messages";

export default {
    props: {
        s: {}
    },
    mixins: [Messages],
    components: { VSubscriptionLineForm, VProduct },
    data() {
        return {
            loading: true,
            showModal: false,
            lines: [],
            context: { empty: true },
            meta: {}
        };
    },
    async created() {
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await SubscriptionLineService.GET_lines(
                    this.s.id
                );
                this.lines = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd, e", e);
            }
        },
        openModal(ctx) {
            this.context = ctx;
            this.showModal = true;
        },
        closeModal() {
            this.context = { empty: true };
            this.showModal = false;
        },
        closeRefresh() {
            this.closeModal();
            this.fetchData();
        },
        deleteLine(line) {
            this.$swal({
                icon: "warning",
                text: this.$t("subscription.are_you_sure_delete_line"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    try {
                        const r = await SubscriptionLineService.DELETE(line.id);
                        this.$msgSuccess(
                            this.$t("subscription.notify_line_deleted")
                        );
                        this.fetchData();
                    } catch (e) {
                        this.$handleExceptionError(
                            e,
                            this.$t(
                                "subscription.swal_error_something_went_wrong"
                            )
                        );
                    }
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <div class="px-4 d-flex align-items-center">
            <h3>{{ $t("subscription.existing_invoice_lines") }}</h3>
            <base-button
                class="ml-auto"
                size="sm"
                type="primary"
                @click="openModal({ empty: true })"
                ><i class="fa fa-plus"></i>
                {{ $t("subscription.add_new_line") }}</base-button
            >
        </div>

        <v-loader :loading="loading" v-if="loading" />
        <template v-else-if="lines.length > 0">
            <div class="px-4 mt-3">
                <h4 class="text-info">
                    {{ $t("subscription.total_price") }}:
                    {{
                        $n(meta.sum, {
                            style: "currency",
                            currency: s.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </h4>
            </div>
            <v-table class="table mt-5">
                <v-thead>
                    <v-tr>
                        <v-th>{{ $t("subscription.sl_hdr_product") }}</v-th>
                        <v-th class="text-right">{{
                            $t("subscription.sl_hdr_price")
                        }}</v-th>
                        <v-th>{{ $t("subscription.sl_hdr_notes") }}</v-th>
                        <v-th></v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="(line, idx) in lines">
                        <v-td>
                            <v-product
                                :lang="s.customer.lang"
                                :product="line.product"
                            />
                        </v-td>
                        <v-td class="text-right"
                            >{{
                                $n(line.price, {
                                    style: "currency",
                                    currency: s.currency,
                                    currencyDisplay: "code"
                                })
                            }}
                        </v-td>
                        <v-td>
                            <template v-if="line.notes">
                                {{ line.notes }}
                            </template>
                            <template v-else>---</template>
                        </v-td>
                        <v-td class="text-right">
                            <base-button type="danger" size="sm" @click="deleteLine(line)"
                                ><i class="fa fa-trash"></i
                            ></base-button>
                            <base-button type="primary" size="sm" @click="openModal(line)"
                                ><i class="fa fa-pencil"></i
                            ></base-button>
                        </v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
        </template>
        <div v-else class="px-4 mt-5">
            <div class="alert alert-warning">
                {{ $t("subscriptions.no_invoice_lines_defined") }}
            </div>
        </div>

        <portal to="modals">
            <v-subscription-line-form
                :context="context"
                :subscription-id="s.id"
                :currency="s.currency"
                v-if="showModal"
                @close="closeModal"
                @close-refresh="closeRefresh"
            />
        </portal>
    </div>
</template>
