<script>
export default {
    props: {
        s: {}
    }
};
</script>

<template>
    <span>
        <template v-if="parseInt(s.subscription_type) === 2">---</template>
        <template v-else>
            {{
                $n(s.amount, {
                    style: "currency",
                    currency: s.currency,
                    currencyDisplay: "code"
                })
            }}
        </template>
    </span>
</template>
