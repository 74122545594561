<script>
export default {
    props: {
        contract: {}
    }
};
</script>

<template>
    <span>
        <template v-if="contract.active === 1">
            <template v-if="contract.termination_date">
                <i class="far fa-exclamation-triangle text-danger"></i>
                {{ $t("contract.active_under_termination") }}
            </template>
            <template v-else>
                <i class="far fa-check text-success"></i>
                {{ $t("contract.active_active") }}
            </template>
        </template>

        <template v-else-if="contract.active === 0">
            <i class="far fa-times text-danger"></i>
            {{ $t("contract.active_inactive") }}
        </template>

        <div v-if="contract.termination_date" class="text-danger small">
            {{ $t("contract.termination_date") }}:
            {{ $d(new Date(contract.termination_date), "short") }}
        </div>
    </span>
</template>
