<script>
export default {
    props: {
        contractDetail: {},
        enableEditing: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cd() {
            return this.contractDetail;
        },
        calcs() {
            return this.contractDetail.src_calculations;
        }
    }
};
</script>

<template>
    <div>
        <h2>{{ $t("cd.hdr_totals") }}</h2>
        <dl>
            <dt>{{ $t("cd.total_paid") }}</dt>
            <dd>
                {{
                    $n(cd.reg_total_paid, {
                        style: "currency",
                        currency: cd.currency,
                        currencyDisplay: "code"
                    })
                }}
            </dd>

            <dt>{{ $t("cd.total_actual") }}</dt>
            <dd>
                {{
                    $n(cd.reg_total_actual, {
                        style: "currency",
                        currency: cd.currency,
                        currencyDisplay: "code"
                    })
                }}
            </dd>

            <dt>{{ $t("cd.difference") }}</dt>
            <dd>
                {{
                    $n(cd.base_fee, {
                        style: "currency",
                        currency: cd.currency,
                        currencyDisplay: "code"
                    })
                }}
            </dd>
        </dl>
        <h2>{{ $t("cd.hdr_periods") }}</h2>
        <div class="row">
            <!-- Left DL -->
            <div
                v-for="(cd, index) in contractDetail.src_calculations.periods"
                class="col"
                :key="index"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="regulation-widget">
                            <div class="regulation-widget__dates">
                                {{ $t("cd.period") }}:
                                {{ $d(new Date(cd.paid.date_from), "short") }} -
                                {{ $d(new Date(cd.paid.date_to), "short") }}
                            </div>

                            <div class="regulation-widget__paid">
                                <div class="row px-5">
                                    <div class="col text-right">
                                        {{ $t("cd.paid_amount") }}
                                    </div>
                                    <div class="col text-left">
                                        {{
                                            $n(cd.paid.base_fee, {
                                                style: "currency",
                                                currency: cd.paid.currency,
                                                currencyDisplay: "code"
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="regulation-widget__actual">
                                <div class="row px-5">
                                    <div class="col text-right">
                                        {{ $t("cd.actual_amount") }}
                                    </div>
                                    <div class="col text-left">
                                        {{
                                            $n(cd.actual.base_fee, {
                                                style: "currency",
                                                currency: cd.paid.currency,
                                                currencyDisplay: "code"
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.regulation-widget {
    text-align: center;
    border: 1px solid $primary;
    border-radius: 6px;
    padding: 15px;

    &__dates {
        color: $gray-800;
        margin-bottom: 20px;
    }

    &__paid {
        font-size: 20px;
        font-weight: 600;
        color: $primary;
    }

    &__actual {
        font-size: 20px;
        border-top: 2px dashed $gray-400;
        font-weight: 600;
        color: $default;
    }
}
</style>
