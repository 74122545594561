<script>
import SingleDetailsModal from "@/components/Momentum/NewContracts/SingleDetailsModal";
import VBudgetHalfForm from "@/components/Forms/VBudgetHalfForm";

export default {
    props: {
        cd: {},
        year: {},
        currency: {
            type: String,
            default: ""
        }
    },
    components: { SingleDetailsModal, VBudgetHalfForm },
    data() {
        return {
            showDetailsModal: false,
            showHalfBudget: false
        };
    },
    computed: {
        isExtra() {
            if (parseInt(this.cd.entry_type) === 3) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        closeRefreshHalfBudget() {
            this.$emit('reload');
            this.showHalfBudget = false;
        }
    }
};
</script>

<template>
    <div
        class="v-contract-timeline-regulation"
        :class="`regulation-type-${cd.entry_type}`"
    >
        <div class="row d-flex align-items-center">
            <div class="col reg-invoice">
                {{ cd.entry_type_name }}
            </div>
            <div class="col ">
                <div class="cd-status" :class="`cd-status-${cd.status}`">
                    {{ cd.status_name }}
                </div>
                <template v-if="isExtra && cd.status != 2">
                    <base-button
                        @click="showHalfBudget = true"
                        size="sm"
                        type="primary"
                        outline
                        block
                        class="mt-2"
                        >{{ $t("cd.define_actual_usage") }}</base-button
                    >
                </template>
                <base-button
                    block
                    size="sm"
                    type="primary"
                    outline
                    class="mt-2"
                    v-if="cd.status > 0"
                    @click="showDetailsModal = true"
                >
                    {{ $t("cd.details") }}
                </base-button>
            </div>
            <div class="col">
                <i class="far fa-file-invoice-dollar fa-fw"></i
                >{{ cd.invoicing_date }} 
                <div v-if="isExtra" class="small mt-2 pt-2" style="border-top: 1px solid #ddd">
                    {{$t('cd.revenue')}}: {{ $n(cd.half_real_revenue, {style: 'currency', currency: currency, currencyDisplay: 'code'}) }} <br />
                    {{$t('cd.production')}}: {{ $n(cd.half_real_production_kwh) }} kWh
                    

                </div>
            </div>
            <div class="col">
                <template v-if="cd.status > 0">
                    {{
                        $n(cd.reg_total_paid, {
                            style: "currency",
                            currency: cd.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </template>
                <template v-else>---</template>
                <div class="field-title">
                    {{ $t("contract_detail.total_paid") }}
                </div>
            </div>
            <div class="col">
                <template v-if="cd.status > 0">
                    {{
                        $n(cd.reg_total_actual, {
                            style: "currency",
                            currency: cd.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </template>
                <template v-else>---</template>
                <div class="field-title">
                    {{ $t("contract_detail.actual_usage") }}
                </div>
            </div>
            <div class="col">
                <template v-if="cd.status > 0">
                    {{
                        $n(cd.base_fee, {
                            style: "currency",
                            currency: cd.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </template>
                <template v-else>---</template>
                <div class="field-title">
                    {{ $t("contract_detail.regulation_fee") }}
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <portal to="modals">
            <single-details-modal
                v-if="showDetailsModal"
                :contract-detail="cd"
                @close="showDetailsModal = false"
            />
            <v-budget-half-form
                v-if="showHalfBudget"
                @close="showHalfBudget = false"
                @close-refresh="closeRefreshHalfBudget"
                :contract-detail="cd"
                :currency="currency"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-contract-timeline-regulation {
    border: 1px solid $gray-600;
    border-radius: 6px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 15px;

    &.regulation-type-3 {
        background-color: $gray-100;
        border: 1px dashed $gray-700;
    }

    .reg-invoice {
        font-size: 14px;
        font-weight: 600;
        color: $default;
    }

    .col {
        text-align: center;

        .field-title {
            color: $text-gray;
            font-size: 11px;
        }
    }

    .cd-status {
        text-align: center;
        border-radius: 6px;
        font-size: 13px;
        padding-top: 4px;
        padding-bottom: 4px;

        &-0 {
            color: $warning;
            border: 1px solid $warning;
        }

        &-1 {
            color: $success;
            border: 1px solid $success;
        }

        &-2 {
            color: $default;
            border: 1px solid $default;
        }
    }
}
</style>
