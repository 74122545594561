<script>
import CustomerService from "@/services/CustomerService";
import NaviBlock from "@/components/Navigation/NaviBlock";

export default {
    name: "VManage",
    props: {
        customer_id: {},
    },
    components: { NaviBlock },
    data() {
        return {
            customer: null,
            loading: false,
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await CustomerService.GETS(this.customer_id);
                this.customer = r.data.data;
            } catch (e) {
                console.log("Exception", e);
            }
            this.loading = false;
        },
    },
};
</script>

<template>
    <div>
        <v-loader :loading="loading" v-if="loading" />
        <template v-else-if="customer">
            <base-header class="pb-1" type="">
                <bread-bag
                    :crumbs="{
                        title: customer.name,
                        chain: [null, $t('breadcrumbs.details')],
                    }"
                >
                    <div class="row top-navi-block">
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersManage',
                                }"
                                :title="$t('customers.top_menu_overview')"
                            />
                        </div>
                        <div class="col" v-if="customer.internal_customer == 1">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabInternalTime',
                                }"
                                :title="$t('customers.top_menu_internal_time')"
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabCases',
                                }"
                                :title="$tc('customers.top_menu_cases', customer.count_cases)"
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabContracts',
                                }"
                                :title="$tc('contracts.top_menu_contracts', customer.count_contracts)"
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabSubscriptions',
                                }"
                                :title="$tc('contracts.top_menu_subscriptions', customer.count_subscriptions)"
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabInvoices',
                                }"
                                :title="$t('customers.top_menu_invoice_archive')"
                            />
                        </div>
                    </div>
                </bread-bag>

                <div class="row" v-if="false">
                    <div class="col-3">
                        <nav-stats-card
                            :title="$t('customers.data_and_stats')"
                            :value="$t('customers.overview')"
                            type="default"
                            icon="fal fa-th-large"
                            :navigate="{ name: 'CustomersManage' }"
                        />
                    </div>

                    <div class="col-3">
                        <nav-stats-card
                            :title="
                                $tc('customers.x_cases', customer.count_cases)
                            "
                            :value="$t('customers.cases')"
                            type="default"
                            icon="fal fa-file-invoice"
                            :navigate="{ name: 'CustomersTabCases' }"
                        />
                    </div>

                    <div class="col-3">
                        <nav-stats-card
                            :title="
                                $tc(
                                    'customers.x_contracts',
                                    customer.count_contracts
                                )
                            "
                            :value="$t('contracts.contracts')"
                            type="default"
                            icon="fal fa-file-signature"
                            :navigate="{ name: 'CustomersTabContracts' }"
                        />
                    </div>

                    <div class="col-3">
                        <nav-stats-card
                            :title="$t('customers.economic_live_data')"
                            :value="$t('customers.invoice_archive')"
                            type="default"
                            icon="fal fa-file-invoice-dollar"
                            :navigate="{ name: 'CustomersTabInvoices' }"
                        />
                    </div>
                </div>
            </base-header>

            <router-view name="customer_tab"></router-view>
        </template>
        <div v-else class="alert alert-warning">
            {{ $t("customer.not_found") }}
        </div>
    </div>
</template>