<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import InnerDashCard from "@/components/Cards/InnerDashCard";

import DateRangeMixin from "@/mixins/DateRangeMixin";
import CustomerService from "@/services/CustomerService";
import DepartmentService from "@/services/DepartmentService";
import UserService from "@/services/UserService";

export default {
    name: "VInternalTimeStats",
    props: {
        customer_id: {},
    },
    components: { InnerDashCard, DateRangePicker },
    mixins: [DateRangeMixin],
    data() {
        return {
            loading: false,
            stats: null,
            users: [],
            deps: [],
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        hasData: function () {
            if (this.activityBreakdown.series.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        activityBreakdown: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            this.stats.activityBreakdown.forEach((el) => {
                data.series.push(el.total_time_perc);
                data.labels.push(el.activity_name);
            });

            return data;
        },
        teamBreakdown: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            this.stats.teamBreakdown.forEach((el) => {
                data.series.push(el.total_time_perc);
                data.labels.push(el.user_name);
            });

            return data;
        },
    },
    watch: {},
    async created() {
        this.loading = true;
        this.$aclGuard(this.$ACL.TIMEREG_MGMT);
        this.resetRange();
        await this.fetchUsers(true);
        await this.fetchDepartments(true);
        await this.fetchData();
        this.$apiEnableWatcher();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await CustomerService.GET_internalTimeStatistics(
                    this.customer_id,
                    params
                );
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchData e", e);
            }

            this.loading = false;
        },
        async fetchDepartments(loading = false) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }

            this.loading = loading;
        },
        async fetchUsers(loading = false) {
            try {
                this.loading = true;
                const r = await UserService.dropdownAll();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers e", e);
            }
            this.loading = loading;
        },
        resetRange() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                user_id: "",
                department_id: "",
            };
        },
    },
};
</script>

<template>
    <div class="v-customers-itime">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12 project-navigation__submenu">
                    <div
                        class="row d-flex justify-content-between project-navigation__submenu--container"
                    >
                        <router-link
                            class="col project-navigation__submenu--box"
                            :to="{ name: 'CustomersTabInternalTime' }"
                            >{{ $t("customer.internal_time") }}</router-link
                        >

                        <router-link
                            class="col project-navigation__submenu--box"
                            :to="{ name: 'CustomersTabInternalTimeStats' }"
                            >{{ $t("project.statistics") }}</router-link
                        >
                    </div>
                </div>
            </div>
            <!-- .top-menu -->
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center flex-row mb-3">
                        <div class="v-stats-rp">
                            <date-range-picker
                                class="range-picker"
                                ref="picker"
                                opens="right"
                                :timePicker="false"
                                :showWeekNumbers="true"
                                :autoApply="true"
                                v-model="apiSearchFilter.dateRange"
                                :linkedCalendars="true"
                                :locale-data="getDateRangePickerLocale()"
                                :ranges="getDateRangePickerRanges()"
                            >
                                <template #header>
                                    <manual-range
                                        :date-range-manual="dateRangeManual"
                                        :date-range-error="dateRangeError"
                                        @exec="
                                            execManualRange(
                                                'apiSearchFilter',
                                                'dateRange'
                                            )
                                        "
                                /></template>
                                <template
                                    v-slot:input="picker"
                                    style="min-width: 350px"
                                >
                                    <template v-if="picker.startDate">
                                        {{ $d(picker.startDate, "short") }}
                                        -
                                        {{ $d(picker.endDate, "short") }}
                                    </template>
                                </template>
                            </date-range-picker>
                        </div>

                        <div class="ml-3 extra-filter">
                            <FormulateInput
                                type="select"
                                :options="users"
                                v-model="apiSearchFilter.user_id"
                                :placeholder="
                                    $t('stats.placeholder_select_user')
                                "
                            />
                        </div>
                        <div class="ml-3 extra-filter">
                            <FormulateInput
                                type="select"
                                :options="deps"
                                v-model="apiSearchFilter.department_id"
                                :placeholder="
                                    $t('stats.placeholder_select_department')
                                "
                            />
                        </div>
                        <div
                            class="text-muted small m-click m-link ml-3"
                            @click="resetRange"
                        >
                            <i class="far fa-sync"></i>
                            {{ $t("stats.reset") }}
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="loading">
                    <v-loader :loading="true" />
                </div>
                <div class="col-12" v-else-if="!hasData">
                    <div class="alert alert-warning alert-outline">
                        {{ $t("inv_stats.no_data_found_try_different_period") }}
                    </div>
                </div>

                <div class="col-12" v-else>
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activity_distribution") }}
                        </h3>

                        <div class="px-4">
                            <!-- Stat for non-internal customers -->
                            <div class="row v-hours-week__statboxes">
                                <!-- Box -->
                                <pre v-if="false">{{ stats }}</pre>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_time')
                                        "
                                        icon="far fa-clock"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.internal_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                            </div>
                            <!-- End of boxes -->
                            <!-- Graph -->
                            <!-- Billable and non billable time (amount of hrs) stacked per day for a given period -->
                            <div class="col-12 v-hours-week__graph">
                                <div style="display: block">
                                    <apexchart
                                        v-if="true"
                                        height="400px"
                                        :options="{
                                            chart: {
                                                width: '100%',
                                                type: 'bar',
                                                stacked: true,
                                            },
                                            xaxis: {
                                                type: 'datetime',
                                                labels: {
                                                    format: 'dd/MM',
                                                },
                                            },
                                        }"
                                        :series="[
                                            {
                                                name: $t(
                                                    'inv_stats.non_billable_time'
                                                ),
                                                data: stats.graph.series
                                                    .internal_time,
                                            },
                                        ]"
                                    ></apexchart>
                                    <pre v-if="false">{{ stats.graph }}</pre>
                                </div>
                            </div>
                            <!-- End of Graph -->
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->

            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activity_distribution") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: activityBreakdown.labels,

                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="activityBreakdown.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.team_distribution") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: teamBreakdown.labels,
                                    legend: {
                                        position: 'right',
                                    },
                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="teamBreakdown.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activities") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.activity") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>

                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.activityBreakdown"
                                :key="index"
                            >
                                <v-td>{{ u.activity_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>

                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ stats.activityBreakdown }}</pre>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.team_members") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.user") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>

                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.teamBreakdown"
                                :key="index"
                            >
                                <v-td>{{ u.user_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>

                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ stats.teamBreakdown }}</pre>
                    </card>
                </div>
            </div>
        </div>
        <!-- .container-fluid -->
    </div>
</template>

<style lang="scss">
.v-stats-rp {
    flex: 0 0 300px;
}

.extra-filter {
    .form-group {
        margin-bottom: 0px !important;
    }
}
</style>
