<script>
/**
 * Events:
 * `reload`: reload cds & budgets
 */
import SingleDetailsModal from "@/components/Momentum/NewContracts/SingleDetailsModal";
import ContractDetailService from "@/services/ContractDetailService";

export default {
    props: {
        cd: {},
        stats: {},
        budgetColors: {},
        enableEditing: {
            type: Boolean,
            default: false
        }
    },
    components: { SingleDetailsModal },
    data() {
        return {
            showBudgetBtn: false,
            showDetailsModal: false,
            newLength: 0
        };
    },
    computed: {
        availableLengths() {
            if (this.cd.period_length == 1) {
                return [];
            }

            let opts = [];
            for (let i = 1; i < this.cd.period_length; i++) {
                opts.push({
                    value: i,
                    label: this.$tc("cd.shrink_to_COUNT_months", i)
                });
            }

            return opts;
        }
    },
    created() {
        this.newLength = this.cd.period_length - 1;
    },
    methods: {
        /**
         * Show details of a given period
         * @param cd
         */
        showContractDetails(cd) {
            this.showDetailsModal = true;
        },
        async doShrink() {
            this.$swal({
                icon: "warning",
                text: this.$tc(
                    "cd.confirm_shrink_period_to_LENGTH",
                    this.newLength
                ),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.shrinkContractDetail();
                }
            });
        },
        async shrinkContractDetail() {
            try {
                const r = await ContractDetailService.PUT_shrink(this.cd.id, {
                    new_length: this.newLength
                });
                this.$emit("reload");
            } catch (e) {
                console.log("err", e);
                // todoshowerrors
            }
        },
        /**
         * Void the period = new invoice.
         */
        async doVoid() {
            this.$swal({
                icon: "warning",
                text: this.$t("cd.confirm_voiding_period"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.voidContractDetail();
                }
            });
        },
        /**
         * Exec the call
         */
        async voidContractDetail() {
            try {
                const r = await ContractDetailService.PUT_void(this.cd.id);
                this.$emit("reload");
            } catch (e) {
                console.log("err", e);
                // todoshowerrors
            }
        }
    }
};
</script>

<template>
    <div>
        <!-- Time range -->
        <div class="contract-timeline__inner">
            <div class="c-date text-center py-2">
                {{ $d(new Date(cd.date_from), "dm") }} -
                {{ $d(new Date(cd.date_to), "dm") }}
            </div>
            <div class="c-length text-center pb-2">
                {{ $t("contract_details.months") }}: {{ cd.period_length }}
            </div>
        </div>
        <!-- Invoice date -->
        <div class="contract-timeline__inner mt-2 py-1">
            <div class="c-date text-center">
                <i class="far fa-file-invoice-dollar fa-fw"></i>
                {{ $d(new Date(cd.invoicing_date), "dm") }}
            </div>
        </div>

        <div
            class="contract-timeline__content mt-2 py-1 text-center"
            :style="
                cd.contract.contract_type == 1
                    ? 'min-height: 80px;'
                    : 'min-height: 180px;'
            "
        >
            <div v-if="cd.status > 0" class="mt-1">
                <i
                    class="fa fa-exclamation-triangle text-warning"
                    :title="$t('cd.uses_force_min_fee')"
                    v-if="
                        'force_min_fee' in cd.src_calculations &&
                            cd.src_calculations.force_min_fee == 1
                    "
                ></i>
                {{
                    $n(cd.base_fee, {
                        style: "currency",
                        currency: cd.contract.currency,
                        currencyDisplay: "code"
                    })
                }}
            </div>

            <!-- Fixed contract data (there is nothing)-->

            <!-- Variable contract data -->
            <template v-if="cd.status !== 0 && cd.contract.contract_type == 2">
                <div>{{ $n(cd.base_fee_perc, "decimal") }} %</div>

                <div>
                    <div>
                        ({{ $t("contract_detail.min_fee") }}:
                        <template v-if="cd.base_fee_min > 0">{{
                            $n(cd.base_fee_min, {
                                style: "currency",
                                currency: cd.contract.currency,
                                currencyDisplay: "code"
                            })
                        }}</template
                        ><template v-else>---</template>)
                    </div>
                </div>
            </template>
            <!-- END OF Variable contract data -->

            <!-- Budget -->
            <template v-if="cd.contract.contract_type == 2">
                <div
                    class="budget-indicator m-click"
                    @click="$emit('edit-budget', cd)"
                    :class="cd.budget_id > 0 ? budgetColors[cd.budget_id] : ''"
                    @mouseover="showBudgetBtn = true"
                    @mouseout="showBudgetBtn = false"
                >
                    <template v-if="cd.budget_id > 0">
                        #{{ cd.budget_id }}
                    </template>
                    <template v-else>
                        <i
                            class="text-danger text-bold far fa-exclamation-triangle fa-lg mt-3 mb-2"
                        ></i>
                    </template>

                    <div class="small">
                        <template v-if="!showBudgetBtn">
                            {{ $t("contract_detail.budget_#NUM") }}
                        </template>
                        <template v-else>
                            <i class="far fa-pencil"></i>
                            {{ $t("budget.click_to_edit") }}
                        </template>
                    </div>
                </div>
            </template>
            <!-- Conditions link -->
            <div class="contract-timeline__container mt-2 py-1 text-center">
                <span class="text-bold">#{{ cd.contract_condition_id }}</span>
                <div class="small text-muted">
                    {{ $t("contract_details.contract_conditions") }}
                </div>
            </div>
        </div>
        <!-- STATUS OF THE contract_detail -->
        <div
            :class="
                `contract-detail-status contract-detail-status-${cd.status}`
            "
        >
            <span class="text-bold">{{ cd.status_name }}</span>
            <div class="small text-muted">
                <template v-if="cd.id in stats.status.periods">
                    {{
                        $n(stats.status.periods[cd.id], {
                            style: "currency",
                            currency: cd.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </template>
                <template v-else>{{ $t("contract_detail.status") }}</template>
            </div>
        </div>

        <!-- Details button -->
        <div class="contract-timeline__container mt-2 py-1 text-center">
            <base-button
                size="sm"
                outline
                block
                :disabled="cd.status === 0"
                type="primary"
                @click="showContractDetails(cd)"
                >{{ $t("contract_detail.details") }}</base-button
            >
        </div>

        <!-- Editing surface -->
        <div
            class="py-2 px-1 text-center contract-timeline__editing"
            v-if="enableEditing"
        >
            <template v-if="cd.is_void === 0 && cd.period_length > 1">
                <div class="row">
                    <div class="col-6">
                        <base-button
                            v-if="cd.is_void === 0"
                            type="danger"
                            size="sm"
                            block
                            @click="doShrink"
                            ><i class="far fa-trash"></i>
                            {{ $t("cd.shrink_this_period") }}
                            <div class="small">
                                {{ $t("cd.shrink_means_behold") }}
                            </div></base-button
                        >
                    </div>
                    <div class="col-6">
                        <FormulateInput
                            type="select"
                            :value="newLength"
                            :options="availableLengths"
                            v-model="newLength"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="mb-3">{{ $t("cd.or") }}</div>
            </template>

            <base-button
                v-if="cd.is_void === 0"
                type="danger"
                size="sm"
                block
                @click="doVoid"
                ><i class="far fa-trash"></i> {{ $t("cd.void_this_period") }}
                <div class="small">
                    {{ $t("cd.void_means_new_invoice") }}
                </div></base-button
            >
        </div>

        <!-- MODALS -->
        <portal to="modals">
            <single-details-modal
                v-if="showDetailsModal"
                :contract-detail="cd"
                @close="showDetailsModal = false"
            />
        </portal>
    </div>
</template>
