export default {
    computed: {
        statusColor: function () {
            
            

            let status = parseInt(this.project.status);
            //console.log("Status for", status, this.project);

            switch (status) {
                // Awaiting
                case 1:
                    return "text-warning";
                    break;
                // Active
                case 5:
                    return "text-primary";
                    break;
                // Finalized
                case 99:
                    return "text-muted";
                    break;
                // Default -> fix because it's red
                default:
                    return "text-danger";
                    break;
            }
        },
        progressColor: function () {            
            
            if (
                this.project.budget_amount == 0 ||
                this.project.budget_amount == null
            ) {
                return "green";
            }

            let bu = parseInt(
                100 *
                    (this.project.used_budget_amount /
                        this.project.budget_amount)
            );

            if (bu >= 0 && bu < 80) {
                return "green";
            } else if (bu >= 80 && bu <= 99) {
                return "yellow";
            } else if (bu >= 100) {
                return "red";
            } else {
                return "green";
            }
        },
    }
}