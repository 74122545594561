<script>
import ManageMulti from "@/views/Customers/Tabs/components/ManageMulti";
import ManageConsumptions from "@/views/Customers/Tabs/components/ManageConsumptions";
import SubscriptionService from "@/services/SubscriptionService";
import VProduct from "@/components/Momentum/Subscriptions/VProduct";

export default {
    props: {
        customer_id: {},
        subscription_id: {}
    },
    components: { ManageMulti, ManageConsumptions, VProduct },
    data() {
        return {
            loading: false,
            s: null
        };
    },
    async created() {
        await this.fetchSubscription();
    },
    methods: {
        async fetchSubscription() {
            try {
                this.loading = true;
                const { data } = await SubscriptionService.GETS(
                    this.subscription_id
                );
                this.s = data.data;
            } catch (e) {
                console.log("fs e", e);
            }

            this.loading = false;
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <v-loader :loading="loading" v-if="loading" />
        <div class="row card-wrapper" v-else>
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>
                            {{
                                $t(
                                    "subscriptions.subscription_details_for_N_subs_id",
                                    { N: subscription_id }
                                )
                            }}
                        </h1>
                    </div>
                    <div class="mg-title__right"></div>
                </div>
                <card class="contract" mini-header-classes="text-center">
                    <div class="row">
                        <div class="col">
                            <div class="text-bold mb-1">
                                {{ $t("subscriptions.type") }}
                            </div>

                            {{ s.subscription_type_name }}
                        </div>
                        <div class="col">
                            <div class="text-bold mb-1">
                                {{ $t("subscriptions.frequency") }}
                            </div>

                            {{ s.frequency }}
                            <div class="small text-muted">
                                {{ s.payment_strategy_name }}
                            </div>
                        </div>

                        <div class="col" v-if="s.subscription_type != 3">
                            <div class="text-bold mb-1">
                                {{ $t("subscriptions.product") }}
                            </div>
                            <div class="small">
                                <v-product :lang="s.customer.lang" :product="s.product" />
                            </div>
                        </div>

                        <div class="col">
                            <div class="text-bold mb-1">
                                {{ $t("subscriptions.notes") }}
                            </div>

                            <template v-if="s.description">{{
                                s.description
                            }}</template>
                            <template v-else>---</template>
                        </div>
                    </div>
                </card>

                

                <card
                    class="contract"
                    body-classes="px-0"
                    mini-header-classes="text-center"
                    
                >
                    <manage-consumptions
                        v-if="s.subscription_type == 2"
                        :s="s"
                    />
                    <manage-multi v-if="s.subscription_type == 3" :s="s" />
                </card>
            </div>
        </div>
    </div>
</template>
