<script>
import InvoiceService from "@/services/InvoiceService";

export default {
    props: {
        year: {},
        contract: {},
        contractDetails: {}
    },
    data() {
        return {
            loading: true,
            invoices: [],
            meta: {}
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await InvoiceService.GET({
                    contract_id: this.contract.id,
                    invoice_type: ["contract", "contract_regulation", "contract_extra_regulation"
                    ],
                    contract_year: this.year,
                    nopagination: 1
                });

                this.invoices = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd", e);
            }

            this.loading = false;
        }
    }
};
</script>

<template>
    <div class="contract-invoices">
        <h3 class="mx-4">{{ $t("invoices.contract_invoices_sent_to_customer") }}</h3>
        <v-loader :loading="loading" v-if="loading" />
        <template v-else-if="invoices.length > 0">
            <v-table class="table table-sm table-mg">
                <v-thead>
                    <v-tr>
                        <v-th>{{ $t("invoice.hdr_date") }}</v-th>
                        <v-th>{{ $t("invoice.hdr_type") }}</v-th>
                        <v-th class="text-right">{{
                            $t("invoice.hdr_amount")
                        }}</v-th>
                        <v-th>{{ $t("invoice.hdr_texts") }}</v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="invoice in invoices" :key="invoice.id">
                        <v-td>{{
                            $d(new Date(invoice.invoice_date), "short")
                        }}</v-td>
                        <v-td>{{ invoice.invoice_type_name }}</v-td>
                        <v-td class="text-right">{{
                            $n(invoice.total_net_amount, {
                                style: "currency",
                                currency: invoice.currency,
                                currencyDisplay: "code"
                            })
                        }}</v-td>
                        <v-td>
                            <div>
                                <span class="text-bold"
                                    >{{ $t("invoice.header") }}:</span
                                >
                                {{ invoice.invoice_header }}
                            </div>
                            <div>
                                <span class="text-bold"
                                    >{{ $t("invoice.text1") }}:</span
                                >
                                {{ invoice.invoice_text1 }}
                            </div>
                        </v-td>
                        <v-td v-if="false">
                            <pre>{{ invoice }}</pre>
                        </v-td>
                    </v-tr>
                </v-tbody>
                <v-tfoot>
                    <v-tr class="sum-line">
                        <v-td colspan="2" class="text-right">{{
                            $t("TOTAL")
                        }}</v-td>
                        <v-td class="text-right">{{
                            $n(meta.stats.sum, {
                                style: "currency",
                                currency: meta.stats.currency,
                                currencyDisplay: "code"
                            })
                        }}</v-td>
                    </v-tr>
                </v-tfoot>
            </v-table>
        </template>
        <div class="alert alert-warning mx-4 mt-3" v-else>
            {{ $t("invoices.no_invoices_available_for_this_contract") }}
        </div>
    </div>
</template>

<style lang="scss">
.contract-invoices {
}
</style>
