<script>
import AssetService from "@/services/AssetService";
import VAssetForm from "@/components/Forms/VAssetForm";
import CountryList from "@/libraries/CountryList";

export default {
    name: "VAssets",
    components: {
        VAssetForm,
    },
    props: {
        contract: {
            type: Object,
            description: "Contract ID",
        },
    },
    data() {
        return {
            assets: [],
            loading: false,
            editing: false,
            editContext: null,
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.fetchData();
    },
    methods: {
        toCountry: CountryList.toCountry,
        async fetchData() {
            try {
                this.loading = true;
                const r = await AssetService.GET({
                    contract_id: this.contract.id,
                });
                this.assets = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            console.log("Assets context", ctx);
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
    },
};
</script>

<template>
    <div>
        <div class="px-lr">
            <div class="mg-table-title">
                <div class="mg-table-title__title">
                    <h3>{{ $t("assets.h3_header") }}</h3>
                </div>
                <div class="mg-table-title__actions">
                    <div class="mg-table-title__actions__left">
                        <span
                            v-if="ACL_RW"
                            class="m-click text-primary"
                            @click="setContext({ empty: true })"
                        >
                            <i class="far fa-plus"></i>
                            {{ $t("assets.add_asset") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="mg-assets">
            <v-loader :loading="loading" v-if="loading" />

            <template v-else-if="assets.length">
                <div class="table-responsive">
                    <v-table class="table table-mg">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("asset.class") }}</v-th>
                                <v-th>{{ $t("asset.type") }}</v-th>
                                <v-th class="text-center">{{
                                    $t("asset.qty")
                                }}</v-th>
                                <v-th class="text-center">{{
                                    $t("asset.mw")
                                }}</v-th>
                                <v-th class="text-center">{{
                                    $t("asset.subsidy_expiration")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("asset.value")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("asset.location")
                                }}</v-th>
                                <v-th v-if="ACL_RW"></v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="asset in assets" :key="asset.id">
                                <v-td>{{ asset.asset_group.name }}</v-td>
                                <v-td>{{
                                    asset.asset_group.asset_type.name
                                }}</v-td>
                                <v-td class="text-center">{{ asset.qty }}</v-td>
                                <v-td class="text-center">{{
                                    asset.rating_mw
                                }}</v-td>
                                <v-td class="text-center">
                                    <template
                                        v-if="asset.subsidy_expiration_date"
                                    >
                                        {{
                                            $d(
                                                new Date(
                                                    asset.subsidy_expiration_date
                                                ),
                                                "short"
                                            )
                                        }}
                                    </template>
                                    <template v-else>---</template>
                                </v-td>
                                <v-td class="text-right">
                                    <template v-if="asset.asset_value > 0">
                                        {{
                                            $n(asset.asset_value, {
                                                style: "currency",
                                                currencyDisplay: "code",
                                                currency:
                                                    asset.asset_value_currency,
                                            })
                                        }}
                                    </template>

                                    <template v-else>---</template>
                                </v-td>
                                <v-td class="text-right">
                                    <template v-if="asset.location_country">
                                        {{ toCountry(asset.location_country) }}
                                    </template>
                                    <template v-else>---</template>
                                </v-td>
                                <v-td class="text-right" v-if="ACL_RW">
                                    <i
                                        class="far fa-pencil text-muted m-click fa-lg"
                                        @click="setContext(asset)"
                                    ></i>
                                </v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </template>

            <div class="alert alert-warning alert-outline ml-4 mr-4" v-else>
                {{ $t("assets.no_data") }}
            </div>
        </div>
        <portal to="modals">
            <v-asset-form
                v-if="editing"
                :context="editContext"
                :contract="contract"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>
