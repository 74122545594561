<script>
import VMdayTag from "@/components/Momentum/Misc/VMdayTag";
import ContractService from "@/services/ContractService";
import CustomerService from "@/services/CustomerService";
import CurrencyService from "@/services/CurrencyService";
import AssetTypeService from "@/services/AssetTypeService";
import UserService from "@/services/UserService";
import MGConsts from "@/mixins/MGConsts";
import EcService from "@/services/EcService";
import SVars from "@/components/Settings/SVars";
import SettingService from "@/services/SettingService";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VContractForm",
    mixins: [MGConsts],
    components: {
        VMdayTag,
        SVars
    },
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        method: {
            type: String,
            description: "Edit method: quick, full, create etc.",
            default: "create"
        },
        customer_id: {
            description: "Customer ID"
        }
    },
    data() {
        return {
            canEditInFull: false,
            vars: null,
            showInvoicingOptions: false,
            mdayValidation: false,
            mday: "",
            editing: false,
            loading: false,
            errors: null,
            currencies: [],
            asset_types: [],
            layouts: [],
            termsOfPayment: [],
            users: [],
            ec_products: [],
            contract: {
                id: null,
                customer_id: null,
                asset_type_id: null,
                from_date: null,
                to_date: null,
                notice: null,
                termination_date: null,
                termination_note: null,
                finance_responsible_id: null,
                operations_responsible_id: null,
                sharepoint_url: null,
                currency: null,
                termination_notices: [],
                invoice_heading: null,
                invoice_text1: null,
                invoice_text2: null,
                invoice_term_of_payment: null,
                invoice_layout: null,
                invoice_override_product_text: null,
                invoice_extra_text: null,
                invoice_prefix_text: null,
                internal_note: null,
                economic_product: null
            }
        };
    },
    computed: {
        selectedCustomer() {
            return this.contract.customer_id;
        },
        /**
         * Check if invoice options should be open
         */
        hasInvoicingOptions: function() {
            let check = [
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
                "invoice_override_product_text",
                "invoice_extra_text"
            ];

            let show = false;

            check.forEach(value => {
                if (this.contract[value]) {
                    show = true;
                }
            });

            //console.log("hasinvoice", show);

            return show;
        },
        /**
         * Find out if a given field is disabled
         * (depending on the edit method)
         *
         * As for now limit only on quick.
         */
        isDisabled: function() {
            if (this.method == "quick") {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.loading = true;
        await this.fetchCurrencies();
        await this.fetchAssetTypes();
        await this.fetchEcProducts();
        if (!this.context.empty) {
            await this.fetchUsers(true);
        } else {
            await this.fetchUsers();
        }
        await this.fetchEconomicParams();
        await this.fetchSettings();

        if (!this.context.empty) {
            this.editing = true;

            this.contract = Cloner.pickClone(this.context, [
            "id",
            "customer_id",
            "asset_type_id",
            "from_date",
            "to_date",
            "notice",
            "termination_date",
            "termination_note",
            "finance_responsible_id",
            "operations_responsible_id",
            "sharepoint_url",
            "currency",
            "termination_notices.[]",
            "invoice_heading",
            "invoice_text1",
            "invoice_text2",
            "invoice_term_of_payment",
            "invoice_layout",
            "invoice_override_product_text",
            "invoice_extra_text",
            "invoice_prefix_text",
            "internal_note",
            "economic_product",
            ]);

            // Clean change_note if it's a full -> then it should be replaced
            if (this.method == "full") {
                this.contract.change_note = "";
                this.contract.valid_from = "";
            }
        } else {
            this.contract.customer_id = this.customer_id;
            this.method = "create";
        }

        await this.fixCurrency();
        this.loading = false;
        console.log("Edit method: " + this.method);
        //console.log("CONTRACT", this.contract);
    },
    methods: {
        /**
         * Fix contract currency
         */
        async fixCurrency() {
            console.log("Fixing currency");
            try {
                const { data } = await CustomerService.GETS(
                    this.contract.customer_id
                );
                console.log(data);
                this.$set(
                    this.contract,
                    "currency",
                    data.data.default_currency
                );
            } catch (e) {
                console.log("fix c", e);
            }
        },
        /**
         * Save the contract
         */
        async saveForm() {
            try {
                const r = await ContractService.PUT(this.contract);
                this.$emit("close-refresh");
            } catch (e) {
                console.log("saveform e", e);
                if (e.response.status == 409) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("contract.error_during_edit_replace"),
                        text: e.response.data.errors.join(".\n")
                    });
                } else {
                    this.errors = e.response.data.errors;
                }
            }
        },
        /**
         *
         */
        async deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("contract.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await ContractService.DELETE(
                                this.contract.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: e.response.data.errors.join(".\n")
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error")
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes({
                    fview: "active"
                });
                this.currencies = r.data.data;
            } catch (e) {}
        },
        /**
         * Get asset types
         */
        async fetchAssetTypes() {
            try {
                const r = await AssetTypeService.dropdown();
                this.asset_types = r.data.data;
            } catch (e) {}
        },
        /**
         * Fetch users
         */
        async fetchUsers(fetchInactive = false) {
            try {
                const params = fetchInactive ? { include_inactive: true } : {};
                const r = await UserService.dropdown(params);
                this.users = r.data.data;
            } catch (e) {}
        },
        /**
         * Fetch settings data + vars
         */
        async fetchSettings() {
            try {
                const r2 = await SettingService.getNamespaceVariables(
                    "invoice_templates"
                );
                this.vars = r2.data.data;
            } catch (e) {}
        },
        /**
         * Remove notice
         */
        removeNotice(date) {
            let check = this.contract.termination_notices.findIndex(el => {
                return el.notice_date == date;
            });
            this.$delete(this.contract.termination_notices, check);
        },
        /**
         * add notice
         */
        addNotice() {
            let check = this.contract.termination_notices.findIndex(el => {
                return el.notice_date == this.mday;
            });

            if (check == -1) {
                this.contract.termination_notices.push({
                    notice_date: this.mday
                });

                this.mday = "";
            }
        },
        /**
         * Padding
         */
        pad(num, size) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        },
        /**
         * Fetch economic params
         */
        async fetchEconomicParams() {
            try {
                const r = await EcService.ecLayoutsAndTerms();
                this.layouts = r.data.data.layouts;
                this.layouts.unshift({
                    label: this.$t("use_default"),
                    value: ""
                });

                this.termsOfPayment = r.data.data.terms_of_payment;
                this.termsOfPayment.unshift({
                    label: this.$t("use_default"),
                    value: ""
                });
            } catch (e) {}
        },
        async fetchEcProducts() {
            try {
                const r = await EcService.ecProducts();
                this.ec_products = r.data.data;
            } catch (e) {}
        }
    }
};
</script>

<template>
    <modal
        :loading="loading"
        size="xl"
        :show="true"
        @close="$emit('close')"
        class="subscription-modal"
    >
        <template slot="header">
            {{ $t("contract.edit_contract") }}
        </template>
        <template slot="default" v-if="!loading">
            <FormulateForm
                v-model="contract"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <!-- Category and type -->
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <FormulateInput
                            name="asset_type_id"
                            type="select"
                            :options="asset_types"
                            :label="$t('contract.category_asset_type')"
                            :validation-name="
                                $t('contract.category_asset_type')
                            "
                            :placeholder="
                                $t('contract.select_category_asset_type')
                            "
                            validation="required"
                            :disabled="isDisabled === true"
                        />
                    </div>

                    <div class="col-md-8 col-sm-12">
                        <FormulateInput
                            name="economic_product"
                            type="select"
                            :options="ec_products"
                            :label="$t('contract.economic_product')"
                            :validation-name="$t('contract.economic_product')"
                            :placeholder="
                                $t('contract.select_economic_product')
                            "
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="sharepoint_url"
                            type="url"
                            :label="$t('contract.contract_url')"
                            :placeholder="$t('contract.contract_url')"
                            validation=""
                        />
                    </div>
                </div>
                <!-- Responsible -->
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="finance_responsible_id"
                            type="select"
                            :options="users"
                            :label="$t('contract.finance_responsible')"
                            :validation-name="
                                $t('contract.finance_responsible')
                            "
                            :placeholder="
                                $t('contract.select_finance_responsible')
                            "
                            validation="required"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="operations_responsible_id"
                            type="select"
                            :options="users"
                            :label="$t('contract.operations_responsible')"
                            :validation-name="
                                $t('contract.operations_responsible')
                            "
                            :placeholder="
                                $t('contract.select_operations_responsible')
                            "
                            validation="required"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="invoice_prefix_text"
                            type="text"
                            :label="$t('contract.invoice_prefix_text')"
                            :help="$t('contract.invoice_prefix_text_help')"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="internal_note"
                            type="textarea"
                            :label="$t('contract.internal_note')"
                            :help="$t('contract.internal_note_help')"
                        />
                    </div>
                </div>

                <h3 class="sub-heading">
                    {{ $t("contract.non_notice_period") }}
                </h3>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="from_date"
                            type="date"
                            :label="$t('contract.from_date')"
                            :validation-name="$t('contract.from_date')"
                            validation="required"
                            :disabled="true"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="to_date"
                            type="date"
                            :label="$t('contract.to_date')"
                            :validation-name="$t('contract.to_date')"
                            validation="required"
                        />
                    </div>
                </div>

                <h3 class="sub-heading">
                    {{ $t("contract.cancellation_policy") }}
                </h3>

                <!-- Contract dates -->
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="termination_date"
                            type="date"
                            :label="$t('contract.termination_date')"
                            :help="$t('contract.termination_date_help_text')"
                        />
                        <FormulateInput
                            name="termination_note"
                            type="textarea"
                            :label="$t('contract.termination_note')"
                            :help="$t('contract.termination_note_help_text')"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            name="notice"
                            type="grouptext"
                            :append="$t('contract.months')"
                            :label="$t('contract.notice')"
                            :validation-name="$t('contract.notice')"
                            :placeholder="$t('contract.notice')"
                            validation="number|required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <FormulateInput
                            @validation="mdayValidation = !$event.hasErrors"
                            type="mday"
                            :label="$t('contract.add_termination_notice')"
                            validation="mday"
                            v-model="mday"
                        />
                        <div class="text-right">
                            <base-button
                                name="donotuseme"
                                type="primary"
                                size="sm"
                                :disabled="!mdayValidation"
                                @click="addNotice"
                                ><i class="far fa-plus"></i>
                                {{ $t("contract.add_date") }}</base-button
                            >
                        </div>
                    </div>
                    <div class="col">
                        <!-- Termination notices -->
                        <v-mday-tag
                            type="primary"
                            v-for="(td, index) in contract.termination_notices"
                            :key="index"
                            :notice="td"
                            @delete="removeNotice"
                        />
                    </div>
                </div>

                <!-- Additional invoicing options -->

                <h3 class="sub-heading">
                    {{ $t("contract.additional_invoicing_options") }}
                </h3>

                <div v-show="hasInvoicingOptions || showInvoicingOptions">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_heading"
                                type="textarea"
                                :label="$t('contract.custom_invoice_heading')"
                                :help="
                                    $t('contract.custom_invoice_heading_help')
                                "
                            />
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <s-vars :vars="vars.contract_heading" />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_text1"
                                type="textarea"
                                :label="$t('contract.custom_invoice_text1')"
                                :help="$t('contract.custom_invoice_text1_help')"
                            />
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <s-vars :vars="vars.contract_text2" />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_text2"
                                type="textarea"
                                :label="$t('contract.custom_invoice_text2')"
                                :help="$t('contract.custom_text2_help')"
                            />
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <s-vars :vars="vars.contract_text2" />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_override_product_text"
                                type="textarea"
                                :label="
                                    $t('contract.invoice_override_product_text')
                                "
                                :help="
                                    $t(
                                        'contract.invoice_override_product_text_help'
                                    )
                                "
                            />
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_layout"
                                type="select"
                                :options="layouts"
                                :label="$t('contract.custom_invoice_layout')"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_extra_text"
                                type="textarea"
                                :label="$t('contract.invoice_extra_text')"
                                :help="$t('contract.invoice_extra_text_help')"
                            />
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <FormulateInput
                                name="invoice_term_of_payment"
                                type="select"
                                :options="termsOfPayment"
                                :label="$t('contract.custom_term_of_payment')"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    v-show="!hasInvoicingOptions && !showInvoicingOptions"
                >
                    <div class="col-lg-6 col-md-12">
                        {{
                            $t(
                                "contract.additional_invoicing_options_form_text"
                            )
                        }}
                    </div>
                    <div class="col-lg-6 col-md-12 text-right">
                        <base-button
                            size="sm"
                            @click="
                                showInvoicingOptions = !showInvoicingOptions
                            "
                        >
                            {{
                                $t("contract.show_additional_invoicing_options")
                            }}</base-button
                        >
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-6 col-md-12 text-left">
                        <!-- do not show delete if version > 1 -->
                        <base-button
                            v-if="editing && context.contract_version != 1"
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-lg-6 col-md-12 text-right">
                        <base-button
                            v-if="ACL_RW"
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            v-if="!ACL_RW"
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("close") }}</base-button
                        >
                        <base-button
                            v-if="ACL_RW"
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
