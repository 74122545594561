<script>
import VBudget from "@/components/Momentum/Contracts/VBudget";
import VAssetArchive from "@/components/Momentum/Contracts/VAssetArchive"; 
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VContractRevision",
    components: {        
        VBudget,
        VAssetArchive,
    },
    mixins: [MGConsts],
    props: {
        contract: {
            description: "Revision",
        },
    },
    data() {
        return {
            showDetails: false,
        };
    },
    created() {},
    methods: {},
};
</script>

<template>
    <div class="contract-revision">
        <template v-if="showDetails === false">
            <div class="row">
                <div class="col">
                    <div class="one-liner">
                        <i :class="`far ${contract.asset_type.icon} fa-2x`"></i>
                        {{ contract.contract_type_text }}
                        
                    </div>
                    <div class="one-liner">
                        <span class="param">{{
                            $t("contract.contract_dates")
                        }}</span>
                        <span class="value">
                            {{ $d(new Date(contract.from_date), "short") }} -
                            <template v-if="contract.to_date"
                                >{{ $d(new Date(contract.to_date), "short") }}
                            </template>
                            <template v-else
                                ><i class="fal fa-infinity"></i
                            ></template>
                        </span>
                    </div>

                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.id_number") }}:
                        </span>
                        <span class="value">{{ contract.id }}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.revision") }}:
                        </span>
                        <span class="value">{{
                            contract.contract_version
                        }}</span>
                    </div>
                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.last_modified") }}:
                        </span>
                        <span class="value">{{
                            $d(new Date(contract.modified), "short")
                        }}</span>
                    </div>
                </div>
                <div class="col">
                    
                    <dl>
                        <dt>{{ $t("contract.change_note") }}</dt>
                        <dd>
                            <i>{{ contract.change_note }}</i>
                        </dd>
                    </dl>
                    <base-button @click="showDetails = true" size="sm" type="primary">{{$t('contract.show_details')}}</base-button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col">
                    <div class="one-liner">
                        <i :class="`far ${contract.asset_type.icon} fa-2x`"></i>
                        {{ contract.contract_type_text }}
                    </div>
                    <div class="one-liner">
                        <span class="param">{{
                            $t("contract.contract_dates")
                        }}</span>
                        <span class="value">
                            {{ $d(new Date(contract.from_date), "short") }} -
                            <template v-if="contract.to_date"
                                >{{ $d(new Date(contract.to_date), "short") }}
                            </template>
                            <template v-else
                                ><i class="fal fa-infinity"></i
                            ></template>
                        </span>
                    </div>
                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.revision") }}:
                        </span>
                        <span class="value">{{
                            contract.contract_version
                        }}</span>
                    </div>
                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.last_modified") }}:
                        </span>
                        <span class="value">{{
                            $d(new Date(contract.modified), "short")
                        }}</span>
                    </div>
                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.id_number") }}:
                        </span>
                        <span class="value">{{ contract.id }}</span>
                    </div>
                    <dl class="mt-2">
                        <dt>{{ $t("contract.change_note") }}</dt>
                        <dd>
                            <i>{{ contract.change_note }}</i>
                        </dd>
                    </dl>
                </div>

                <div class="col">
                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.currency") }}:
                        </span>
                        <span class="value">{{ contract.currency }}</span>
                    </div>
                    <!-- Fixed fee -->
                    <template v-if="parseInt(contract.contract_type) == 1">
                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.basic_fee") }}:</span
                            >
                            <span class="value">
                                {{
                                    $n(contract.base_fee, {
                                        style: "currency",
                                        currency: contract.currency,
                                        currencyDisplay: "code",
                                    })
                                }}
                            </span>
                        </div>
                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.first_year_base_fee") }}:
                            </span>
                            <span class="value">
                                <template
                                    v-if="contract.first_year_base_fee > 0"
                                    >{{
                                        $n(contract.first_year_base_fee, {
                                            style: "currency",
                                            currency: contract.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</template
                                >
                                <template v-else>---</template>
                            </span>
                        </div>
                    </template>

                    <!-- Revenue -->
                    <template v-if="parseInt(contract.contract_type) == 2">
                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.basic_fee") }}:
                            </span>
                            <span class="value"
                                >{{ $n(contract.base_fee_perc) }}%</span
                            >
                        </div>

                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.first_year_base_fee_perc") }}:
                            </span>
                            <span class="value">
                                <template
                                    v-if="contract.first_year_base_fee_perc > 0"
                                >
                                    {{ $n(contract.first_year_base_fee_perc) }}%
                                </template>
                                <template v-else>---</template>
                            </span>
                        </div>
                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.base_fee_min") }}:
                            </span>
                            <span class="value">
                                <template v-if="contract.base_fee_min > 0">
                                    {{
                                        $n(contract.base_fee_min, {
                                            style: "currency",
                                            currency: contract.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</template
                                >
                                <template v-else>---</template>
                            </span>
                        </div>
                    </template>

                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.frequency") }}:
                        </span>
                        <span class="value">
                            {{ contract.frequency_obj.number }}
                            {{ contract.frequency_obj.word }}
                        </span>
                    </div>

                    <div class="one-liner">
                        <span class="param">
                            {{ $t("contract.shift_payment") }}:
                        </span>
                        <span class="value">
                            <template v-if="contract.shift_frequency">
                                {{
                                    $matchInHash(
                                        mgShiftPaymentHash,
                                        contract.shift_frequency
                                    )
                                }}
                            </template>
                            <template v-else>---</template>
                        </span>
                    </div>

                    <div class="one-liner">
                        <span class="param">
                            {{ $t("contract.payment_strategy") }}:
                        </span>
                        <span class="value">
                            <template
                                v-if="contract.payment_strategy == 'ahead'"
                                >{{ $t("contract.ahead") }}</template
                            >
                            <template v-else>{{
                                $t("contract.behind")
                            }}</template>
                        </span>
                    </div>

                    <div class="one-liner">
                        <span class="param"
                            >{{ $t("contract.adjustment_type") }}:
                        </span>
                        <span class="value">{{
                            contract.adjustment_type_name
                        }}</span>
                    </div>
                    <template v-if="contract.adjustment_type > 1">
                        <template v-if="contract.contract_type == 2">
                            <div class="one-liner">
                                <span class="param"
                                    >{{ $t("contract.adjustment_subtype") }}:
                                </span>
                                <span class="value">{{
                                    $matchInHash(
                                        mgAdjustmentSubtype,
                                        contract.adjustment_subtype
                                    )
                                }}</span>
                            </div>
                        </template>

                        <template v-if="contract.adjustment_type == 3">
                            <div class="one-liner">
                                <span class="param"
                                    >{{
                                        $t("contract.min_regulation_percent")
                                    }}:
                                </span>
                                <span class="value">
                                    <template
                                        v-if="contract.min_adjustment_perc > 0"
                                    >
                                        {{ $n(contract.min_adjustment_perc) }}%
                                    </template>
                                    <template v-else>---</template>
                                </span>
                            </div>
                        </template>

                        <template v-if="contract.adjustment_type == 2">
                            <div class="one-liner">
                                <span class="param"
                                    >{{
                                        $t("contract.fixed_regulation_rate")
                                    }}:</span
                                >
                                <span class="value"
                                    >{{ contract.adjustment_perc }}%</span
                                >
                            </div>
                        </template>

                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.first_adjustment_date") }}:
                            </span>
                            <span class="value">
                                <template v-if="contract.first_adjustment_date">
                                    {{
                                        $d(
                                            new Date(
                                                contract.first_adjustment_date
                                            ),
                                            "short"
                                        )
                                    }}
                                </template>
                                <template v-else>---</template>
                            </span>
                        </div>
                        <div class="one-liner">
                            <span class="param"
                                >{{ $t("contract.adjust_on") }}:
                            </span>
                            <span class="value">
                                <template v-if="contract.adjust_on">{{
                                    contract.adjust_on
                                }}</template>
                                <template v-else>---</template>
                            </span>
                        </div>
                    </template>
                </div>
            </div>

            <v-budget :contract="contract" :ro="true" />
            <v-asset-archive :contract="contract" />

            <pre v-if="false">{{ contract }}</pre>
        </template>
    </div>
</template>


<style>
</style>