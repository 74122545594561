<script>
import BudgetService from "@/services/BudgetService";
import ContractDetailService from "@/services/ContractDetailService";


export default {
    props: {
        context: {},
        budgetColors: {}
    },
    data() {
        return {
            loading: true,
            budgets: [],
            meta: {}
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        /**
         * Link budgets
         * @param budget_id 
         */
        async selectBudget(budget_id) {
            try {
                const {data} = await ContractDetailService.PUT_setBudgetLink(this.context.id, budget_id);
                this.$emit('close-reload');
            } catch (e) {
                console.log("sel", e);
            }
        },
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await BudgetService.GET_budgets(
                    this.context.contract_id,
                    this.context.contract_year
                );
                this.budgets = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("ee", e);
            }
            this.loading = false;
        }
    }
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
        :loading="loading"
    >
        <template slot="header">
            {{ $t("budget.setup_budget_link") }}
        </template>

        <template slot="default">
            <div class="row">
                <div class="col-12">
                    <div
                        class="alert alert-danger text-center"
                        v-if="context.status > 1"
                    >
                        {{
                            $t(
                                "budget.budget_already_invoiced_want_change_need_to_void_text"
                            )
                        }}
                    </div>

                    <v-table
                        class="table table-sm table-mg table-striped"
                        v-else
                    >
                        <v-thead>
                            <v-tr>
                                <v-th class="text-center">{{
                                    $t("budget.color_and_id")
                                }}</v-th>
                                <v-th class="text-center">{{
                                    $t("budget.spanning_period_length")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.kwh_actual")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.kwh_budget")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.turnover_actual")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.turnover_budget")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("budget.action")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="budget in budgets" :key="budget.id">
                                <v-td
                                    :class="
                                        budget.id in budgetColors
                                            ? `color-${budgetColors[budget.id]}`
                                            : ''
                                    "
                                    >#{{ budget.id }}
                                </v-td>
                                <v-td class="text-center">
                                    <template v-if="budget.id in budgetColors">
                                        {{
                                            $tc(
                                                "budget.N_months",
                                                budget.total_period_length
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        ({{
                                            $t("budget.not_assigned")
                                        }})</template
                                    >
                                </v-td>
                                <v-td class="text-right">{{
                                    $n(budget.actual_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.planned_production_kwh)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.actual_revenue, {
                                        style: "currency",
                                        currency: context.contract.currency
                                    })
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(budget.planned_revenue, {
                                        style: "currency",
                                        currency: context.contract.currency
                                    })
                                }}</v-td>
                                <v-td class="text-right">
                                    <template
                                        v-if="budget.id == context.budget_id"
                                    >
                                        <span class="text-bold text-primary">{{
                                            $t("budget.current")
                                        }}</span></template
                                    >
                                    <base-button
                                        v-else
                                        size="sm"
                                        type="primary"
                                        @click="selectBudget(budget.id)"
                                        >{{ $t("budget.select") }}</base-button
                                    >
                                </v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-right mt-4">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>
