<script>
export default {
    props: {
        displayYear: {},
        meta: {},
        contract: {}
    }
};
</script>

<template>
    <div>
        <h3>
            {{
                $t("contract.data_for_YEAR", {
                    YEAR: displayYear
                })
            }}
        </h3>

        <div>
            {{ $t("contract.total_calculated_for_all_periods") }}:
            <span
                :class="{
                    'text-danger': meta.details.has_staged
                }"
                ><i
                    v-if="meta.details.has_staged"
                    class="far fa-exclamation-triangle"
                ></i>
                {{
                    $n(meta.details.sum, {
                        style: "currency",
                        currency: contract.currency,
                        currencyDisplay: "code"
                    })
                }}</span
            >
            <div class="text-danger" v-if="meta.details.has_staged">
                <i class="far fa-exclamation-triangle"></i>
                {{ $t("contract.WARNING_includes_staged_periods") }}
            </div>
        </div>
    </div>
</template>
