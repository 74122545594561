<script>
import HInnerDashCard from "@/components/Cards/HInnerDashCard";
export default {
    props: {
        meta: {}
    },
    components: { HInnerDashCard }
};
</script>

<template>
    <div class="row mt-4 contract-q-dash">
        <div class="col">
            <h-inner-dash-card
                :title="$t('contract.total_calculated')"                
                :value="
                    $n(meta.status.total_sum, {
                        style: 'currency',
                        currency: meta.status.currency,
                        currencyDisplay: 'code'
                    })
                "
                :footer="$t('contract.hlp_total_sum')"                
            />
        </div>
        <div class="col">
            <h-inner-dash-card
                :title="$t('contract.calculated_invoiced')"
                :value="
                    $n(meta.status.invoiced_cds, {
                        style: 'currency',
                        currency: meta.status.currency,
                        currencyDisplay: 'code'
                    })
                "
                :footer="$t('contract.hlp_calculated_invoiced')"                
            />
        </div>
        <div class="col">
            <h-inner-dash-card
                :title="$t('contract.invoiced_so_far')"
                :value="
                    $n(meta.status.invoiced_invoiced, {
                        style: 'currency',
                        currency: meta.status.currency,
                        currencyDisplay: 'code'
                    })
                "
                :footer="$t('contract.hlp_invoiced_so_far')"
            />
        </div>

        <div class="col">
            <h-inner-dash-card
                :title="$t('contract.difference_in_periods')"
                :value="
                    $n(meta.status.regulation, {
                        style: 'currency',
                        currency: meta.status.currency,
                        currencyDisplay: 'code'
                    })
                "
                :footer="$t('contract.hlp_difference_in_periods')"
                :type="meta.status.regulation < 0 ? 'danger' : ''"
            />
        </div>
        <div class="col">
            <h-inner-dash-card
                :title="$t('contract.awaiting_invoicing')"
                :value="
                    $n(meta.status.awaiting, {
                        style: 'currency',
                        currency: meta.status.currency,
                        currencyDisplay: 'code'
                    })
                "
                :type="meta.status.awaiting < 0 ? 'danger' : ''"
                :footer="$t('contract.hlp_awaiting_invoicing')"
            />
        </div>
    </div>
</template>
