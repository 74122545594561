<script>
import ContractDetailService from "@/services/ContractDetailService";
import { pick } from "lodash";

export default {
    name: "VBudgetHalfForm",
    props: {
        contractDetail: {},
        currency: {}
    },
    data() {
        return {
            loading: false,
            errors: null,
            cd: {
                id: null,
                contract_year: null,
                half_real_production_kwh: null,
                half_real_revenue: null
            }
        };
    },
    created() {
        this.cd = pick(this.contractDetail, [
            "id",
            "contract_year",
            "half_real_production_kwh",
            "half_real_revenue"
        ]);
    },
    methods: {
        async saveForm() {
            try {          
                this.loading = true;      
                const {data } = await ContractDetailService.PUT_editHalfBudget(this.cd.id, this.cd);                
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }

            this.loading = false;   
        }
    }
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            {{ $t("cd.edit_half_budget") }}
        </template>

        <template slot="default">
            
            <FormulateForm v-model="cd" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="contract_year"
                            type="text"
                            :placeholder="$t('budget.year_placeholder')"
                            :label="$t('budget.year')"
                            :disabled="true"
                        />
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="half_real_production_kwh"
                            type="grouptext"
                            append="kWh"
                            :label="$t('budget.production')"
                        />
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="half_real_revenue"
                            type="currency"
                            :currency="currency"
                            :label="$t('budget.turnover')"
                        />
                    </div>
                </div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
