<script>
import Cloner from "@/libraries/Cloner";
import SubscriptionConsumptionService from "@/services/SubscriptionConsumptionService";

export default {
    name: "VChargeTemplateForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    computed: {},
    data() {
        return {
            loading: true,
            editing: false,
            errors: {},
            consumption: {
                id: null,
                subscription_id: null,
                qty: null,
                item_price: null,
                match_period_date: null
            }
        };
    },
    async created() {
        this.editing = this.context.ready;

        if (this.editing) {
            this.consumption = Cloner.pickClone(this.context.consumption, [
                "id",
                "qty",
                "item_price",
                "subscription_id",
                "match_period_date"
            ]);
        } else {
            this.consumption.subscription_id = this.context.subscription_id;
            this.consumption.match_period_date = this.context.req_consumption.match_period_date;
        }

        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await SubscriptionConsumptionService.PUT(
                        this.consumption
                    );
                } else {
                    const r = await SubscriptionConsumptionService.POST(
                        this.consumption
                    );
                }

                this.$emit("close-refresh");
            } catch (e) {
                if (e.response.status == 403) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_no_access"),
                        text: e.response.data.errors.join(".\n")
                    });
                } else {
                    this.errors = e.response.data.errors;
                }
            }
        }
    }
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="subscription-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("consumption.edit") }}
            </template>
            <template v-else>
                {{ $t("consumption.create") }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <FormulateForm
                v-model="consumption"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="qty"
                            type="decimal"
                            :label="
                                $t('consumption.quantity_UNIT', {
                                    UNIT: context.unit_name
                                })
                            "
                            :validation-name="$t('consumption.quantity')"
                            validation=""
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="item_price"
                            type="decimal"
                            number-style="usage"
                            :label="$t('consumption.item_price')"
                            :validation-name="$t('consumption.item_price')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
