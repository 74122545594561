<script>
import BudgetService from "@/services/BudgetService";
import { pick } from "lodash";

export default {
    name: "VAssetForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        contract: {
            description: "Contract object",
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            budget: {
                id: null,
                year: null,
                contract_id: null,
                planned_revenue: null,
                actual_revenue: null,
                planned_production_kwh: null,
                actual_production_kwh: null,
            },
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.budget = pick(this.context, [
                "id",
                "year",
                "contract_id",
                "planned_revenue",
                "actual_revenue",
                "planned_production_kwh",
                "actual_production_kwh",
            ]);
        } else {
            // Assign contract_Id
            this.budget.contract_id = this.contract.id;
        }
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await BudgetService.PUT(this.budget);
                } else {
                    const r = await BudgetService.POST(this.budget);
                }

                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <template v-if="editing">
                {{ $t("budget.edit_budget") }}
            </template>
            <template v-else>
                {{ $t("budget.create_budget") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm v-model="budget" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="year"
                            type="text"
                            :placeholder="$t('budget.year_placeholder')"
                            :label="$t('budget.year')"
                            validation="required|number|between:2000,2999"
                        />
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="planned_production_kwh"
                            type="grouptext"
                            append="kWh"                            
                            :label="$t('budget.production')"
                        />
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <FormulateInput
                            name="planned_revenue"
                            type="currency"
                            :currency="contract.currency"
                            :label="$t('budget.turnover')"
                        />
                    </div>
                </div>

                <div class="line-separator"></div>

                <div class="row mt-4">
                    <div class="col-lg-4 col-sm-12 col-xs-12">
                        <span class="actual-numbers">{{
                            $t("budget.actual_numbers")
                        }}</span>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="actual_production_kwh"
                            type="grouptext"
                            append="kWh"                            
                            :label="$t('budget.production')"
                        />
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="actual_revenue"
                            type="currency"
                            :currency="contract.currency"
                            :label="$t('budget.turnover')"
                        />
                    </div>
                </div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
