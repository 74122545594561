<script>
import AppCfg from "@/mixins/AppCfg";
import MGConsts from "@/mixins/MGConsts";
import ContractConditionService from "@/services/ContractConditionService";
import Cloner from "@/libraries/Cloner";

export default {
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        contract: {}
    },
    mixins: [MGConsts, AppCfg],
    data() {
        return {
            loading: true,
            editing: false,
            allowAll: true,
            errors: {},
            condition: {
                id: null,
                contract_id: null,
                base_fee: null,
                base_fee_perc: null,
                base_fee_min: null,
                adjustment_type: null,
                adjustment_perc: null,
                min_adjustment_perc: null,
                adjustment_subtype: null,
                valid_from: null,
                first_adjustment_date: null,
                comment: null,
                is_first_year: 0,
                force_min_fee: null,
                enable_half_regulation: null
            }
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.allowAll = false;
            this.condition = Cloner.pickClone(this.context, [
                "id",
                "contract_id",
                "base_fee",
                "base_fee_perc",
                "base_fee_min",
                "adjustment_type",
                "adjustment_perc",
                "min_adjustment_perc",
                "adjustment_subtype",
                "valid_from",
                "first_adjustment_date",
                "comment",
                "is_first_year",
                "force_min_fee",
                "enable_half_regulation"
            ]);
        } else {
            // Assign contract_Id
            this.condition.contract_id = this.contract.id;
        }

        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await ContractConditionService.PUT(
                        this.condition
                    );
                } else {
                    const r = await ContractConditionService.POST(
                        this.condition
                    );
                }

                this.$emit("close-reload");
            } catch (e) {
                console.log("saveForm e", e);
                this.errors = e.response.data.errors;
            }
        },
        async deleteEntity() {
            this.$swal({
                icon: "warning",
                text: this.$t("contract_condition.confirm_delete_conditions"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.delete();
                }
            });
        },
        async delete() {
            try {
                const r = await ContractConditionService.DELETE(
                    this.condition.id
                );
                this.$emit("close-reload");
            } catch (e) {
                this.$swal({
                    icon: "warning",
                    text: Object.values(e.response.data.errors).join(" "),
                    showConfirmButton: true
                });
            }
        }
    }
};
</script>

<template>
    <modal
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
        :loading="loading"
    >
        <template slot="header">
            <template v-if="editing">{{
                $t("contract_condition.edit_condition")
            }}</template>
            <template v-else>{{
                $t("contract_condition.create_condition")
            }}</template>
        </template>

        <template slot="default">
            <FormulateForm
                v-model="condition"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col-12">
                        <p>
                            {{ $t("contract_condition.form_help_text") }}
                        </p>
                    </div>

                    <div class="col-6">
                        <FormulateInput
                            name="is_first_year"
                            type="select"
                            :options="mgIsFirstYear"
                            :label="$t('contract.is_first_year')"
                            :placeholder="$t('contract.select_is_first_year')"
                            validation="required"
                            :validation-name="$t('contract.is_first_year')"
                            :help="$t('contract_condition.help_is_first_year')"
                        />
                    </div>

                    <div class="col-6">
                        <FormulateInput
                            :disabled="!allowAll"
                            type="fdatepicker"
                            name="valid_from"
                            :label="$t('contract_condition.valid_from')"
                            :validation-name="
                                $t('contract_condition.valid_from')
                            "
                            :help="$t('contract_condition.help_valid_from')"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row">
                    <template v-if="contract.contract_type == 1">
                        <div class="col-sm-6 col-xs-12">
                            <FormulateInput
                                :disabled="!allowAll"
                                name="base_fee"
                                :currency="contract.currency"
                                type="currency"
                                :label="$t('contract.base_fee')"
                                :validation-name="$t('contract.base_fee')"
                                :placeholder="$t('contract.base_fee')"
                                validation="required"
                            />
                        </div>
                    </template>
                    <template v-if="contract.contract_type == 2">
                        <div class="col-sm-6 col-xs-12">
                            <FormulateInput
                                :disabled="!allowAll"
                                name="base_fee_perc"
                                type="groupdecimal"
                                append="%"
                                :label="$t('contract.base_fee_perc')"
                                :validation-name="$t('contract.base_fee_perc')"
                                :placeholder="
                                    $t('contract.placeholder_base_fee_perc')
                                "
                                validation="required"
                            />
                        </div>
                        <div class="col-sm-6">
                            <FormulateInput
                                :disabled="!allowAll"
                                name="base_fee_min"
                                type="currency"
                                :currency="contract.currency"
                                :label="$t('contract.placeholder_base_fee_min')"
                                :placeholder="$t('contract.base_fee_min')"
                            />
                        </div>

                        <div class="col-sm-6">
                            <FormulateInput
                                :disabled="!allowAll"
                                name="enable_half_regulation"
                                type="select"
                                :label="$t('contract.enable_half_regulation')"
                                :validation-name="
                                    $t('contract.enable_half_regulation')
                                "
                                :placeholder="
                                    $t(
                                        'contract.placeholder_enable_half_regulation'
                                    )
                                "
                                :options="
                                    appCfgRead(
                                        'ContractConditions.enable_half_regulation',
                                        true
                                    )
                                "
                                :help="
                                    $t('contract.help_enable_half_regulation')
                                "
                                validation="required"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <FormulateInput
                                :disabled="
                                    !(
                                        allowAll &&
                                        parseFloat(condition.base_fee_min) > 0
                                    )
                                "
                                name="force_min_fee"
                                type="select"
                                :label="$t('contract.force_min_fee')"
                                :validation-name="$t('contract.force_min_fee')"
                                :validation="parseFloat(condition.base_fee_min) > 0 ? 'required' : ''"
                                :placeholder="
                                    $t('contract.placeholder_force_min_fee')
                                "
                                :options="
                                    appCfgRead(
                                        'ContractConditions.force_min_fee',
                                        true
                                    )
                                "
                                :help="$t('contract.help_force_min_fee')"
                            />
                        </div>
                    </template>
                </div>
                <!-- Regulation -->
                <div class="row">
                    <div class="col-sm-12 col-xs-12">
                        <FormulateInput
                            :disabled="!allowAll"
                            name="adjustment_type"
                            type="select"
                            :options="mgAdjustmentType"
                            :label="$t('contract.adjustment_type')"
                            :placeholder="$t('contract.select_adjustment_type')"
                            validation="required"
                            :validation-name="$t('contract.adjustment_type')"
                        />
                    </div>

                    <template v-if="parseInt(condition.adjustment_type) > 1">
                        <template v-if="contract.contract_type == 2">
                            <div class="col-sm-4 col-xs-12">
                                <FormulateInput
                                    :disabled="!allowAll"
                                    name="adjustment_subtype"
                                    type="select"
                                    :label="$t('contract.adjustment_subtype')"
                                    :options="mgAdjustmentSubtype"
                                    validation="required"
                                    :validation-name="
                                        $t('contract.adjustment_subtype')
                                    "
                                />
                            </div>
                        </template>

                        <template v-if="condition.adjustment_type == 3">
                            <div class="col-sm-4 col-xs-12">
                                <FormulateInput
                                    :disabled="!allowAll"
                                    name="min_adjustment_perc"
                                    type="groupdecimal"
                                    append="%"
                                    :label="
                                        $t('contract.min_regulation_percent')
                                    "
                                    :placeholder="
                                        $t('contract.min_regulation_percent')
                                    "
                                />
                            </div>
                        </template>
                        <template v-if="condition.adjustment_type == 2">
                            <div class="col-sm-4 col-xs-12">
                                <FormulateInput
                                    :disabled="!allowAll"
                                    name="adjustment_perc"
                                    type="groupdecimal"
                                    append="%"
                                    :label="
                                        $t('contract.fixed_regulation_rate')
                                    "
                                    :placeholder="
                                        $t('contract.fixed_regulation_rate')
                                    "
                                    validation="required"
                                    :validation-name="
                                        $t('contract.fixed_regulation_rate')
                                    "
                                />
                            </div>
                        </template>
                        <div class="col-sm-4 col-xs-12">
                            <FormulateInput
                                :disabled="!allowAll"
                                name="first_adjustment_date"
                                type="fdatepicker"
                                :label="$t('contract.adjustment_since_date')"
                                version="year"
                                validation="required"
                                :validation-name="
                                    $t('contract.adjustment_since_date')
                                "
                                :help="
                                    $t('contract.help_adjustment_since_date')
                                "
                            />
                        </div>
                    </template>
                </div>
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="textarea"
                            :label="
                                $t('contract_condition.describe_the_change')
                            "
                            :validation-name="
                                $t('contract_condition.describe_the_change')
                            "
                            :help="
                                $t(
                                    'contract_condition.help_describe_the_change'
                                )
                            "
                            name="comment"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col text-left">
                        <base-button
                            v-if="editing"
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
