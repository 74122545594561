<script>
import ChargeService from "@/services/ChargeService";
import VChargeForm from "@/components/Forms/VChargeForm";

export default {
    name: "VCharges",
    components: {
        VChargeForm,
    },
    props: {
        customer_id: {
            description: "Customer ID",
        },
    },
    data() {
        return {
            loading: false,
            charges: [],
            editing: false,
            editContext: null,
            showNote: false,
            showNoteContext: null,
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch all
         */
        async fetchData() {
            try {
                this.loading = true;
                const r = await ChargeService.GET({
                    customer_id: this.customer_id,
                });
                this.charges = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         * Finish
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
        /**
         * Show note
         */
        doShowNote(ctx) {
            this.showNote = true;
            this.showNoteContext = ctx;
        },
        /** 
         * HIde note
         */
        doHideNote() {
            this.showNoteContext = null;
            this.showNote = false;
        },
    },
};
</script>

<template>
    <!-- one-time services :: lines -->
    <div class="v-charges">
        <div class="px-lr mt-4">
            <div class="mg-table-title">
                <div class="mg-table-title__title">
                    <h3>{{ $t("charges.charges") }}</h3>
                </div>
                <div class="mg-table-title__actions">
                    <div class="mg-table-title__actions__left">
                        <span
                            v-if="ACL_RW"
                            class="m-click text-primary"
                            @click="setContext({ empty: true })"
                        >
                            <i class="far fa-plus"></i>
                            {{ $t("charges.add_new") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        
        <v-loader :loading="loading" v-if="loading" />
        <!-- table -->
        <div class="table-responsive" v-else-if="charges.length">
            <v-table class="table table-mg">
                <v-thead>
                    <v-tr>
                        <v-th>{{ $t("charges.product") }}</v-th>
                        <v-th class="text-center">{{ $t("charges.notes") }}</v-th>
                        <v-th>{{ $t("charges.invoice_date") }}</v-th>
                        <v-th class="text-right">{{ $t("charges.amount") }}</v-th>
                        <v-th>{{ $t("charges.status") }}</v-th>
                        <v-th></v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="c in charges" :key="c.id" :class="{'status-disabled': c.status == 2}">
                        <v-td>
                            <template v-if="c.invoice_text">
                                {{ c.invoice_text }}
                                <div class="small text-muted">
                                    {{ c.economic_product_name }}
                                </div>
                            </template>
                            <template v-else>
                                {{ c.economic_product_name }}
                            </template>
                        </v-td>
                        <v-td class="text-center">
                            <i
                                class="far fa-comment-alt-lines fa-lg m-click"
                                @click="doShowNote(c.description)"
                                v-if="c.description"
                            ></i>
                            <i
                                class="far fa-comment-alt-slash text-muted fa-lg"
                                v-else
                            ></i>
                        </v-td>
                        <v-td>
                            {{ $d(new Date(c.invoice_date), "short") }}
                        </v-td>

                        <v-td class="text-right">
                            {{
                                $n(c.amount, {
                                    style: "currency",
                                    currency: c.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </v-td>
                        <v-td>
                            {{ c.status_name }}
                        </v-td>

                        <v-td class="text-right" v-if="ACL_RW">
                            <i class="far fa-ban fa-lg" v-if="c.status == 2"></i>
                            <i class="far fa-pencil m-click fa-lg"
                            @click="setContext(c)"
                            v-if="c.status == 1"></i>
                        </v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
        </div>

        <div class="alert alert-warning alert-outline ml-4 mr-4" v-else>
            {{ $t("charges.no_charges_found") }}
        </div>
        <portal to="modals">
            <modal
                v-if="showNote"
                size="lg"
                :show="true"
                @close="doHideNote"
            >
                <template slot="header">
                    {{ $t("charges.note_header") }}
                </template>
                <template slot="default">
                    {{ showNoteContext }}
                </template>
            </modal>
            <v-charge-form
                v-if="editing"
                :context="editContext"
                :customer_id="customer_id"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>


<style>
</style>