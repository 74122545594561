<script>
import CustomerService from "@/services/CustomerService";
import VFilters from "@/components/Search/VFilters";

export default {
    name: "VIndex",
    components: {
        VFilters
    },
    data() {
        return {
            customers: [],
            meta: {},
            loading: false
        };
    },
    created() {
        this.apiSearchFilter = this.initSearch();
        this.apiPaginator.sort = "name";
        this.apiPaginator.direction = "asc";
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        /**
         * Init search
         */
        initSearch() {
            return {
                search: "",
                address: "",
                number: "",
                vat_number: ""
            };
        },
        /**
         * Reset serach
         */
        resetSearch() {
            this.$resetSearch();
            this.apiSearchFilter = this.initSearch();
        },
        /**
         * Main fetch data
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await CustomerService.GET(params);
                this.customers = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        /**
         * Navigate to customer view
         */
        navigate(customer, routeName = "CustomersManage") {
            this.$router.push({
                name: routeName,
                params: { customer_id: customer.id }
            });
        }
    }
};
</script>

<template>
    <div class="v-customers-index">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        @reset-search="resetSearch"
                        :search="true"
                        v-model="apiSearchFilter.search"
                        :search-placeholder="$t('customer.search')"
                    >
                        <template slot="default">
                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.number"
                                    :placeholder="$t('customer.number')"
                                    type="text"
                                />
                            </div>

                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.vat_number"
                                    :placeholder="$t('customer.vat_number')"
                                    type="text"
                                />
                            </div>

                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.address"
                                    :placeholder="$t('customer.address')"
                                    type="text"
                                />
                            </div>
                        </template>
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="customers.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "customer.customer_name"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th class="text-center">
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="active"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("customer.status")
                                                    }}</v-sorter
                                                >
                                            </v-th>

                                            <v-th class="text-center">
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="customer_subtype"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "customer.customer_subtype"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>

                                            <v-th class="text-center">
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="internal_customer"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "customer.customer_type"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>

                                            <v-th class="text-center"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="count_contracts"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "contracts.contracts"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-center"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="count_cases"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("customer.cases")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="customer in customers"
                                            :key="customer.id"
                                        >
                                            <v-td>
                                                <span
                                                    class="m-click text-primary"
                                                    @click="navigate(customer)"
                                                >
                                                    {{ customer.name }}
                                                </span>
                                            </v-td>
                                            <v-td class="text-center">
                                                <span
                                                    class="text-success"
                                                    v-if="
                                                        parseInt(
                                                            customer.active
                                                        ) == 1
                                                    "
                                                >
                                                    {{ $t("customer.active") }}
                                                </span>
                                                <span
                                                    class="text-danger"
                                                    v-else
                                                    >{{
                                                        $t("customer.inactive")
                                                    }}</span
                                                >
                                            </v-td>
                                            <v-td class="text-center">
                                                <template
                                                    v-if="
                                                        customer.customer_subtype >
                                                            0
                                                    "
                                                >
                                                    {{
                                                        customer.customer_subtype_name
                                                    }}
                                                </template>
                                                <template v-else>
                                                    (mangler segment)
                                                </template>
                                            </v-td>

                                            <v-td class="text-center">
                                                {{
                                                    customer.internal_customer_alt_name
                                                }}</v-td
                                            >

                                            <v-td
                                                class="text-center badge-icon-mix"
                                            >
                                                <span
                                                    class="m-click"
                                                    :class="{
                                                        'text-primary':
                                                            customer.count_contracts,
                                                        'text-muted': !customer.count_contracts
                                                    }"
                                                    @click.stop="
                                                        navigate(
                                                            customer,
                                                            'CustomersTabContracts'
                                                        )
                                                    "
                                                >
                                                    <template
                                                        v-if="
                                                            customer.count_contracts
                                                        "
                                                    >
                                                        {{
                                                            customer.count_contracts
                                                        }}x
                                                        <i
                                                            class="far fa-file-signature ml-2 fa-lg"
                                                        ></i>
                                                    </template>
                                                    <span v-else>
                                                        <i
                                                            class="far fa-arrow-right"
                                                        ></i>
                                                    </span>
                                                </span>
                                            </v-td>
                                            <v-td class="text-center">
                                                <span
                                                    class="m-click"
                                                    :class="{
                                                        'text-primary':
                                                            customer.count_cases,
                                                        'text-muted': !customer.count_cases
                                                    }"
                                                    @click.stop="
                                                        navigate(
                                                            customer,
                                                            'CustomersTabCases'
                                                        )
                                                    "
                                                >
                                                    <template
                                                        v-if="
                                                            customer.count_cases
                                                        "
                                                    >
                                                        {{
                                                            customer.count_cases
                                                        }}x
                                                        <i
                                                            class="far fa-file-invoice fa-lg ml-2"
                                                        ></i>
                                                    </template>
                                                    <span v-else>
                                                        <i
                                                            class="far fa-arrow-right"
                                                        ></i>
                                                    </span>
                                                </span>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.v-customers-index {
    .badge-icon-mix {
        span {
        }
        i {
        }
    }
}
</style>
