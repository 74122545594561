<script>
export default {
    props: {
        contract: {}
    }
};
</script>

<template>
    <div
        class="alert alert-danger d-flex align-items-center"
        v-if="contract.is_terminated || contract.has_termination"
    >
        <div>
            <template v-if="contract.is_terminated">
                {{
                    $t("contract.WARNING_the_contract_was_terminated_on_DATE", {
                        DATE: $d(new Date(contract.termination_date), "short")
                    })
                }}
            </template>
            <template v-else>
                {{
                    $t("contract.WARNING_the_contract_is_terminated_by_DATE", {
                        DATE: $d(new Date(contract.termination_date), "short")
                    })
                }}
            </template>
            <div class="small" v-if="contract.termination_note">
                <span class="text-bold">{{ $t("contract.reason") }}:</span>
                {{ contract.termination_note }}
            </div>
        </div>

        <i class="far fa-exclamation-triangle ml-auto fa-lg text-bold"></i>
    </div>
</template>
