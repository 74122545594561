<script>
import AssetArchiveService from "@/services/AssetArchiveService";

export default {
    name: "VAssetArchive",
    props: {
        contract: {
            type: Object,
            description: "Contract ID",
        },
    },
    data() {
        return {
            assets: [],
            loading: false,                        
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await AssetArchiveService.GETS(this.contract.id);
                this.assets = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
    },
};
</script>

<template>
    <div>
        <div class="px-lr">
            <div class="mg-table-title">
                <div class="mg-table-title__title">
                    <h3>{{ $t("assets.h3_header") }}</h3>
                </div>
                <div class="mg-table-title__actions">
                    <div class="mg-table-title__actions__left">                        
                    </div>
                </div>
            </div>
        </div>
        <div class="mg-assets">
            <v-loader :loading="loading" v-if="loading" />

            <template v-else-if="assets.length">
                <div class="table-responsive">
                    <v-table class="table table-mg">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("asset.class") }}</v-th>
                                <v-th>{{ $t("asset.type") }}</v-th>
                                <v-th class="text-center">{{
                                    $t("asset.qty")
                                }}</v-th>
                                <v-th class="text-center">{{
                                    $t("asset.mw")
                                }}</v-th>
                                <v-th class="text-center">{{
                                    $t("asset.subsidy_expiration")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("asset.value")
                                }}</v-th>                    
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            
                            <v-tr v-for="asset in assets" :key="asset.id">
                                <v-td>{{asset.asset_type}}</v-td>
                                <v-td>{{asset.asset_class}}</v-td>
                                <v-td class="text-center">{{ asset.asset_qty }}</v-td>
                                <v-td class="text-center">{{
                                    asset.asset_mw
                                }}</v-td>
                                <v-td class="text-center">
                                    <template
                                        v-if="asset.asset_subsidy_expiration"
                                    >
                                        {{
                                            $d(
                                                new Date(
                                                    asset.asset_subsidy_expiration
                                                ),
                                                "short"
                                            )
                                        }}
                                    </template>
                                    <template v-else>---</template>
                                </v-td>
                                <v-td class="text-right">
                                    <template v-if="asset.asset_value > 0">
                                        {{
                                            $n(asset.asset_value, {
                                                style: "currency",
                                                currencyDisplay: "code",
                                                currency:
                                                    asset.asset_value_currency,
                                            })
                                        }}
                                    </template>

                                    <template v-else>---</template>
                                </v-td>                                
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </template>

            <div class="alert alert-warning alert-outline ml-4 mr-4" v-else>
                {{ $t("assets.no_data") }}
            </div>
        </div>        
    </div>
</template>