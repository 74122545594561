<script>
import ContractService from "@/services/ContractService";
import UserService from "@/services/UserService";
import ProjectService from "@/services/ProjectService";
import Cloner from "@/libraries/Cloner";
import Messages from "@/mixins/Messages";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VProjectForm",
    components: {},
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        contractId: {},
    },
    mixins: [Messages, AppCfg],
    data() {
        return {
            projectDefaults: null,
            loading: false,
            editing: false,
            processing: false,
            errors: null,
            users: [],
            currency: null,
            status: [],
            // Project
            project: {
                id: null,
                title: null,
                description: null,
                user_id: null,
                discount: null,
                km_price: null,
                status: null
            },
        };
    },
    async created() {
        // Load all resources
        this.loading = true;
        await this.fetchUsers();
        this.status = this.appCfgRead("Projects.status", true);

        if (!this.context.empty) {
            // Editing -> use context
            this.editing = true;
            this.project = Cloner.pickClone(this.context, [
                "id",
                "title",
                "description",
                "user_id",
                "discount",
                "km_price",
                "status"
            ]);

            this.currency = this.context.currency;
        } else {
            // Create -> fetchDetails -> contractId
            await this.fetchDefaults();
            this.project.title = this.projectDefaults.title;
            this.project.description = this.projectDefaults.description;
            this.project.user_id = this.projectDefaults.user_id;
            this.currency = this.projectDefaults.currency;
        }

        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {
                this.processing = true;
                if (this.editing) {
                    const r = await ProjectService.PUT(this.project);
                    this.$emit("save", r.data.data);
                } else {
                    const r = await ProjectService.POST_fromContract(
                        this.contractId,
                        this.project
                    );

                    let project_id = r.data.data.project_id;

                    this.$msgSuccess(
                        this.$t(
                            "project.notify_new_project_from_contract_created"
                        )
                    );

                    this.$router.push({
                        name: "ProjectViewTasks",
                        params: { project_id },
                    });
                }
            } catch (e) {
                if (e.response.status == 409) {
                    this.$handleExceptionError(
                        e,
                        this.$t("project.error_project_cannot_be_created")
                    );
                } else {
                    this.errors = e.response.data.errors;
                }
            }
        },
        async fetchDefaults() {
            try {
                const r = await ContractService.GET_defaultsForContract(
                    this.contractId
                );
                console.log("DATA", r.data.data);
                this.projectDefaults = r.data.data;
            } catch (e) {
                console.log("fetchDefaults exception", e);
            }
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="project-modal"
    >
        <template slot="header">
            {{ $t("project.create_project_from_contract") }}
        </template>
        <template slot="default">
            <FormulateForm
                v-model="project"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="multiselect"
                            :options="users"
                            name="user_id"
                            :validation-name="$t('project.responsible_user')"
                            :label="$t('project.responsible_user')"
                            validation="required"
                            :placeholder="
                                $t('project.placeholder_responsible_user')
                            "
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="text"
                            name="title"
                            :validation-name="$t('project.title')"
                            :label="$t('project.title_case_name')"
                            validation="required"
                            :disabled="editing"
                            :placeholder="$t('project.placeholder_case_title')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="select"
                            :options="status"
                            name="status"
                            :validation-name="$t('project.status')"
                            :label="$t('project.status')"
                            validation="required"
                            :placeholder="$t('project.placeholder_status')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="textarea"
                            name="description"
                            :validation-name="$t('project.description')"
                            :label="$t('project.description')"
                            :placeholder="$t('project.placeholder_description')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="groupdecimal"
                            append="%"
                            name="discount"
                            validation="number|min:0|max:100"
                            :label="$t('project.discount')"
                            :validation-name="$t('project.discount')"
                            :placeholder="$t('project.placeholder_discount')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="currency"
                            :currency="currency"
                            name="km_price"
                            :label="$t('project.km_price')"
                            :validation-name="$t('project.km_price')"
                            :placeholder="$t('project.placeholder_km_price')"
                        />
                    </div>
                </div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>
