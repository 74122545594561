<script>
import VProject from "@/components/Projects/VProject";
import VFilters from "@/components/Search/VFilters";
import VProjectFilter from "@/components/Search/VProjectFilter";
import VProjectForm from "@/components/Forms/VProjectForm";
import VDeptFilter from "@/components/Search/VDeptFilter";
import CustomerService from "@/services/CustomerService";
import ProjectService from "@/services/ProjectService";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import {mapGetters} from "vuex";

export default {
    name: "VCases",
    props: {
        customer_id: {
            description: "Customer ID from navigation",
        },
    },
    components: {
        VProject,
        VProjectForm,
        VFilters,
        VProjectFilter,
        VDeptFilter,
    },
    data() {
        return {
            loading: false,
            projects: [],
            meta: null,
            addProject: false,
            addSection: false,
            customers: [],
            customer: null,

            stats: {
                budget: 0,
                budget_used: 0,
                over_budget: 0,
                past_deadline: 0,
            },

            filterViews: {
                actawait: this.$t("projects.filter_active_and_awaiting"),
                act: this.$t("projects.filter_active"),
                awaiting: this.$t("projects.filter_awaiting"),
                //hld: this.$t("projects.filter_on_hold"),
                fin: this.$t("projects.filter_finished"),
                ccl: this.$t("projects.filter_cancelled"),
                all: this.$t("projects.filter_all"),
            },

            sortViews: {
                null: this.$t("projects.sort_default"),
                "Customers.name": this.$t("projects.sort_customer"),
                "Projects.title": this.$t("projects.sort_title"),
                "Projects.deadline_date": this.$t("projects.sort_deadline"),
                "Projects.status": this.$t("projects.sort_status"),
                "Projects.budget_amount": this.$t("projects.sort_budget"),
            },
        };
    },
    computed: mapGetters("auth", ["getInvoiceSetup"]),
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);

        this.loading = true;
        await this.fetchCustomer(true);
        await this.fetchCustomers(true);
        this.apiFilterView = "actawait";
        this.apiSearchFilter = { search: "", favorites_user_id: null, invoice_setup: this.getInvoiceSetup };
        this.$apiEnableWatcher();
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        /**
         * Fetch projects
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                // We filter it ONLY for a gtiven customer!
                params["customer_id"] = this.customer_id;
                const r = await ProjectService.GET(params);
                this.projects = r.data.data;
                this.meta = r.data.meta;
                this.stats = r.data.meta.stats;
            } catch (e) {
                console.log("Project exception", e);
            }
            this.loading = false;
        },
        /**
         * Fetch customer info
         */
        async fetchCustomer(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.GETS(this.customer_id);
                this.customer = r.data.data;
            } catch (e) {}
            this.loading = loading;
        },
        closeSave() {
            this.addProject = false;
            this.fetchData();
        },
        /**
         * Fetch dropdown data
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        humanize(number) {
            return CurrencyHumanizer(number, "DKK");
        },
        /**
         * Change the favorite flag for a given user (identified by a session)
         */
        async changeFavorite(project_id, flag) {
            console.log("Change favorite: " + project_id + " / " + flag);
            try {
                const r = await ProjectService.changeFavorite(project_id, flag);
                let project = r.data.data;
                let foundKey = this.projects.findIndex(
                    (p) => p.id == project.id
                );
                this.$set(this.projects, foundKey, project);
            } catch (e) {}
        },
    },
};
</script>

<template>
    <div class="v-projects">
        <base-header class="pb-1" type="">
            <div class="row pt-3" v-if="false">
                <div class="col-12 col-md-3">
                    <nav-stats-card
                        :title="$t('projects.stat_budget')"
                        :value="humanize(stats.budget)"
                        type="default"
                        icon="fal fa-chart-line"
                    />
                </div>

                <div class="col-12 col-md-3">
                    <nav-stats-card
                        :title="$t('projects.stat_used_budget')"
                        :value="humanize(stats.budget_used)"
                        :type="stats.budget_used == 0 ? 'default' : 'warning'"
                        icon="fal fa-chart-pie-alt"
                    />
                </div>

                <div class="col-12 col-md-3">
                    <nav-stats-card
                        :title="$t('projects.stat_over_budget')"
                        :value="stats.over_budget"
                        :type="stats.over_budget == 0 ? 'default' : 'danger'"
                        icon="fal fa-siren-on"
                    />
                </div>

                <div class="col-12 col-md-3">
                    <nav-stats-card
                        :title="$t('projects.stat_past_deadline')"
                        :value="stats.past_deadline"
                        :type="stats.past_deadline == 0 ? 'default' : 'danger'"
                        icon="fal fa-calendar-exclamation"
                    />
                </div>
            </div>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        :filter-views="filterViews"
                        :filter-view="apiFilterView"
                        :filter-name="$t('projects.filter_grp_name')"
                        filter-icon="far fa-flag-alt"
                        @filter-view-change="$handleFilterView($event)"
                        :paginator="apiPaginator"
                        :sort-views="sortViews"
                        @sort="$handleSorting($event)"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="$t('projects.placeholder_search')"
                    >
                        <template slot="left">
                            <base-button
                                type="secondary"
                                @click="addProject = true"
                                >{{ $t("project.create_case") }}
                                <i class="far fa-plus"></i
                            ></base-button>
                        </template>

                        <template slot="right">
                            <v-dept-filter v-model="apiSearchFilter" />
                            <v-project-filter v-model="apiSearchFilter" class="ml-4" />
                        </template>
                    </v-filters>

                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="projects.length">
                        <v-project
                            v-for="project in projects"
                            :key="project.id"
                            :project="project"
                            view-context="customer"
                            @favorite="changeFavorite"
                        />

                        <div class="d-flex justify-content-end mt-4">
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </div>
                    </template>
                    <card body-classes="px-0" v-else>
                        <div class="pb-mg">
                            <div class="nice-info">
                                {{ $t("projects.no_projects") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-project-form
                v-if="addProject"
                @close="addProject = false"
                @close-refresh="closeSave"
                :customer_id="customer_id"
                :context="{ empty: true }"
            />
        </portal>
    </div>
</template>
