<script>
export default {
    props: {
        contract: {},
        condition: {}
    }
};
</script>

<template>
    <div class="small mt-2">
        <template v-if="contract.contract_type === 1">
            <div>
                {{ $t("conditions.base_fee") }}:
                {{
                    $n(condition.base_fee, {
                        style: "currency",
                        currency: contract.currency,
                        currencyDisplay: "code"
                    })
                }}
            </div>

            <div>
                {{ condition.adjustment_type_name }}
            </div>

            <div v-if="condition.min_adjustment_perc > 0">
                {{ $t("conditions.min_adj") }}:
                {{ $n(condition.min_adjustment_perc, "decimal") }} %
            </div>

            <div>
                {{ $t("conditions.from") }}:
                {{ $d(new Date(condition.valid_from), "short") }}
            </div>

            <div v-if="condition.is_first_year" class="text-warning">
                <i class="far fa-exclamation-triangle"></i>
                {{ $t("conditions.notice_first_year_conditions") }}
            </div>
        </template>
        <template v-else-if="contract.contract_type === 2">
            <div>
                {{ $t("conditions.fee") }}:
                {{ $n(condition.base_fee_perc, "decimal") }} %
            </div>

            <div>
                {{ condition.adjustment_type_name }}
                <span v-if="condition.adjustment_type == 2"
                    >({{ $n(condition.adjustment_perc) }} %)</span
                >
                <div v-if="condition.adjustment_subtype > 0">
                    {{ $t("conditions.adj_subtype") }}:
                    {{ condition.adjustment_subtype_name }}
                </div>
            </div>

            <div v-if="condition.min_adjustment_perc > 0">
                {{ $t("conditions.min_adj") }}:
                {{ $n(condition.min_adjustment_perc, "decimal") }} %
            </div>

            <div>
                {{ $t("conditions.from") }}:
                {{ $d(new Date(condition.valid_from), "short") }}
            </div>
        </template>
    </div>
</template>
