<script>
import VContract from "@/components/Momentum/Contracts/VContract";
import VTurnover from "@/components/Momentum/Contracts/VTurnover";
import VContractForm from "@/components/Forms/VContractForm";
import ContractService from "@/services/ContractService";

export default {
    name: "VContracts",
    components: {
        VContract,
        VTurnover,
        VContractForm,
    },
    props: {
        customer_id: {
            description: "Customer ID",
        },
    },
    data() {
        return {
            loading: false,
            contracts: [],
            editing: false,
            editContext: null,
            editMethod: ""
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchContracts();
    },
    methods: {
        async fetchContracts() {
            try {
                this.loading = true;
                const r = await ContractService.GET_AllForCustomer({
                    customer_id: this.customer_id,
                });
                this.contracts = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx, editMethod) {
            this.editContext = ctx;
            this.editing = true;
            this.editMethod = editMethod;
        },
        /**
         * Finish
         */
        async closeSave() {
            this.editing = false;
            await this.fetchContracts();
        },
    },
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("contracts.contracts") }}</h1>
                    </div>
                    <div class="mg-title__right">
                        <base-button
                            v-if="ACL_RW"
                            size="sm"
                            type="secondary"
                            @click="setContext({ empty: true })"
                            ><i class="far fa-plus"></i>
                            {{ $t("contracts.add_contract") }}</base-button
                        >
                    </div>
                </div>

                <v-loader :loading="loading" v-if="loading" />

                <template v-else-if="contracts.length">
                    <v-contract
                        v-for="contract in contracts"
                        :key="contract.id"
                        :contract="contract"
                        @edit-contract="setContext"
                        @reload="fetchContracts"
                    />
                </template>

                <card body-classes="px-0" v-else>
                    <div class="pb-mg">
                        <div class="nice-info">
                            {{ $t("contract.no_contracts") }}
                        </div>
                    </div>
                </card>
            </div>
        </div>
        <!-- .row for contracts -->

        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("additional_turnover") }}</h1>
                    </div>
                    <div class="mg-title__right"></div>
                </div>

                <v-turnover :customer_id="customer_id" />
            </div>
        </div>
        <!-- .row for additional turnover -->

        <v-contract-form
            v-if="editing"
            :customer_id="customer_id"
            :context="editContext"
            :method="editMethod"
            @close="editing = false"
            @close-refresh="closeSave"
        />
    </div>
</template>
