<script>
import { mapGetters } from "vuex";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import Cloner from "@/libraries/Cloner";

// this.apiSearchFilter['favorites_user_id'] = this.$store.getters["auth/getMyId"];
export default {
    name: "VProjectFilter",
    props: {
        value: {
            description: "v-model support",
        },
    },
    components: {
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    data() {
        return {
            filterViews: {
                all: this.$t("project_filter.all"),
                responsible: this.$t("project_filter.responsible"),
                participating: this.$t("project_filter.participating"),
                //favorites: this.$t("project_filter.favorites"),
                pm_1: this.$t("project.filter_fixed_price_projects"),
                pm_2: this.$t("project.filter_billable_hours_projects"),
                pm_3: this.$t("project.filter_admin_projects"),
                pt_1: this.$t("project.filter_wind_projects"),
                pt_2: this.$t("project.filter_sun_projects"),
                pt_3: this.$t("project.filter_other_projects"),
                //budget: this.$t("project_filter.over_budget"),
                deadline: this.$t("project_filter.past_deadline"),
            },
            currentFilterView: "",
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
    },
    created() {
        this.currentFilterView = "all";
    },
    methods: {
        handleInput() {
            if (parseInt(this.value) > 0) {
                //this.$emit("input", null);
            } else {
                //this.$emit("input", this.getMyId);
            }
        },
        handleCommand(cmd) {
            let copy = Cloner.clone(this.value);
            copy.user_id = null;
            copy.connected_user_id = null;
            copy.favorites_user_id = null;
            copy.filter = null;

            this.currentFilterView = cmd;

            switch (cmd) {
                case "responsible":
                    copy.user_id = this.getMyId;
                    break;

                case "participating":
                    copy.connected_user_id = this.getMyId;
                    break;

                case "favorites":
                    copy.favorites_user_id = this.getMyId;
                    break;

                case "budget":
                    copy.filter = "over_budget";
                    break;

                case "deadline":
                    copy.filter = "past_deadline";
                    break;

                case "pm_1":
                    copy.project_model = 1;
                    break;

                case "pm_2":
                    copy.project_model = 2;
                    break;

                case "pm_3":
                    copy.project_model = 3;
                    break;

                case "pt_1":
                    copy.project_type = 1;
                    break;

                case "pt_2":
                    copy.project_type = 2;
                    break;

                case "pt_3":
                    copy.project_type = 3;
                    break;

                default:
                    this.cmd = "all";
                    break;
            }

            this.$emit("input", copy);
        },
        showCheck(idx) {
            if (idx == "null") {
                idx = null;
            }

            if (idx == this.currentFilterView) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<template>
    <div>
        <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
                <i class="far fa-filter"></i>

                <template v-if="currentFilterView">
                    <span class="text-default">{{
                        filterViews[currentFilterView]
                    }}</span></template
                >
            </span>
            <el-dropdown-menu slot="dropdown">
                <!-- Hash object {a:b, c:d ...} -->

                <el-dropdown-item
                    v-for="(filter, index) in filterViews"
                    :key="index"
                    :command="index"
                >
                    {{ filter }}
                    <i v-if="showCheck(index)" class="far fa-check"></i>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<style lang="scss">
.v-favorite-filter {
    i.fal {
        color: rgb(96, 98, 102);
    }

    .fav-label {
        margin-left: 3px;
        color: rgb(96, 98, 102);
        font-weight: 400;
    }
}
.el-dropdown-link i {
    margin-right: 5px;
}
</style>
