<script>
import ContractService from "@/services/ContractService";
import VContractRevision from "@/components/Momentum/Contracts/VContractRevision";

export default {    
    name: "VContractHistory",
    components: {
        VContractRevision,
    },
    props: {
        contract: {
            description: "Orig contract",
        },
    },
    data() {
        return {
            loading: false,
            contracts: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await ContractService.GET_Revisions(this.contract.id); 
                this.contracts = r.data.data; 
            } catch (e) {}
            this.loading = false; 
        },
    },
};
</script>

<template>
    <div>
        <v-loader :loading="loading" v-if="loading" />
        <template v-else>

        
        <v-contract-revision v-for="c in contracts" :key="c.id" :contract="c" />        
        </template>
    </div>
</template>

<style>
</style>