

<script>
export default {
    name: "VMdayTag",
    props: {
        notice: {
            type: Object,
            required: true,
            description: "Notice Object",
        },
        tag: {
            type: String,
            default: "div",
            description: "Html tag to use for the badge.",
        },
        icon: {
            type: String,
            default: "fal fa-window-close",
            description: "Close icon",
        },
        type: {
            type: String,
            default: "default",
            description:
                "Label type (primary|info|danger|default|warning|success)",
        },
        size: {
            type: String,
            description: "Label size (md, lg)",
            default: "",
        },
    },
    computed: {
        nice_date: function () {
            let spl = this.notice.notice_date.split("-");
            return `${spl[1]}.${spl[0]}`;
        },
    },
};
</script>


<template>
    <component
        :is="tag"
        class="v-tag btn"
        :class="[`btn-${type}`, { [`btn-${size}`]: size }]"
    >
        <div class="date-container">
            <span class="date-entry">{{ nice_date }}</span>
            <i
                :class="icon"
                class="fa-lg"
                @click="$emit('delete', notice.notice_date)"
            ></i>
        </div>
    </component>
</template>

<style lang="scss">
.v-tag {
    width: 47%; 
    margin-bottom: 8px;

    .date-container {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;

        i {
            padding-top: 5px;
        }
    }
}
</style>
