
<script>
import InvoiceService from "@/services/InvoiceService";

export default {

    name: "VInvoicePlan",
    props: {
        contract_id: {
            description: "Contract ID",
        },
    },
    data() {
        return {
            invoiceData: [],
        };
    },
    async created() {
        await this.fetchFromContract();
    },
    methods: {
        async fetchFromContract() {
            try {
                const r = await InvoiceService.invoicingPreviewContract(
                    this.contract_id
                );
                console.log(r.data.data);
                this.invoiceData = r.data.data;
            } catch (e) {}
        },
    },
};
</script>

<template>
    <div class="v-invoice-plan">
        <v-table class="table table-sm">
            <v-tbody>
                <template v-for="(data, year) in invoiceData">
                    <v-tr :key="year" class="bg-gray">
                        <v-td colspan="6" class="v-invoice-plan__header">
                            {{ $t("invoice.accounting_year", { year }) }}
                        </v-td>
                    </v-tr>
                    <v-tr :key="`${year}.extra`">
                        <v-th>{{ $t("invoice.number") }}</v-th>
                        <v-th>{{ $t("invoice.type") }}</v-th>
                        <v-th>{{ $t("invoice.invoice_date") }}</v-th>
                        <v-th>{{ $t("invoice.period_from") }}</v-th>
                        <v-th>{{ $t("invoice.period_to") }}</v-th>
                        <v-th class="text-center">{{
                            $t("invoice.regulation")
                        }}</v-th>
                    </v-tr>
                    <v-tr
                        v-for="(invoice, index) in data"
                        :key="`${year}.${index}`"
                    >
                        <v-td>
                            {{
                                $t("invoice.short_numbering", {
                                    n: invoice.invoice_idx,
                                    of: invoice.invoice_total,
                                })
                            }}
                        </v-td>
                        <v-td>
                            {{ invoice.type_name }}
                            <template
                                v-if="
                                    !invoice.aligned &&
                                    invoice.type == 'contract'
                                "
                            >
                                <div class="small text-warning">
                                    <i class="far fa-exclamation-triangle"></i>
                                    {{
                                        $t("invoice.used_days", {
                                            n: invoice.period_used,
                                            of: invoice.period_length,
                                        })
                                    }}
                                </div>
                            </template>
                        </v-td>
                        <v-td>
                            <template v-if="invoice.invoice_date_shifted">
                                {{
                                    $d(
                                        new Date(invoice.invoice_date_shifted),
                                        "short"
                                    )
                                }}
                                <div class="small text-muted">
                                    <i class="far fa-history"></i>
                                    {{
                                        $d(
                                            new Date(
                                                invoice.calculated_invoice_date
                                            ),
                                            "short"
                                        )
                                    }}
                                </div>
                            </template>
                            <template v-else>
                                {{
                                    $d(new Date(invoice.invoice_date), "short")
                                }}
                            </template>
                        </v-td>

                        <template v-if="invoice.type == 'contract'">
                            <v-td>
                                {{ $d(new Date(invoice.from_date), "short") }}
                            </v-td>
                            <v-td>
                                {{ $d(new Date(invoice.to_date), "short") }}
                            </v-td>
                        </template>
                        <template v-else>
                            <v-td colspan="2">
                                {{ $t("invoice.accounting_year", { year }) }}
                            </v-td>
                        </template>

                        <v-td class="text-center">
                            <span v-if="invoice.adjustment.length == 0"
                                >---</span
                            >
                            <template v-else>
                                <span class="text-muted"
                                    >x{{ invoice.adjustment.series }}</span
                                >
                            </template>
                        </v-td>
                    </v-tr>
                </template>
            </v-tbody>
        </v-table>
    </div>
</template>

<style lang="scss">
.v-invoice-plan {
    .bg-gray {
        background: $gray-300 !important;
    }

    &__header {
        color: $headings-color !important;
        font-size: 15px;
        font-weight: bold;
    }
}
</style>