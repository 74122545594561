var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.customer)?[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag',{attrs:{"crumbs":{
                    title: _vm.customer.name,
                    chain: [null, _vm.$t('breadcrumbs.details')],
                }}},[_c('div',{staticClass:"row top-navi-block"},[_c('div',{staticClass:"col"},[_c('navi-block',{attrs:{"navigate":{
                                name: 'CustomersManage',
                            },"title":_vm.$t('customers.top_menu_overview')}})],1),(_vm.customer.internal_customer == 1)?_c('div',{staticClass:"col"},[_c('navi-block',{attrs:{"navigate":{
                                name: 'CustomersTabInternalTime',
                            },"title":_vm.$t('customers.top_menu_internal_time')}})],1):_vm._e(),_c('div',{staticClass:"col"},[_c('navi-block',{attrs:{"navigate":{
                                name: 'CustomersTabCases',
                            },"title":_vm.$tc('customers.top_menu_cases', _vm.customer.count_cases)}})],1),_c('div',{staticClass:"col"},[_c('navi-block',{attrs:{"navigate":{
                                name: 'CustomersTabContracts',
                            },"title":_vm.$tc('contracts.top_menu_contracts', _vm.customer.count_contracts)}})],1),_c('div',{staticClass:"col"},[_c('navi-block',{attrs:{"navigate":{
                                name: 'CustomersTabSubscriptions',
                            },"title":_vm.$tc('contracts.top_menu_subscriptions', _vm.customer.count_subscriptions)}})],1),_c('div',{staticClass:"col"},[_c('navi-block',{attrs:{"navigate":{
                                name: 'CustomersTabInvoices',
                            },"title":_vm.$t('customers.top_menu_invoice_archive')}})],1)])]),(false)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('nav-stats-card',{attrs:{"title":_vm.$t('customers.data_and_stats'),"value":_vm.$t('customers.overview'),"type":"default","icon":"fal fa-th-large","navigate":{ name: 'CustomersManage' }}})],1),_c('div',{staticClass:"col-3"},[_c('nav-stats-card',{attrs:{"title":_vm.$tc('customers.x_cases', _vm.customer.count_cases),"value":_vm.$t('customers.cases'),"type":"default","icon":"fal fa-file-invoice","navigate":{ name: 'CustomersTabCases' }}})],1),_c('div',{staticClass:"col-3"},[_c('nav-stats-card',{attrs:{"title":_vm.$tc(
                                'customers.x_contracts',
                                _vm.customer.count_contracts
                            ),"value":_vm.$t('contracts.contracts'),"type":"default","icon":"fal fa-file-signature","navigate":{ name: 'CustomersTabContracts' }}})],1),_c('div',{staticClass:"col-3"},[_c('nav-stats-card',{attrs:{"title":_vm.$t('customers.economic_live_data'),"value":_vm.$t('customers.invoice_archive'),"type":"default","icon":"fal fa-file-invoice-dollar","navigate":{ name: 'CustomersTabInvoices' }}})],1)]):_vm._e()],1),_c('router-view',{attrs:{"name":"customer_tab"}})]:_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("customer.not_found"))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }