<script>
import VSubscriptions from "@/components/Momentum/Subscriptions/VSubscriptions";
import VCharges from "@/components/Momentum/Contracts/VCharges";

export default {
    name: "VTurnover",
    components: {
        VSubscriptions,
        VCharges,
    },
    props: {
        customer_id: {
            description: "Customer ID",
        },
    },    
};
</script>

<template>
    <card class="additional-turnover" body-classes="px-0">
        <v-subscriptions :customer_id="customer_id" />
        <v-charges :customer_id="customer_id" />
    </card>
</template>