<script>
/**
 * Contract timeline -> here the grid is generated with columns and contract_details are
 * plotted onto the sceen.
 *
 * Events:
 * `reload`: reload cds + budgets
 */
import ContractTimelineBox from "@/components/Momentum/NewContracts/ContractTimelineBox";
import ContractTimelineEmptyBox from "@/components/Momentum/NewContracts/ContractTimelineEmptyBox";
import ContractTimelineRegulation from "@/components/Momentum/NewContracts/ContractTimelineRegulation";
import Cloner from "@/libraries/Cloner";
import VBudgets from "@/components/Momentum/NewContracts/VBudgets";
import VBudgetLinkForm from "@/components/Forms/VBudgetLinkForm";

import { EventBus } from "@/libraries/EventBus";
import dayjs from "dayjs";

export default {
    props: {
        contract: {},
        contractDetails: {},
        year: {},
        enableEditing: {
            type: Boolean,
            default: false
        },
        stats: {}
    },
    components: {
        VBudgets,
        VBudgetLinkForm,
        ContractTimelineBox,
        ContractTimelineEmptyBox,
        ContractTimelineRegulation
    },
    data() {
        return {
            showBudgetLinkModal: false,
            budgetLinkContext: null,
            showDetailsModal: false,
            columns: [],
            colors: [
                "budget1",
                "budget2",
                "budget3",
                "budget4",
                "budget5",
                "budget6",
                "budget7",
                "budget8",
                "budget9",
                "budget10",
                "budget11",
                "budget12"
            ]
        };
    },
    computed: {
        extraRegulationContractDetail() {
            // if variable contract
            if (this.contract.contract_type === 2) {
                const idx = this.contractDetails.findIndex(
                    p => p.entry_type == 3
                );
                if (idx > 0) {
                    return this.contractDetails[idx];
                }
            }

            return false;
        },
        regulationContractDetail() {
            // if variable contract
            if (this.contract.contract_type === 2) {
                const idx = this.contractDetails.findIndex(
                    p => p.entry_type == 2
                );
                if (idx > 0) {
                    return this.contractDetails[idx];
                }
            }

            return {};
        },
        /**
         * Automatic generation of budget colors
         */
        budgetColors() {
            let colors = {};
            this.contractDetails.forEach(elem => {
                if (parseInt(elem.budget_id) > 0) {
                    colors[elem.budget_id] = true;
                }
            });

            colors = Object.keys(colors);
            let srcColors = Cloner.clone(this.colors);

            let finalColors = {};
            if (colors.length > 0) {
                colors.forEach(elem => {
                    finalColors[elem] = srcColors.shift();
                });
            }

            return finalColors;
        }
    },
    created() {
        this.gridGenerator();
    },
    methods: {
        /**
         * Edit a budget
         * @param cd
         */
        editBudget(cd) {
            this.budgetLinkContext = cd;
            this.showBudgetLinkModal = true;
        },
        /**
         * Reload also budgets - fired after budget linking
         */
        reloadAfterBudgets() {
            this.budgetLinkContext = null;
            this.showBudgetLinkModal = false;
            this.$emit("reload");
        },
        /**
         * Generate the grid for presentation
         */
        async gridGenerator() {
            const contractDetails = this.contractDetails;
            let columns = [];

            for (let i = 0; i < 12; i++) {
                columns[i] = "free";
            }

            // Check if start date is limiting first elements
            if (
                parseInt(dayjs(this.contract.from_date).format("YYYY")) ===
                parseInt(this.year)
            ) {
                const term_month =
                    parseInt(dayjs(this.contract.from_date).format("M")) - 1;

                for (let i = 0; i < 12; i++) {
                    if (i < term_month) {
                        columns[i] = "block";
                    }
                }
            }

            // check if the contract is terminated
            if (
                this.contract.has_termination &&
                parseInt(
                    dayjs(this.contract.termination_date).format("YYYY")
                ) === parseInt(this.year)
            ) {
                // -1 is here because dayjs hits month from 0 - 11
                const term_month =
                    parseInt(
                        dayjs(this.contract.termination_date).format("M")
                    ) - 1;

                for (let i = 0; i < 12; i++) {
                    if (i > term_month) {
                        columns[i] = "block";
                    }
                }
            }

            // cancelled -> show nothing.
            if (this.contract.active === 0) {
                for (let i = 0; i < 12; i++) {
                    columns[i] = "block";
                }
            }

            contractDetails.forEach(p => {
                if (p.entry_type == 1) {
                    const start = dayjs(p.date_from).month();
                    const end = dayjs(p.date_to).month();
                    for (let i = start; i <= end; i++) {
                        columns[i] = p.id;
                    }
                }
            });

            if (!Array.isArray(columns) || columns.length === 0) {
                return [];
            }

            const result = [];
            let currentElement = columns[0];
            let count = 1;

            for (let i = 1; i < columns.length; i++) {
                if (columns[i] === currentElement) {
                    count++;
                } else {
                    result.push({ id: currentElement, length: count });
                    // reset conditions
                    currentElement = columns[i];
                    count = 1;
                }
            }

            // Push the last element
            result.push({ id: currentElement, length: count });

            let current = 1;
            result.forEach(elem => {
                elem["mon_start"] = current;
                elem["mon_end"] = current + elem.length - 1;
                current = current + elem.length;
            });

            result.forEach((val, key) => {
                if (val.id !== "free") {
                    result[key]["period"] = contractDetails.find(v => {
                        return v.id == val.id;
                    });
                }
            });

            this.columns = result;
        }
    }
};
</script>

<template>
    <div>
        <div class="row px-4 contract-timeline">
            <div
                v-for="(cd, index) in columns"
                :key="index"
                class="contract-timeline__elem"
                :class="`col-${cd.length}`"
            >
                <contract-timeline-box
                    :cd="cd.period"
                    :stats="stats"
                    :budget-colors="budgetColors"
                    :enable-editing="enableEditing"
                    @edit-budget="editBudget"
                    @reload="$emit('reload')"
                    v-if="cd.id !== 'free' && cd.id !== 'block'"
                />
                <contract-timeline-empty-box
                    :cd="cd"
                    :columns="columns"
                    :column-index="index"
                    :length="cd.length"
                    :contract="contract"
                    :year="year"
                    :enable-editing="enableEditing"
                    @reload="$emit('reload')"
                    v-else
                />
            </div>
        </div>
        <div class="row mt-4" v-if="contract.contract_type == 2">
            
            <div class="col-12 mb-3" v-if="extraRegulationContractDetail">
                <contract-timeline-regulation
                    :cd="extraRegulationContractDetail"
                    :year="year"
                    :currency="contract.currency"
                    @reload="$emit('reload')"
                />
            </div>

            <div class="col-12">
                <contract-timeline-regulation
                    :cd="regulationContractDetail"
                    :year="year"
                />
            </div>
        </div>
        <!-- Just for debugging -> colors defined in additional.scss -->
        <div class="row" v-if="false">
            <div
                class="col text-center"
                :class="`budget${i}`"
                v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                :key="i"
            >
                {{ i }}
            </div>
        </div>
        <!-- .row px-4 -->
        <hr />

        <v-budgets
            :contract="contract"
            :colors="budgetColors"
            :year="year"
            :budget-colors="budgetColors"
            @reload="$emit('reload')"
        />

        <portal to="modals">
            <v-budget-link-form
                v-if="showBudgetLinkModal"
                :context="budgetLinkContext"
                :budget-colors="budgetColors"
                @close="showBudgetLinkModal = false"
                @close-reload="reloadAfterBudgets"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.contract-timeline {
    .contract-detail-status {
        text-align: center;
        font-size: 13px;
        margin-top: 15px;

        margin-left: 5px;
        margin-right: 5px;
        border-radius: 5px;
        padding-top: 4px;
        padding-bottom: 4px;

        &-0 {
            color: $warning;
            border: 1px solid $warning;
        }

        &-1 {
            color: $success;
            border: 1px solid $success;
        }

        &-2 {
            color: $default;
            border: 1px solid $default;
        }
    }

    .budget-indicator {
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .c-date {
        font-size: 12px;
        font-weight: 600;
        color: $headings-color !important;
    }

    .c-length {
        font-size: 10px;
        font-weight: 600;
        color: $gray-600 !important;
    }

    &__elem {
        min-height: 100%;
        padding: 0px;
        &:not(:last-child) {
            //border-right: 1px solid red;
        }
        &:not(:first-child) {
        }
    }

    &__inner {
        border-radius: 6px;
        background: $gray-100;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 3px;
        margin-right: 3px;
    }

    &__content {
        color: $text-gray;
        font-size: 12px;
        border-radius: 6px;
        border: 1px solid $gray-400;
        background: white;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 3px;
        margin-right: 3px;
    }

    &__container {
        color: $text-gray;
        font-size: 12px;
        background: white;

        margin-left: 3px;
        margin-right: 3px;
    }

    &__editing {
        margin-top: 15px;
        border-top: 2px dashed $danger;
        border-bottom: 2px dashed $danger;
        border-right: 1px dotted $danger;
        border-left: 1px dotted $danger;
    }
}
</style>
