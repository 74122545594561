var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-4 contract-q-dash"},[_c('div',{staticClass:"col"},[_c('h-inner-dash-card',{attrs:{"title":_vm.$t('contract.total_calculated'),"value":_vm.$n(_vm.meta.status.total_sum, {
                    style: 'currency',
                    currency: _vm.meta.status.currency,
                    currencyDisplay: 'code'
                }),"footer":_vm.$t('contract.hlp_total_sum')}})],1),_c('div',{staticClass:"col"},[_c('h-inner-dash-card',{attrs:{"title":_vm.$t('contract.calculated_invoiced'),"value":_vm.$n(_vm.meta.status.invoiced_cds, {
                    style: 'currency',
                    currency: _vm.meta.status.currency,
                    currencyDisplay: 'code'
                }),"footer":_vm.$t('contract.hlp_calculated_invoiced')}})],1),_c('div',{staticClass:"col"},[_c('h-inner-dash-card',{attrs:{"title":_vm.$t('contract.invoiced_so_far'),"value":_vm.$n(_vm.meta.status.invoiced_invoiced, {
                    style: 'currency',
                    currency: _vm.meta.status.currency,
                    currencyDisplay: 'code'
                }),"footer":_vm.$t('contract.hlp_invoiced_so_far')}})],1),_c('div',{staticClass:"col"},[_c('h-inner-dash-card',{attrs:{"title":_vm.$t('contract.difference_in_periods'),"value":_vm.$n(_vm.meta.status.regulation, {
                    style: 'currency',
                    currency: _vm.meta.status.currency,
                    currencyDisplay: 'code'
                }),"footer":_vm.$t('contract.hlp_difference_in_periods'),"type":_vm.meta.status.regulation < 0 ? 'danger' : ''}})],1),_c('div',{staticClass:"col"},[_c('h-inner-dash-card',{attrs:{"title":_vm.$t('contract.awaiting_invoicing'),"value":_vm.$n(_vm.meta.status.awaiting, {
                    style: 'currency',
                    currency: _vm.meta.status.currency,
                    currencyDisplay: 'code'
                }),"type":_vm.meta.status.awaiting < 0 ? 'danger' : '',"footer":_vm.$t('contract.hlp_awaiting_invoicing')}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }