import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "contract-details",


    async PUT_editHalfBudget(contract_detail_id, data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/edit-half-budget/${contract_detail_id}`,
            data
        );
    },

    async GET_contractInvoicing(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/contract-invoicing`,
            {
                params: params
            }
        );
    },

    async POST_createPeriod(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    async PUT_shrink(contract_detail_id, data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/shrink/${contract_detail_id}`,
            data
        );
    },

    async PUT_void(contract_detail_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/void/${contract_detail_id}`
        );
    },

    async PUT_setBudgetLink(contract_detail_id, budget_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/set-budget-link/${contract_detail_id}`,
            { budget_id }
        );
    },

    async PUT_setBudgetLinks(contract_id, budget_id, year) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/set-budget-links/${contract_id}`,
            { budget_id, year, contract_id }
        );
    },

    async GET_forContract(contract_id, year, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/for-contract/${contract_id}/${year}`,
            { params: params }
        );
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};
