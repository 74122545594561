<script>
import AssetService from "@/services/AssetService";
import AssetGroupService from "@/services/AssetGroupService";
import CurrencyService from "@/services/CurrencyService";
import CountryList from "@/libraries/CountryList";
import { pick } from "lodash";

export default {
    name: "VAssetForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        contract: {
            type: Object,
            description: "Contract ID",
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            assetGroups: [],
            currencies: [],
            asset: {
                id: null,
                asset_group_id: null,
                contract_id: null,
                qty: null,
                asset_value: null,
                asset_value_currency: null,
                rating_mw: null,
                subsidy_expiration_date: null,
                location_country: null,
            },
        };
    },
    computed: {
        countries: function () {
            return CountryList.select();
        }
    },
    async created() {
        // These should be here or else selects won't be "selected"
        this.loading = true;
        await this.fetchGroups();
        await this.fetchCurrencies();
        this.loading = false;

        console.log("groups", this.assetGroups);

        if (!this.context.empty) {
            this.editing = true;
            this.asset = pick(this.context, [
                "id",
                "asset_group_id",
                "contract_id",
                "qty",
                "asset_value",
                "asset_value_currency",
                "rating_mw",
                "subsidy_expiration_date",
                "location_country",
            ]);
        } else {
            // Default currency of the contract
            this.asset.asset_value_currency = this.contract.currency;
            this.asset.contract_id = this.contract.id;
        }
    },
    methods: {
        /**
         * Delete entity
         */
        async deleteEntity() {
            if (!this.editing) {
                return;
            }

            this.$swal({
                icon: "warning",
                text: this.$t("assets.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true,
            }).then(async (answer) => {
                if (answer.isConfirmed) {
                    try {
                        const r = await AssetService.DELETE(this.asset.id);
                        this.$emit("close-refresh");
                    } catch (e) {
                        this.$swal({
                            icon: "error",
                            title: this.$t("error_cannot_delete"),
                            text: this.$t("unknown_error"),
                        });
                    }
                }
            });
        },
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await AssetService.PUT(this.asset);
                } else {
                    const r = await AssetService.POST(this.asset);
                }

                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Get dropdown data
         */
        async fetchGroups() {
            try {
                const r = await AssetGroupService.dropdown({
                    asset_type_id: this.contract.asset_type_id,
                });
                console.log(r.data);
                this.assetGroups = r.data.data;
            } catch (e) {
                console.log("fGroups", e);
            }
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes();
                this.currencies = r.data.data;
            } catch (e) {}
        },
    },
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="asset-modal">
        <template slot="header">
            <template v-if="editing">
                {{ $t("asset.edit_asset") }}
            </template>
            <template v-else>
                {{ $t("asset.create_asset") }}
            </template>
        </template>

        <template slot="default">

            <v-loader :loading="loading" v-if="loading" />
            <FormulateForm
                v-model="asset"
                @submit="saveForm"
                :errors="errors"
                v-else
            >
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <FormulateInput
                            name="asset_group_id"
                            type="multiselect"
                            :label="$t('asset.active_category')"
                            :validation-name="$t('asset.active_category')"
                            :options="assetGroups"
                            :placeholder="$t('asset.select_group')"
                            validation="required"
                        />
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <FormulateInput
                            name="location_country"
                            type="multiselect"
                            :label="$t('asset.location_of_the_asset')"
                            :validation-name="$t('asset.location_of_the_asset')"
                            :options="countries"
                            :placeholder="$t('asset.select_country')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="qty"
                            type="text"
                            :label="$t('asset.quantity')"
                            :validation-name="$t('asset.quantity')"
                            validation=""
                        />
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="qty"
                            type="text"
                            :label="$t('asset.quantity')"
                            :validation-name="$t('asset.quantity')"
                            validation=""
                        />
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="rating_mw"
                            type="groupdecimal"
                            append="MW"
                            :label="$t('asset.rating_per_unit')"
                            validation=""
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-12 col-xs-12">
                        <FormulateInput
                            name="asset_value"
                            type="decimal"
                            number-style="money"
                            :label="$t('asset.value')"
                            validation=""
                        />
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="asset_value_currency"
                            type="select"
                            :options="currencies"
                            :label="$t('asset.currency')"
                            :placeholder="$t('asset.select_currency')"
                            validation=""
                        />
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="subsidy_expiration_date"
                            type="date"
                            :label="$t('asset.subsidy_expires')"
                            validation=""
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-6 col-sm-4 text-left">
                        <base-button
                            v-if="editing"
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-lg-6 col-sm-8 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
