<script>
import InvoicePeriod from "@/components/Momentum/NewContracts/SingleDetailsComponents/InvoicePeriod";
import RegulationPeriod from "@/components/Momentum/NewContracts/SingleDetailsComponents/RegulationPeriod";

export default {
    props: {
        contractDetail: {},
        enableEditing: {
            type: Boolean,
            default: false
        }
    },
    components: { InvoicePeriod, RegulationPeriod },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        cd() {
            return this.contractDetail;
        },
        calcs() {
            return this.contractDetail.src_calculations;
        }
    },
    created() {
        console.log("SingleDetailsModal created");
    }
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        @close="$emit('close')"
        class="budget-modal single-details-modal"
        :loading="loading"
    >
        <template slot="header">
            {{ $t("contract_detail.details") }}
        </template>

        <template slot="default">
            <invoice-period v-if="cd.entry_type == 1" :contract-detail="contractDetail" :enable-editing="enableEditing" />
            <regulation-period v-if="cd.entry_type == 2 || cd.entry_type == 3"  :contract-detail="contractDetail" :enable-editing="enableEditing"  />
            <pre v-if="false">{{ cd.contract_condition }}</pre>
            <pre v-if="false">{{ contractDetail.src_calculations }}</pre>
        </template>
    </modal>
</template>

<style lang="scss">
.single-details-modal {
    .netprice-step {
        border: 1px solid $gray-600;
        border-radius: 6px;
        margin-bottom: 15px;
        padding: 8px;
        font-size: 13px;
    }
}
</style>
