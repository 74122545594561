<script>
export default {
    props: {
        contractType: {},
        contractConditions: {}
    },
    methods: {
        editCondition(condition, edit_all) {
            this.$emit("edit", {
                contract_condition: condition
            });
        }
    }
};
</script>

<template>
    <v-table class="table table-mg table-sm">
        <v-thead>
            <v-tr>
                <v-th class="text-center">{{
                    $t("contract_conditions.hdr_ID")
                }}</v-th>
                <!-- FIXED heading -->
                <template v-if="contractType === 1">
                    <v-th>{{ $t("contract_conditions.hdr_valid_from") }}</v-th>
                    <v-th class="text-right">{{
                        $t("contract_conditions.hdr_base_fee")
                    }}</v-th>
                    <v-th>{{ $t("contract_conditions.hdr_adj_type") }}</v-th>
                    <v-th class="text-right">{{
                        $t("contract_conditions.hdr_min_adj_perc")
                    }}</v-th>
                    <v-th>{{ $t("contract_conditions.hdr_adj_date") }}</v-th>
                    <v-th class="text-center">{{
                        $t("contract_conditions.hdr_is_first_year")
                    }}</v-th>
                    <v-th>{{ $t("contract_conditions.hdr_note") }}</v-th>
                    <v-th></v-th>
                </template>
                <!-- VAR heading -->
                <template v-else>
                    <v-th>{{ $t("contract_conditions.hdr_valid_from") }}</v-th>
                    <v-th class="text-right">{{
                        $t("contract_conditions.hdr_base_perc")
                    }}</v-th>
                    <v-th class="text-right">{{
                        $t("contract_conditions.hdr_min_fee")
                    }}</v-th>
                    <v-th>{{ $t("contract_conditions.hdr_adj_type") }}</v-th>
                    <v-th class="text-right">{{
                        $t("contract_conditions.hdr_min_adj_perc")
                    }}</v-th>
                    <v-th>{{ $t("contract_conditions.hdr_adj_date") }}</v-th>
                    <v-th class="text-center">{{
                        $t("contract_conditions.hdr_is_first_year")
                    }}</v-th>
                    <v-th>{{ $t("contract_conditions.hdr_note") }}</v-th>
                    <v-th></v-th>
                </template>
            </v-tr>
        </v-thead>
        <v-tbody>
            <v-tr v-for="(cd, index) in contractConditions" :key="cd.id">
                <v-td class="text-center">#{{ cd.id }}</v-td>
                <!-- FIXED CONTRACT -->
                <template v-if="contractType === 1">
                    <v-td>{{ $d(new Date(cd.valid_from), "short") }}</v-td>
                    <v-td class="text-right">{{
                        $n(cd.base_fee, {
                            style: "currency",
                            currency: cd.contract.currency,
                            currencyDisplay: "code"
                        })
                    }}</v-td>
                    <v-td
                        >{{ cd.adjustment_type_name }}
                        <div v-if="cd.adjustment_perc > 0">
                            {{ $t("contract_conditions.perc_rate") }}:
                            {{ $n(cd.adjustment_perc, "decimal") }} %
                        </div>
                    </v-td>
                    <v-td class="text-right">
                        <template v-if="cd.min_adjustment_perc > 0">
                            {{ $n(cd.min_adjustment_perc) }}
                        </template>
                        <template v-else>---</template>
                    </v-td>
                    <v-td>
                        <template v-if="cd.adjustment_type != 1">
                            {{
                                $d(new Date(cd.first_adjustment_date), "short")
                            }}</template
                        >
                        <template v-else>---</template></v-td
                    >
                    <v-td class="text-center">
                        <i
                            class="fas fa-fw"
                            :class="{
                                'fa-times text-muted': cd.is_first_year === 0,
                                'fa-check text-success': cd.is_first_year === 1
                            }"
                        ></i>
                    </v-td>
                    <v-td><v-empty :text="cd.comment"/></v-td>
                    <v-td>
                        <i
                            class="far fa-pencil fa-fw m-click fa-lg"
                            @click="editCondition(cd)"
                        ></i>
                    </v-td>
                </template>
                <!-- VARIABLE CONTRACT -->
                <template v-else>
                    <v-td>{{ $d(new Date(cd.valid_from), "short") }}</v-td>
                    <v-td class="text-right"
                        >{{ $n(cd.base_fee_perc, "decimal") }} %</v-td
                    >
                    <v-td class="text-right">{{
                        $n(cd.base_fee_min, {
                            style: "currency",
                            currency: cd.contract.currency,
                            currencyDisplay: "code"
                        })
                    }}
                    <div v-if="cd.force_min_fee == 1" class="text-danger">
                        <i class="fa fa-exclamation-triangle"></i> {{$t("cd.notice_min_fee_is_forced")}}
                    </div>                
                </v-td>

                    <v-td
                        >{{ cd.adjustment_type_name }}
                        <div v-if="cd.adjustment_perc > 0">
                            {{ $t("contract_conditions.perc_rate") }}:
                            {{ $n(cd.adjustment_perc, "decimal") }} %
                        </div>
                        <div v-if="cd.adjustment_type !== 1">
                            {{ $t("contract_conditions.adjust(short)") }}:
                            {{ cd.adjustment_subtype_name }}
                        </div>
                    </v-td>
                    <v-td class="text-right">
                        <template v-if="cd.min_adjustment_perc > 0">
                            {{ $n(cd.min_adjustment_perc) }}
                        </template>
                        <template v-else>---</template>
                    </v-td>
                    <v-td
                        ><template v-if="cd.adjustment_type != 1">{{
                            $d(new Date(cd.first_adjustment_date), "short")
                        }}</template
                        ><template v-else>---</template></v-td
                    >
                    <v-td class="text-center">
                        <i
                            class="fas fa-fw"
                            :class="{
                                'fa-times text-muted': cd.is_first_year === 0,
                                'fa-check text-success': cd.is_first_year === 1
                            }"
                        ></i>
                    </v-td>
                    <v-td>
                        <div class="text-danger mb-2" v-if="cd.enable_half_regulation">
                            <i class="fa fa-exclamation-triangle"></i> {{$t("cd.half_regulation_enabled")}}
                        </div>
                         <v-empty :text="cd.comment"/>
                    </v-td>
                    <v-td>
                        <i
                            class="far fa-pencil fa-fw m-click fa-lg"
                            @click="editCondition(cd)"
                        ></i>
                    </v-td>
                </template>
            </v-tr>
        </v-tbody>
    </v-table>
</template>
