<script>
import ContractDetailService from "@/services/ContractDetailService";

export default {
    props: {
        length: {},
        enableEditing: {
            type: Boolean,
            default: false
        },
        columns: {},
        columnIndex: {},
        contract: {},
        year: {},
        cd: {}
    },
    data() {
        return {
            saving: false,
            newLength: 1
        };
    },
    computed: {
        maxlen() {
            if (this.contract.frequency == "1 MONTH") {
                return 1;
            } else if (this.contract.frequency === "3 MONTHS") {
                return 3;
            } else if (this.contract.frequency === "6 MONTHS") {
                return 6;
            } else if (this.contract.frequency === "1 YEAR") {
                return 12;
            }
        },
        availableLengths() {
            let lens = [];

            for (
                let i = 1;
                i <= (this.length > this.maxlen ? this.maxlen : this.length);
                i++
            ) {
                lens.push({
                    value: i,
                    label: this.$tc("contract_details.COUNT_months", i)
                });
            }

            return lens;
        }
    },
    methods: {
        async createPeriod() {
            let data = {
                contract_id: this.contract.id,
                contract_year: this.year,
                period_length: this.newLength,
                position: this.calculatePosition()
            };

            try {
                const r = await ContractDetailService.POST_createPeriod(data);
                this.$emit("reload");
            } catch (e) {
                console.log("create e", e);
            }
        },
        calculatePosition() {
            if (this.columnIndex === 0) {
                return "first";
            }

            return `after:${this.columns[this.columnIndex - 1]["id"]}`;
        }
    }
};
</script>

<template>
    <div class="contract-timeline-empty-box striped-background">
        <div class="contract-timeline__inner">
            <div class="c-date text-center py-2">
                <template v-if="cd.id === 'free'">
                    {{ $t("contract_detail.unallocated") }}
                </template>
                <template v-else>
                    {{ $t("contract_detail.cannot_be_allocated") }}
                    <div
                        class="mt-4 ml-1 mr-1 p-2 alert-budget"
                        v-if="contract.contract_type == 2"
                    >
                        <i class="far fa-exclamation-triangle fa-2x"></i>
                        <div class="mt-1">
                        {{
                            $t(
                                "contract_detail.notice_includes_not_allocated_time_check_that_budget_numbers_are_adjusted_to_actual_period_length_shown_in_the_table"
                            )
                        }}</div>
                    </div>
                </template>
            </div>
            <div class="c-length text-center pb-2" v-if="cd.id === 'free'">
                {{ $t("contract_detail.months") }}: {{ length }}
            </div>
        </div>
        <!-- Invoice date -->
        <div
            class="contract-timeline__container mt-4 py-1 text-center"
            v-if="cd.id === 'free'"
        >
            {{ $t("contact_detail.create_period") }}
            <div
                class="mt-2"
                style="max-width: 110px; margin-left: auto; margin-right: auto;"
            >
                <FormulateInput
                    :value="length > maxlen ? maxlen : length"
                    type="select"
                    :options="availableLengths"
                    v-model="newLength"
                    size="sm"
                />
            </div>
            <base-button type="primary" @click.native="createPeriod">{{
                $t("contract_detail.create")
            }}</base-button>
        </div>
    </div>
</template>

<style lang="scss">
.contract-timeline-empty-box {
    .contract-timeline__inner {
        border: 1px dashed $warning !important;
    }
}

.alert-budget {
    background-color: white;
    color: $warning;
    border-radius: 6px;
}

.striped-background {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        135deg,
        white,
        white 10px,
        rgb(250, 250, 250) 10px,
        rgb(250, 250, 250) 20px
    );
}
</style>
