<script>
import CustomerService from "@/services/CustomerService";
import nl2br from "@/filters/nl2br";

export default {
    name: "VInvoices",
    props: {
        customer_id: {
            description: "Customer ID",
        },
    },
    data() {
        return {
            loading: false,
            invoices: [],
            error: [],
        };
    },
    async created() {
        console.log("VInvoices", this.customer_id);
        await this.fetchData();
    },
    methods: {
        newlines(text) {
            return nl2br(text);
        },
        async fetchData(params = {}) {
            try {
                this.loading = true;
                const r = await CustomerService.getInvoices(this.customer_id, params);
                this.invoices = r.data.data;
                this.errors = [];
            } catch (e) {
                this.errors = e.response.data.errors;
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("customers.invoice_archive") }}</h1>
                    </div>
                    <div class="mg-title__right">
                        <base-button size="sm" type="secondary"
                        @click="fetchData({refresh: 1})"
                            ><i class="far fa-sync"></i>
                            {{ $t("customers.refresh_invoices") }}</base-button
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- headings -->

        <div class="row card-wrapper">
            <div class="col-12">
                <v-loader :loading="loading" v-if="loading">
                    {{ $t("ec_please_wait_loading") }}
                </v-loader>

                <template v-else-if="invoices.length">
                    <card body-classes="px-0">
                        <div class="table-responsive">
                            <v-table class="table table-mg table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("customers.invoice_type")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("customers.invoice_date")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("customers.invoice_number")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("customers.invoice_net_amount")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("customers.invoice_heading")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("customers.invoice_text1")
                                        }}</v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="invoice in invoices"
                                        :key="invoice.id"
                                    >
                                        <v-td>
                                            <template
                                                v-if="
                                                    parseFloat(
                                                        invoice.net_amount
                                                    ) > 0
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "customers.invoice_invoice"
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    $t(
                                                        "customers.invoice_credit_note"
                                                    )
                                                }}
                                            </template>
                                        </v-td>
                                        <v-td
                                            >{{
                                                $d(
                                                    new Date(invoice.date),
                                                    "short"
                                                )
                                            }}
                                            <div class="small text-muted">
                                                <i
                                                    class="far fa-calendar-exclamation"
                                                ></i>
                                                {{
                                                    $d(
                                                        new Date(
                                                            invoice.due_date
                                                        ),
                                                        "short"
                                                    )
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td>{{ invoice.number }}</v-td>
                                        <v-td class="text-right">
                                            {{
                                                $n(invoice.net_amount, {
                                                    style: "currency",
                                                    currency: invoice.currency,
                                                    currencyDisplay: "code",
                                                })
                                            }}
                                            <div
                                                class="small text-muted"
                                                :title="
                                                    $t(
                                                        'customers.invoice_gross'
                                                    )
                                                "
                                            >
                                                {{
                                                    $n(invoice.gross_amount, {
                                                        style: "currency",
                                                        currency:
                                                            invoice.currency,
                                                        currencyDisplay: "code",
                                                    })
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td>{{ invoice.heading }}</v-td>
                                        <v-td
                                            v-html="newlines(invoice.text1)"
                                        ></v-td>

                                        <v-td>
                                            <a
                                                :href="`/v1/invoices/ec-invoice-download/${invoice.id}`"
                                                target="_blank"
                                            >
                                                <i
                                                    class="far fa-file-pdf fa-lg"
                                                >
                                                </i
                                            ></a>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                    </card>
                </template>
                <v-errors :errors="errors" v-else-if="errors" />

                <card body-classes="px-0" v-else>
                    <div class="pb-mg">
                        <div class="nice-info">
                            {{ $t("customers.no_invoices") }}
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>