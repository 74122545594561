<script>
import SubscriptionService from "@/services/SubscriptionService";
import VSubscriptionForm from "@/components/Forms/VSubscriptionForm";
import VProduct from "@/components/Momentum/Subscriptions/VProduct";
import VAmount from "@/components/Momentum/Subscriptions/VAmount";
import VOptions from "@/components/Momentum/Subscriptions/VOptions";

export default {
    name: "VSubscriptions",
    components: {
        VSubscriptionForm,
        VProduct,
        VAmount,
        VOptions
    },
    props: {
        customer_id: {
            description: "Customer ID"
        }
    },
    data() {
        return {
            loading: false,
            subscriptions: [],
            editing: false,
            editContext: null,
            showNote: false,
            showNoteContext: null
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch
         */
        async fetchData() {
            try {
                this.loading = true;
                const r = await SubscriptionService.GET({
                    customer_id: this.customer_id,
                    includes: "latest_invoice"
                });
                this.subscriptions = r.data.data;
            } catch (e) {}

            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         * Finish
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
        /**
         * Show note
         */
        doShowNote(ctx) {
            this.showNote = true;
            this.showNoteContext = ctx;
        },
        /**
         * HIde note
         */
        doHideNote() {
            this.showNoteContext = null;
            this.showNote = false;
        }
    }
};
</script>

<template>
    <div class="v-subscriptions">
        <div class="px-lr">
            <div class="mg-table-title">
                <div class="mg-table-title__title"></div>
                <div class="mg-table-title__actions" v-if="false">
                    <div class="mg-table-title__actions__left">
                        <span
                            v-if="ACL_RW"
                            class="m-click text-primary"
                            @click="setContext({ empty: true })"
                        >
                            <i class="far fa-plus"></i>
                            {{ $t("subscriptions.add_new") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>

        <v-loader :loading="loading" v-if="loading" />
        <!-- table -->
        <div class="table-responsive" v-else-if="subscriptions.length">
            <v-table class="table table-mg table-sm">
                <v-thead>
                    <v-tr>
                        <v-th>
                            {{ $t("subscriptions.id") }}
                        </v-th>
                        <v-th>{{ $t("subscriptions.type") }}</v-th>
                        <v-th>
                            {{ $t("subscriptions.product") }}
                        </v-th>
                        <v-th class="text-center">
                            {{ $t("subscriptions.notes") }}
                        </v-th>
                        <v-th>{{ $t("subscriptions.start-stop") }}</v-th>
                        <v-th>{{ $t("subscriptions.frequency") }}</v-th>
                        <v-th class="text-right">{{
                            $t("subscriptions.amount")
                        }}</v-th>
                        <v-th class="text-right">{{
                            $t("subscriptions.invoiced")
                        }}</v-th>
                        <v-th v-if="ACL_RW"></v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr
                        v-for="s in subscriptions"
                        :key="s.id"
                        :class="{ 'status-disabled': s.status == 2 }"
                    >
                        <v-td>#{{ s.id }}</v-td>
                        <v-td
                            >{{ s.subscription_type_name }}
                            <div
                                class="small text-info "
                                v-if="s.subscription_type == 2"
                            >
                                <i class="fa fa-cart-plus"></i>
                                {{ s.unit_name }}
                            </div>
                        </v-td>
                        <v-td>
                            <template v-if="s.subscription_type == 3">
                                <span v-if="'subscription_lines' in s"
                                    ><div
                                        v-for="sl in s.subscription_lines"
                                        :key="sl.id"
                                    >
                                        <v-product
                                            :lang="s.customer.lang"
                                            :product="sl.product"
                                        />
                                        <hr class="mt-1 mb-2" /></div
                                ></span>
                                <template v-else>
                                    <span class="text-danger">{{
                                        $t("invoice.sub_no_products_defined")
                                    }}</span>
                                </template>
                            </template>
                            <v-product
                                v-else
                                :lang="s.customer.lang"
                                :product="s.product"
                            />
                        </v-td>
                        <v-td class="text-center">
                            <i
                                class="far fa-comment-alt-lines fa-lg m-click"
                                @click="doShowNote(s.description)"
                                v-if="s.description"
                            ></i>
                            <i
                                class="far fa-comment-alt-slash text-muted fa-lg"
                                v-else
                            ></i>
                        </v-td>
                        <v-td
                            >{{ $d(new Date(s.start_date), "short") }}
                            <template v-if="s.stop_date">
                                - {{ s.stop_date }}
                                <div
                                    class="small text-danger"
                                    v-if="s.status == 2"
                                >
                                    {{ $t("subscription.is_closed") }}
                                </div>
                            </template>
                        </v-td>
                        <v-td>
                            {{ s.frequency }}
                            <div class="small text-muted">
                                {{ s.payment_strategy_name }}
                            </div>
                        </v-td>
                        <v-td class="text-right">
                            <v-amount :s="s" />
                        </v-td>
                        <v-td class="text-right">
                            <template v-if="s.latest_invoice">
                                {{
                                    $d(
                                        new Date(s.latest_invoice.invoice_date),
                                        "short"
                                    )
                                }}
                                <div class="small">
                                    {{
                                        $d(
                                            new Date(s.latest_invoice.created),
                                            "short"
                                        )
                                    }}
                                </div>
                            </template>
                            <template v-else> --- </template>
                        </v-td>
                        <v-td class="text-right" v-if="ACL_RW">
                            <v-options :s="s" @click="setContext" />
                        </v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
        </div>

        <div class="alert alert-warning alert-outline ml-4 mr-4" v-else>
            {{ $t("subscriptions.no_subscriptions_found") }}
        </div>

        <portal to="modals">
            <modal v-if="showNote" size="lg" :show="true" @close="doHideNote">
                <template slot="header">
                    {{ $t("subscriptions.note_header") }}
                </template>
                <template slot="default">
                    {{ showNoteContext }}
                </template>
            </modal>
            <v-subscription-form
                v-if="editing"
                :context="editContext"
                :customer_id="customer_id"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>

<style></style>
