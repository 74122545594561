<script>
import BudgetService from "@/services/BudgetService";
import { pick } from "lodash";

export default {
    name: "VAssetForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        contract: {
            description: "Contract object"
        },
        year: {},
        hintConnected: {
            type: Boolean
        }
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            budget: {
                id: null,
                year: null,
                contract_id: null,
                planned_revenue: null,
                actual_revenue: null,
                planned_production_kwh: null,
                actual_production_kwh: null
            }
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.budget = pick(this.context, [
                "id",
                "year",
                "contract_id",
                "planned_revenue",
                "actual_revenue",
                "planned_production_kwh",
                "actual_production_kwh"
            ]);
        } else {
            // Assign contract_Id
            this.budget.contract_id = this.contract.id;
            this.budget.year = this.year;
        }
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await BudgetService.PUT(this.budget);
                } else {
                    const r = await BudgetService.POST(this.budget);
                }

                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Handle delete budget
         * @param budget
         */
        async handleDelete() {
            this.$swal({
                icon: "warning",
                text: this.$t("budget.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    let result = await this.delete(this.budget.id);
                    if (result) {
                        this.$notify({
                            message: this.$t("task.notify_budget__deleted"),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });

                        this.$emit("close-refresh");
                    }
                }
            });
        },
        /**
         * Delete budget
         * @param budget_id
         */
        async delete(budget_id) {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await BudgetService.DELETE(budget_id);
                return true;
            } catch (e) {
                console.log(e);
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: map(e.response.data.errors, n => {
                            return n.join(".\n");
                        })
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error")
                    });
                }
            }

            return false;
        }
    }
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <template v-if="editing">
                {{ $t("budget.edit_budget") }}
            </template>
            <template v-else>
                {{ $t("budget.create_budget") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm v-model="budget" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="year"
                            type="text"
                            :placeholder="$t('budget.year_placeholder')"
                            :label="$t('budget.year')"
                            validation="required|number|between:2000,2999"
                            disabled
                        />
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="planned_production_kwh"
                            type="grouptext"
                            append="kWh"
                            :label="$t('budget.production')"
                        />
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <FormulateInput
                            name="planned_revenue"
                            type="currency"
                            :currency="contract.currency"
                            :label="$t('budget.turnover')"
                        />
                    </div>
                </div>

                <div class="line-separator"></div>

                <div class="row mt-4">
                    <div class="col-lg-4 col-sm-12 col-xs-12">
                        <span class="actual-numbers">{{
                            $t("budget.actual_numbers")
                        }}</span>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="actual_production_kwh"
                            type="grouptext"
                            append="kWh"
                            :label="$t('budget.production')"
                        />
                    </div>

                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="actual_revenue"
                            type="currency"
                            :currency="contract.currency"
                            :label="$t('budget.turnover')"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-5">
                        <base-button type="danger" v-if="editing && !hintConnected" @click="handleDelete"
                            ><i class="far fa-trash"></i>
                        </base-button>
                    </div>
                    <div class="col-7">
                        <div class="text-right ">
                            <base-button
                                type="secondary"
                                :outline="true"
                                @click="$emit('close')"
                                >{{ $t("cancel") }}</base-button
                            >
                            <base-button
                                type="primary"
                                :loading="loading"
                                native-type="submit"
                                >{{ $t("save") }}</base-button
                            >
                        </div>
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
