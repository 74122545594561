<script>
import { pick } from "lodash";
import ChargeService from "@/services/ChargeService";
import ChargeTemplateService from "@/services/ChargeTemplateService";
import CurrencyService from "@/services/CurrencyService";
import CustomerService from "@/services/CustomerService";
import EcService from "@/services/EcService";
import MGConsts from "@/mixins/MGConsts";

import SettingService from "@/services/SettingService";

export default {
    name: "VChargeForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        customer_id: {
            description: "Customer ID",
        },
    },
    computed: {
        hasInvoicingOptions: function () {
            let check = [
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
            ];

            let show = false;

            check.forEach((value) => {
                if (this.charge[value]) {
                    show = true;
                }
            });

            console.log("Hasinvoice", show);

            return show;
        },
    },
    data() {
        return {
            vars: null, //unused
            showInvoicingOptions: false,
            layouts: null,
            termsOfPayment: null,
            editing: false,
            loading: false,
            loadingTpl: false,
            errors: null,
            currencies: [],
            ec_products: [],
            charge_tpls: [],
            charge: {
                id: null,
                economic_product: null,
                customer_id: null,
                description: null,
                invoice_text: null,
                amount: null,
                currency: null,
                status: null,
                invoice_date: null,
                invoice_heading: null,
                invoice_text1: null,
                invoice_text2: null,
                invoice_term_of_payment: null,
                invoice_layout: null,
            },
        };
    },
    async created() {
        this.loading = true;
        await this.fetchCurrencies();
        await this.fetchEcProducts();
        await this.fetchEconomicParams();
        await this.fetchChargeTemplates();

        if (!this.context.empty) {
            this.editing = true;
            this.charge = pick(this.context, [
                "id",
                "customer_id",
                "economic_product",
                "invoice_text",
                "description",
                "amount",
                "currency",
                "status",
                "invoice_date",
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
            ]);
        } else {
            this.charge.customer_id = this.customer_id;
        }

        await this.fixCurrency();
        this.loading = false;
    },
    methods: {
        async fixCurrency() {
            try {
                const { data } = await CustomerService.GETS(
                    this.charge.customer_id
                );
                this.$set(this.charge, "currency", data.data.default_currency);
            } catch (e) {}
        },
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await ChargeService.PUT(this.charge);
                } else {
                    const r = await ChargeService.POST(this.charge);
                }

                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         *
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("charge.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true,
                }).then(async (confirmed) => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await ChargeService.DELETE(
                                this.charge.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: e.response.data.errors.join(".\n"),
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error"),
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Get templates
         */
        async fetchChargeTemplates() {
            try {
                const r = await ChargeTemplateService.dropdown();
                this.charge_tpls = r.data.data;

                if (this.charge_tpls.length) {
                    this.charge_tpls.unshift({ "": "" });
                }
            } catch (e) {}
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes({
                    fview: "active",
                });
                this.currencies = r.data.data;
            } catch (e) {}
        },
        /**
         * Get EC products
         */
        async fetchEcProducts() {
            try {
                const r = await EcService.ecProducts();
                this.ec_products = r.data.data;
            } catch (e) {}
        },
        /**
         * Fetch economic params
         */
        async fetchEconomicParams() {
            try {
                const r = await EcService.ecLayoutsAndTerms();
                this.layouts = r.data.data.layouts;
                this.layouts.unshift({
                    label: this.$t("use_default"),
                    value: "",
                });

                this.termsOfPayment = r.data.data.terms_of_payment;
                this.termsOfPayment.unshift({
                    label: this.$t("use_default"),
                    value: "",
                });
            } catch (e) {}
        },
        /**
         * Fetch settings data + vars
         * UNUSED - but left here, because maybe
         * one day?
         */
        async fetchSettings() {
            try {
                const r2 = await SettingService.getNamespaceVariables(
                    "invoice_templates"
                );
                this.vars = r2.data.data;
            } catch (e) {}
        },
        async loadTemplate(data) {
            let process = [
                "economic_product",
                "invoice_text",
                "description",
                "amount",
                "currency",
                "invoice_heading",
                "invoice_text1",
                "invoice_text2",
                "invoice_term_of_payment",
                "invoice_layout",
            ];
            console.log("LOAD", data);
            if (typeof data !== "undefined") {
                try {
                    this.loadingTpl = true;
                    const r = await ChargeTemplateService.GETS(data);
                    let d = r.data.data;

                    //console.log("Loaded", r.data.data);
                    process.forEach((key, index) => {
                        if (d[key] !== null && d[key] !== "") {
                            //console.log("replace", d[key],  key );
                            this.charge[key] = d[key];
                            delete process[index];
                        }
                    });

                    // Reset remaining
                    process.forEach((key, index) => {
                        //console.log("CIUSR", key, index);
                        this.charge[key] = "";
                    });
                } catch (e) {
                    //console.log("ZJebak", e);
                }

                this.loadingTpl = false;
            }
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="subscription-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("charge.edit_charge") }}
            </template>
            <template v-else>
                {{ $t("charge.create_charge") }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <template v-if="charge_tpls.length">
                <FormulateInput
                    :label="$t('charge_tpls.select_template')"
                    type="select"
                    :options="charge_tpls"
                    @input="loadTemplate"
                />
                <v-loader :loading="loadingTpl" v-if="loadingTpl" />
                <hr />
            </template>

            <FormulateForm v-model="charge" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-lg-6 col-sm-8 col-xs-12">
                        <FormulateInput
                            name="amount"
                            type="currency"
                            :currency="charge.currency"
                            number-style="money"
                            :label="$t('charge.amount')"
                            :validation-name="$t('charge.amount')"
                            :placeholder="$t('charge.provide_amount')"
                            validation="required"
                        />
                    </div>
                    <div class="col-lg-6 col-sm-4 col-xs-12">
                        <FormulateInput
                            name="currency"
                            type="select"
                            :options="currencies"
                            :label="$t('charge.currency')"
                            :validation-name="$t('charge.currency')"
                            :placeholder="$t('charge.select_currency')"
                            validation="required"
                            :disabled="true"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="invoice_date"
                            type="date"
                            :label="$t('charge.charge_date')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="economic_product"
                            type="select"
                            :options="ec_products"
                            :label="$t('charge.economic_product')"
                            :validation-name="$t('charge.economic_product')"
                            :placeholder="$t('charge.select_economic_product')"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="invoice_text"
                            type="textarea"
                            :label="$t('charge.invoice_text')"
                            :help="$t('charge.help_invoice_text')"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="description"
                            type="textarea"
                            :label="$t('charge.optional_note')"
                        />
                    </div>
                </div>

                <!-- EXTRA INVOICING OPTIONS START -->
                <!-- Additional invoicing options -->

                <h3 class="sub-heading">
                    {{ $t("charge.additional_invoicing_options") }}
                </h3>

                <div v-show="hasInvoicingOptions || showInvoicingOptions">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_heading"
                                type="textarea"
                                :label="$t('charge.custom_invoice_heading')"
                                :help="$t('charge.custom_invoice_heading_help')"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_text1"
                                type="textarea"
                                :label="$t('charge.custom_invoice_text1')"
                                :help="$t('charge.custom_invoice_text1_help')"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_text2"
                                type="textarea"
                                :label="$t('charge.custom_invoice_text2')"
                                :help="$t('charge.custom_text2_help')"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_layout"
                                type="select"
                                :options="layouts"
                                :label="$t('charge.custom_invoice_layout')"
                            />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <FormulateInput
                                name="invoice_term_of_payment"
                                type="select"
                                :options="termsOfPayment"
                                :label="$t('charge.custom_term_of_payment')"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    v-show="!hasInvoicingOptions && !showInvoicingOptions"
                >
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        {{
                            $t("charge.additional_invoicing_options_form_text")
                        }}
                    </div>
                    <div
                        class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-right"
                    >
                        <base-button
                            size="sm"
                            @click="
                                showInvoicingOptions = !showInvoicingOptions
                            "
                        >
                            {{
                                $t("charge.show_additional_invoicing_options")
                            }}</base-button
                        >
                    </div>
                </div>
                <!-- EXTRA INVOICING OPTIONS END -->

                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button
                            v-if="editing"
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>
