<script>
/**
 * In customer = list of all subscriptions
 */
import VSubscriptions from "@/components/Momentum/Subscriptions/VSubscriptions";

export default {
    name: "VSubscriptionList",
    components: {
        VSubscriptions
    },
    props: {
        customer_id: {
            description: "Customer ID"
        }
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
    },
    methods: {
        addNew() {
            this.$refs.submgmt.setContext({ empty: true });
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left">
                        <h1>{{ $t("customer.subscriptions") }}</h1>
                    </div>
                    <div class="mg-title__right">
                        <base-button
                            v-if="ACL_RW"
                            size="sm"
                            type="secondary"
                            @click="addNew"
                            ><i class="far fa-plus"></i>
                            {{ $t("subscriptions.add_subscription") }}</base-button
                        >
                    </div>
                </div>

                <card class="additional-turnover" body-classes="px-0">
                    <v-subscriptions :customer_id="customer_id" ref="submgmt" />
                </card>
            </div>
        </div>
    </div>
</template>
