<script>
import dayjs from "dayjs";

export default {
    name: "VDeadlineColor",
    props: {
        context: {
            description: "Due date",
        },
        active: {
            default: false
        },
        activeClass: {
            type: String,
            default: "active"
        },
        dateFormat: {
            type: String,
            default: "short"
        }
    },
    computed: {
        isActiveClass: function () {
            if (this.active) {
                return activeClass;
            } 

            return "";
        },
        colorClass: function () {
            let dt = dayjs(this.context, "YYYY-MM-DD");
            let now = dayjs();

            if (dt.isSame(now, "date")) {
                return "text-warning";
            } else if (dt.isBefore(now, "date")) {
                return "text-danger";          
            } else if (dt.isAfter(dayjs().add(4, "day"), "date")) {
                return "";
            }

            return "";
        },
    },
};
</script>

<template>
    <span :class="[colorClass,isActiveClass]">{{ $d(new Date(context), dateFormat) }}</span>
</template>


<style lang="scss">
</style>