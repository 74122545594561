import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'asset-archives',
         
    /** 
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {params: params}); 
    },    
}
