<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import VTaskForm from "@/components/Forms/VTaskForm";
import VFilters from "@/components/Search/VFilters";
import VQuickTask from "@/components/Projects/VQuickTask";
import VTaskStatus from "@/components/Task/Widgets/VTaskStatus";

import TaskService from "@/services/TaskService";
import UserService from "@/services/UserService";
import ActivityService from "@/services/ActivityService";
import { EventBus } from "@/libraries/EventBus";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VInternalTime",
    props: {
        customer_id: {
            description: "Customer ID",
        },
    },
    mixins: [DateRangeMixin],
    components: {
        VFilters,
        VTaskForm,
        VQuickTask,
        DateRangePicker,
        VTaskStatus,
    },
    data() {
        return {
            loading: false, // loading tasks + meta
            initLoading: false, // one-shot loading on init
            showTaskModal: false,
            internalCustomer: false,
            tasks: [],
            meta: null,
            context: null,
            isEditingTask: false,
            users: [],
            activities: [],
            taskStatus: [
                { label: this.$t("task.status_open"), value: "1" },
                { label: this.$t("task.status_closed"), value: "5" },
                { label: this.$t("task.status_invoiced"), value: "9" },
            ],
        };
    },
    computed: mapGetters("auth", ["getMyId", "getAuthUser"]),
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);
        this.initLoading = true;
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchData();
        EventBus.$on("task-created", this.fetchData);
        this.initLoading = false;
    },
    methods: {
        fixParams() {
            let params = this.$buildParams();
            params["customer_id"] = this.customer_id;
            params["internal_time"] = 1;
            params["internal_stats"] = 1;
            return params;
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.fixParams();
                const r = await TaskService.GET(params);
                this.tasks = r.data.data;
                this.meta = r.data.meta;
                console.log(this.meta);
            } catch (e) {
                console.log("fetchDta exception", e);
            }

            this.loading = false;
        },
        /**
         * Insert a newly created quick-task at the top of the list
         */
        async insertTask(task) {
            task.is_new = true;
            this.tasks.splice(0, 0, task);
            // Refresh meta
            try {
                let params = this.fixParams();
                const r = await TaskService.GET(params);
                this.meta = r.data.meta;
            } catch (e) {}
        },
        /**
         * Reset all search parameters to startup defaults
         */
        resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };
            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "task_date",
            };
        },
        /**
         * Open up modal for editing & enable context
         */
        setTaskEditContext(task) {
            this.taskContext = task;
            this.showTaskModal = true;
        },
        /**
         * Delete the task
         * TBD TODO
         */
        async deleteTask(task) {
            // RUN DELETE ACTION
            // Find the task
            let idx = this.tasks.findIndex((el) => {
                return el.id == task.id ? true : false;
            });

            this.tasks.splice(idx, 1);
        },
        /**
         * Close task modal
         */
        closeTaskModal() {
            this.showTaskModal = false;
        },
        /**
         * Close task modal and update the task object. Remove
         * if project_id changed.
         */
        closeSaveTaskModal(task) {
            // Find the task
            let idx = this.tasks.findIndex((el) => {
                return el.id == task.id ? true : false;
            });

            // Either replace the task
            // or remove from the table (project_id change)
            if (task.project_id == this.project_id) {
                this.$set(this.tasks, idx, task);
            } else {
                this.tasks.splice(idx, 1);
            }

            this.showTaskModal = false;
        },
        async fetchSearchData() {
            await this.fetchUsers();
            await this.fetchActivities();
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {}
        },
        /**
         * TBD: should be done user dependent
         */
        async fetchActivities(loading = true) {
            try {
                const r = await ActivityService.dropdown();
                this.activities = r.data.data;
            } catch (e) {}
        },
        /**
         * Show/hide delete option in the table
         */
        canDelete(task) {
            if (this.ACL_RW == false || task.user_id != this.getMyId) {
                return false;
            }

            if (task.is_locked || task.is_invoiced) {
                return false;
            }

            return true;
        },
    },
};
</script>

<template>
    <div class="v-customers-itime">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12 project-navigation__submenu" v-if="ACL_RW">
                    <div
                        class="row d-flex justify-content-between project-navigation__submenu--container"
                    >
                        <router-link
                            class="col project-navigation__submenu--box"
                            :to="{ name: 'CustomersTabInternalTime' }"
                            >{{ $t("customer.internal_time") }}</router-link
                        >

                        <router-link
                            class="col project-navigation__submenu--box"
                            :to="{ name: 'CustomersTabInternalTimeStats' }"
                            >{{ $t("project.statistics") }}</router-link
                        >
                    </div>
                </div>

                <!-- quick reg -->
                <div class="col-12">
                    <h3 class="mb-3">{{ $t("project.quick_registration") }}</h3>
                    <v-loader :loading="initLoading" v-if="initLoading" />
                    <v-quick-task
                        @save="insertTask"
                        :internal-customer="true"
                        :customer-id="customer_id"
                        :allow-create="true"
                    />
                </div>
                <div class="col-12">
                    <h3 class="mb-3">{{ $t("project.time_usage") }}</h3>
                </div>
                <div class="col-12">
                    <card body-classes="px-0">
                        <div class="mb-3 mt-1 px-3">
                            <v-filters
                                :search="false"
                                :enable-filters="true"
                                @filters-open="fetchSearchData"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <template slot="right">
                                    <v-loader
                                        :loading="initLoading"
                                        v-if="initLoading"
                                    />
                                    <div
                                        class="project-stats"
                                        v-else-if="meta.stats"
                                    >
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "internal_time.total_time"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    meta.stats.total_time
                                                        | toTime
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of right slot -> stats -->
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-4">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="center"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                        /></template>

                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <!-- daterange -->
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.user_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_user'
                                            )
                                        "
                                        type="select"
                                        :options="users"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.activity_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_activity'
                                            )
                                        "
                                        type="select"
                                        :options="activities"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.status"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_status'
                                            )
                                        "
                                        type="select"
                                        :options="taskStatus"
                                    />
                                </div>
                            </v-filters>
                        </div>
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="tasks.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="task_date"
                                                @sort="$handleSorting($event)"
                                                >{{ $t("task.date") }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-center">
                                            <v-sorter
                                                :paginator="apiPaginator"
                                                name="status"
                                                @sort="$handleSorting($event)"
                                                ><i
                                                    class="far fa-lock"
                                                ></i></v-sorter
                                        ></v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Activities.name"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.activity")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="user_id"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.responsible")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-center"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="details"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.description")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-right"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="time_reg"
                                                @sort="$handleSorting($event)"
                                                >{{ $t("task.time") }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th></v-th>
                                        <v-th></v-th>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="t in tasks"
                                            :key="t.id"
                                            :class="{
                                                'effect-new-task': t.is_new,
                                            }"
                                        >
                                            <v-td>{{
                                                $d(
                                                    new Date(t.task_date),
                                                    "short"
                                                )
                                            }}</v-td>
                                            <v-td class="text-center">
                                                <v-task-status
                                                    :status="t.status"
                                                />
                                            </v-td>
                                            <v-td> {{ t.activity.name }}</v-td>
                                            <v-td>{{ t.user.name }}</v-td>
                                            <v-td class="text-center">
                                                <i
                                                    class="fa-sticky-note m-click"
                                                    @click="
                                                        setTaskEditContext(t)
                                                    "
                                                    :class="{
                                                        'fas text-primary':
                                                            t.details,
                                                        'far text-muted':
                                                            !t.details,
                                                    }"
                                                ></i>
                                            </v-td>
                                            <v-td class="text-right">{{
                                                t.time_reg | toTime
                                            }}</v-td>
                                            <v-td>
                                                <i
                                                    class="far fa-fw"
                                                    :class="
                                                        !t.internal_details
                                                            ? 'fa-comment-alt-slash text-muted'
                                                            : 'fa-comment-alt-lines'
                                                    "
                                                ></i>
                                                <i
                                                    class="far fa-fw fa-files"
                                                    :class="
                                                        !t.internal_details
                                                            ? 'text-muted'
                                                            : ''
                                                    "
                                                ></i>
                                            </v-td>
                                            <v-td>
                                                <base-dropdown
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                >
                                                    <template
                                                        slot="title-container"
                                                        ><i
                                                            class="far fa-ellipsis-h fa-lg"
                                                        ></i
                                                    ></template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="
                                                            setTaskEditContext(
                                                                t
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "task.action_open_modal"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        v-if="canDelete(t)"
                                                        @click="deleteTask(t)"
                                                        class="dropdown-item text-danger"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-trash"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "task.action_delete"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="shortcut-modal">
            <v-task-form
                v-if="showTaskModal"
                :context="taskContext"
                @close="closeTaskModal"
                @save="closeSaveTaskModal"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.table-responsive {
    //display: block;
    //width: 100%;
    overflow-x: auto !important;

    .table {
        .task-title {
            min-width: 300px;
        }

        .user-name {
            min-width: 200px;
        }
    }
}
</style>
