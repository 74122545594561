<script>
import MultiStat from "@/components/Momentum/MultiStat";

export default {
    props: {
        contract: {},
        widgetLoading: {},
        dates: {}
    },
    components: { MultiStat },
};
</script>

<template>
    <div class="row">
        <div
            class="contract__stat col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12"
        >
            <multi-stat :header="$t('contract.basic_fee')">
                <template v-if="parseInt(contract.contract_type) == 1">
                    {{
                        $n(contract.base_fee, {
                            style: "currency",
                            currency: contract.currency,
                            currencyDisplay: "code",
                        })
                    }}
                </template>
                <template v-if="parseInt(contract.contract_type) == 2">
                    {{ $n(contract.base_fee_perc) }}%
                </template>

                <template slot="footer"
                    >{{ $t("contract.frequency") }}:
                    {{ contract.frequency_obj.number }}
                    {{ contract.frequency_obj.word }}</template
                >
            </multi-stat>
        </div>

        <div
            class="contract__stat col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12"
        >
            <multi-stat
                :header="$t('contract.next_invoicing_date')"
                @click-footer="$emit('show-plan')"
            >
                <v-loader :loading="widgetLoading" v-if="widgetLoading" />
                <template v-else>
                    <template v-if="dates.next_invoice_date">
                        {{
                            $d(new Date(dates.next_invoice_date), "short")
                        }}</template
                    >
                    <template v-else> --- </template>
                </template>

                <template slot="footer">
                    <span class="m-click text-primary">
                        {{ $t("contract.show_invoicing_plan") }}</span
                    >
                </template>
            </multi-stat>
        </div>

        <div
            class="contract__stat col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12"
        >
            <multi-stat :header="$t('contract.next_adjustment')">
                <v-loader :loading="widgetLoading" v-if="widgetLoading" />
                <template v-else>
                    <template v-if="dates.next_adjustment_date">
                        {{ $d(new Date(dates.next_adjustment_date), "short") }}
                    </template>
                    <template v-else> --- </template>
                </template>

                <template slot="footer">
                    {{ contract.adjustment_type_name }}
                </template>
            </multi-stat>
        </div>

        <div
            class="contract__stat col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12"
        >
            <multi-stat :header="$t('contract.next_cancellation')">
                <v-loader :loading="widgetLoading" v-if="widgetLoading" />
                <template v-else>
                    <template v-if="dates.next_cancellation_notice_date">
                        {{
                            $d(
                                new Date(dates.next_cancellation_notice_date),
                                "short"
                            )
                        }}</template
                    >
                    <template v-else> --- </template>
                </template>
                <template slot="footer"
                    >{{ $t("contract.notice") }}:
                    {{
                        $tc("contract.n_notice_months", contract.notice)
                    }}</template
                >
            </multi-stat>
        </div>
    </div>
</template>


<style>
</style>
