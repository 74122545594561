<script>
export default {
    props: {
        contractDetail: {},
        enableEditing: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cd() {
            return this.contractDetail;
        },
        calcs() {
            return this.contractDetail.src_calculations;
        }
    }
};
</script>

<template>
    <div>
        <div class="row">
            <!-- Left DL -->
            <div class="col-6">
                <h2>{{ $t("cd.hdr_this_period") }}</h2>
                <dl>
                    <dt>{{ $t("cd.period") }}</dt>
                    <dd>
                        {{ $d(new Date(cd.date_from), "short") }} -
                        {{ $d(new Date(cd.date_to), "short") }}
                    </dd>

                    <dt>{{ $t("cd.period_length") }}</dt>
                    <dd>{{ $t("cd.months") }}: {{ cd.period_length }}</dd>

                    <dt>{{ $t("cd.status") }}</dt>
                    <dd>
                        {{ cd.status_name }}
                    </dd>

                    <dt>{{ $t("cd.is_void") }}</dt>
                    <dd>{{ cd.is_void_name }}</dd>

                    <dt>{{ $t("cd.contract_type") }}</dt>
                    <dd>
                        <template v-if="cd.contract.contract_type == 1">
                            {{ $t("cd.fixed_fee") }}
                        </template>
                        <template v-else>
                            {{ $t("cd.variable") }}
                        </template>
                    </dd>
                </dl>
            </div>
            <div class="col-6">
                <h2>{{ $t("cd.hdr_contract_conditions") }}</h2>
                <dl>
                    <dt>{{ $t("cd.condition_id") }}</dt>
                    <dd>#{{ cd.contract_condition.id }}</dd>

                    <!-- FIXED CONTRACT -->
                    <template v-if="cd.contract.contract_type === 1">
                        <dt>{{ $t("cd.valid_from") }}</dt>
                        <dd>
                            {{
                                $d(
                                    new Date(cd.contract_condition.valid_from),
                                    "short"
                                )
                            }}
                        </dd>

                        <dt>{{ $t("cd.base_fee") }}</dt>
                        <dd>
                            {{
                                $n(cd.base_fee, {
                                    style: "currency",
                                    currency: cd.contract.currency,
                                    currencyDisplay: "code"
                                })
                            }}
                        </dd>

                        <dt>{{ $t("cd.adjustment_type") }}</dt>
                        <dd>
                            {{ cd.contract_condition.adjustment_type_name }}
                            <div
                                v-if="cd.contract_condition.adjustment_perc > 0"
                            >
                                {{ $t("contract_conditions.perc_rate") }}:
                                {{
                                    $n(
                                        cd.contract_condition.adjustment_perc,
                                        "decimal"
                                    )
                                }}
                                %
                            </div>
                        </dd>

                        <dt>{{ $t("cd.min_adjustment") }}</dt>
                        <dd>
                            <template
                                v-if="
                                    cd.contract_condition.min_adjustment_perc >
                                        0
                                "
                            >
                                {{
                                    $n(
                                        cd.contract_condition
                                            .min_adjustment_perc
                                    )
                                }}
                            </template>
                            <template v-else>---</template>
                        </dd>

                        <dt>{{ $t("cd.first_adjustment_on") }}</dt>
                        <dd>
                            {{
                                $d(
                                    new Date(
                                        cd.contract_condition.first_adjustment_date
                                    ),
                                    "short"
                                )
                            }}
                        </dd>

                        <dt>{{ $t("cd.first_year") }}</dt>
                        <dd>
                            <i
                                class="fas fa-fw"
                                :class="{
                                    'fa-times text-muted':
                                        cd.contract_condition.is_first_year ===
                                        0,
                                    'fa-check text-success':
                                        cd.contract_condition.is_first_year ===
                                        1
                                }"
                            ></i>
                        </dd>
                    </template>
                    <!-- VARIABLE CONTRACT -->
                    <template v-else>
                        <dt>{{ $t("cd.valid_from") }}</dt>
                        <dd>
                            {{
                                $d(
                                    new Date(cd.contract_condition.valid_from),
                                    "short"
                                )
                            }}
                        </dd>

                        <dt>{{ $t("cd.base_perc") }}</dt>
                        <dd>
                            {{
                                $n(
                                    cd.contract_condition.base_fee_perc,
                                    "decimal"
                                )
                            }}
                            %
                        </dd>

                        <dt>{{ $t("cd.min_fee") }}</dt>

                        <dd>
                            {{
                                $n(cd.contract_condition.base_fee_min, {
                                    style: "currency",
                                    currency: cd.contract.currency,
                                    currencyDisplay: "code"
                                })
                            }}
                        </dd>

                        <dt>{{ $t("cd.adjustment_type") }}</dt>
                        <dd>
                            {{ cd.adjustment_type_name }}
                            <div
                                v-if="cd.contract_condition.adjustment_perc > 0"
                            >
                                {{ $t("contract_conditions.perc_rate") }}:
                                {{
                                    $n(
                                        cd.contract_condition.adjustment_perc,
                                        "decimal"
                                    )
                                }}
                                %
                            </div>
                            <div v-if="cd.adjustment_type !== 1">
                                {{ $t("contract_conditions.adjust(short)") }}:
                                {{
                                    cd.contract_condition
                                        .adjustment_subtype_name
                                }}
                            </div>
                        </dd>

                        <dt>{{ $t("cd.min_perc_adjustment") }}</dt>
                        <dd>
                            <template
                                v-if="
                                    cd.contract_condition.min_adjustment_perc >
                                        0
                                "
                            >
                                {{
                                    $n(
                                        cd.contract_condition
                                            .min_adjustment_perc
                                    )
                                }}
                            </template>
                            <template v-else>---</template>
                        </dd>

                        <dt>{{ $t("cd.first_adjustment_on") }}</dt>
                        <dd>
                            {{
                                $d(
                                    new Date(
                                        cd.contract_condition.first_adjustment_date
                                    ),
                                    "short"
                                )
                            }}
                        </dd>

                        <dt>{{ $t("cd.first_year") }}</dt>
                        <dd>
                            <i
                                class="fas fa-fw"
                                :class="{
                                    'fa-times text-muted':
                                        cd.contract_condition.is_first_year ===
                                        0,
                                    'fa-check text-success':
                                        cd.contract_condition.is_first_year ===
                                        1
                                }"
                            ></i>
                        </dd>
                    </template>
                </dl>
            </div>
        </div>

        <h2 class="mt-4">{{ $t("cd.source calculations") }}</h2>
        <dl>
            <dt>{{ $t("cd.type") }}</dt>
            <dd>{{ cd.contract_condition.adjustment_type_name }}</dd>

            <!-- Subtype adjustment -->
            <template v-if="cd.contract.contract_type == 2">
                <dt>{{ $t("cd.subtype_adjust_proc_and_or_min_fee") }}</dt>
                <dd>{{ cd.contract_condition.adjustment_subtype_name }}</dd>
            </template>
        </dl>

        <h4>{{ $t("cd.numbers") }}</h4>

        <ul>
            <li class="text-bold text-primary">
                {{ $t("cd.invoice_fee") }}:
                {{
                    $n(calcs.invoice_fee, {
                        style: "currency",
                        currency: cd.contract.currency,
                        currencyDisplay: "code"
                    })
                }}
            </li>
            <!-- Fixed contract -->
            <template v-if="cd.contract.contract_type == 1">
                <li>
                    {{ $t("cd.fee_not_adjusted") }}:
                    {{
                        $n(calcs.base_fee_year, {
                            style: "currency",
                            currency: cd.contract.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </li>
                <li>
                    {{ $t("cd.fee_adjusted") }}:
                    {{
                        $n(calcs.base_fee_year_adjusted, {
                            style: "currency",
                            currency: cd.contract.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </li>
            </template>
            <!-- Variable contraxct -->
            <template v-if="cd.contract.contract_type == 2">
                <li v-if="'force_min_fee' in calcs && calcs.force_min_fee == 1" class="text-warning text-bold">
                    <i class="fa fa-exclamation-triangle "></i>
                    {{
                        $t("cd.uses_forced_min_fee_real_is_N", {
                            N: $n(calcs.force_min_fee_original_fee_period, {
                                style: "currency",
                                currency: cd.contract.currency,
                                currencyDisplay: "code"
                            })
                        })
                    }}
                </li>
                <li>
                    {{ $t("cd.perc_not_adjusted") }}:
                    {{ $n(calcs.base_perc_year) }} %
                </li>
                <li>
                    {{ $t("cd.perc_adjusted") }}:
                    {{ $n(calcs.base_perc_year_adjusted) }} %
                </li>
                <li>
                    {{ $t("cd.min_fee_unadjusted") }}:
                    {{
                        $n(calcs.base_min_fee_year, {
                            style: "currency",
                            currency: cd.contract.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </li>
                <li>
                    {{ $t("cd.min_fee_adjusted") }}:
                    {{
                        $n(calcs.base_min_fee_year_adjusted, {
                            style: "currency",
                            currency: cd.contract.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </li>
                <li>
                    {{ $t("cd.used_min_fee_in_calcs") }}:
                    <i
                        class="far"
                        :class="{
                            'fa-check text-success': calcs.using_min_fee,
                            'fa-times': !calcs.using_min_fee
                        }"
                    ></i>
                </li>
            </template>
        </ul>

        <!-- No adjustment -->
        <template v-if="cd.contract_condition.adjustment_type == 1">
            <div class="alert alert-warning">
                {{ $t("cd.no_adjustment") }}
            </div>
        </template>
        <!-- Fixed -->
        <template v-else-if="cd.contract_condition.adjustment_type == 2">
            <h4>{{ $t("cd.adjustment_details") }}</h4>
            <ul>
                <li>
                    <template v-if="cd.src_calculations.adjuster.length > 0">{{
                        $t("cd.adjustment_since")
                    }}</template
                    ><template v-else>{{
                        $t("cd.first_adjustment_on")
                    }}</template
                    >:
                    {{
                        $d(
                            new Date(
                                cd.contract_condition.first_adjustment_date
                            ),
                            "short"
                        )
                    }}
                </li>
                <li v-if="cd.src_calculations.adjuster.length > 0">
                    {{ $t("cd.number_of_years") }}:
                    {{ calcs.adjuster.years }}
                </li>
                <li v-if="cd.src_calculations.adjuster.length > 0">
                    {{ $t("cd.final_adjustment") }}:
                    {{ $n(calcs.adjuster.percentage, "decimal") }} %
                </li>
            </ul>
        </template>
        <!-- Netprice -->
        <template v-else-if="cd.contract_condition.adjustment_type == 3">
            <h4>{{ $t("cd.adjustment_details") }}</h4>
            <ul>
                <li>
                    <template v-if="cd.src_calculations.adjuster.length > 0">{{
                        $t("cd.adjustment_since")
                    }}</template
                    ><template v-else>{{
                        $t("cd.first_adjustment_on")
                    }}</template
                    >:
                    {{
                        $d(
                            new Date(
                                cd.contract_condition.first_adjustment_date
                            ),
                            "short"
                        )
                    }}
                </li>
                <li v-if="cd.src_calculations.adjuster.length > 0">
                    {{ $t("cd.final_percent") }}:
                    {{ $n(cd.src_calculations.adjuster.percentage) }} %
                </li>
            </ul>
            <hr />
            <div class="row">
                <div
                    class="col-3"
                    v-for="(step, index) in calcs.adjuster.steps"
                >
                    <div class="netprice-step">
                        <div class="text-bold">{{ step.index_year }}</div>
                        <ul class="mt-2">
                            <li>
                                {{ $t("cd.netprice_box_multiplier") }}:
                                {{ $n(step.multiplier, "full") }}
                            </li>
                            <li>
                                {{ $t("cd.netprice_box_index") }}:
                                {{ $n(step.net_price * 100 - 100) }} %
                            </li>
                            <li>
                                {{ $t("cd.netprice_box_used_min_perc") }}:
                                <template v-if="step.used_min_perc">{{
                                    $t("cd.yes")
                                }}</template>
                                <template v-else>{{ $t("cd.no") }}</template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
