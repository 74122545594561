import i18n from "@/i18n.js";
import countriesEN from "i18n-iso-countries/langs/en.json";
import countriesDA from "i18n-iso-countries/langs/da.json";
// countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
// countries.registerLocale(require("i18n-iso-countries/langs/da.json"));

export default {

    select() {
        const countries = (i18n.locale === 'da-DK') ? countriesDA.countries : countriesEN.countries;

        let data = [];
        Object.keys(countries).forEach(key => {
            data.push({
                value: key,
                label: countries[key]
            });
        });

        return data;
    },

    toCountry(code) {
        const countries = (i18n.locale === 'da-DK') ? countriesDA.countries : countriesEN.countries;

        return countries[code];
    }

}
