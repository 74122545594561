<script>
/**
 * Horizontal project component - summary.
 *
 */
import Cloner from "@/libraries/Cloner";
import BaseProgress from "@/components/BaseProgress";
import VDeadlineColor from "@/components/Widgets/VDeadlineColor";
import ProjectMixin from "@/mixins/ProjectMixin";
import CurrencyHumanizer from "@/util/MillionHumanizer";

export default {
    name: "VProject",
    components: { BaseProgress, VDeadlineColor },
    mixins: [ProjectMixin],
    props: {
        project: {},
        viewContext: {
            type: String,
            default: "project",
            description: "Display context: project (in list of projects), customer (list under a particular customer)"
        }
    },
    data() {
        return {
            loading: false,
            isMounted: false,
        };
    },
    computed: {
        /**
         * Budget usage in % for BaseProgress
         */
        progressBudgetUsage: function () {
            if (parseInt(this.project.perc_budget_usage) > 100) {
                return 100;
            } else {
                return this.project.perc_budget_usage;
            }
        },
        /**
         * Is title overflown -> for popover (show popover if CSS ellipsis in effect)
         */
        titleIsOverflown: function () {
            if (this.isMounted) {
                let element = this.$refs.projectTitle;
                return (
                    element.offsetHeight < element.scrollHeight ||
                    element.offsetWidth < element.scrollWidth
                );
            } else {
                return false;
            }
        },
        /**
         * Is customer name overflown -> for popover (show popover if CSS ellipsis in effect)
         */
        customerIsOverflown: function () {
            if (this.isMounted) {
                let element = this.$refs.projectCustomer;
                return (
                    element.offsetHeight < element.scrollHeight ||
                    element.offsetWidth < element.scrollWidth
                );
            } else {
                return false;
            }
        },
    },
    mounted() {
        // Trick for using $refs in computed properties
        this.isMounted = true;
    },
    created() {},
    methods: {
        humanize(money, currency) {
            return CurrencyHumanizer(money, currency);
        },
        navigate(where, id) {
            this.$router.push({
                name: where,
                params: {
                    project_id: id,
                },
            });
        },
    },
};
</script>

<template>
    <div class="v-project">
        <div class="v-project__body">
            <div class="row">
                <div v-if="false"
                    class="col-12 v-project__favorite"
                    @click="$emit('favorite', project.id, !project.is_favorite)"
                >
                    <i
                        class="fa-star m-click"
                        :class="{
                            'fas text-primary': project.is_favorite,
                            'fal no-favorite': !project.is_favorite,
                        }"
                    ></i>
                </div>
                <div
                    class="col-12 col-sm-6 v-project__group v-project__group--title"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.case_with_number", { id: project.id }) }}
                    </div>
                    <div class="v-project__value" ref="projectTitle">
                        <el-popover
                            placement="bottom-start"
                            trigger="hover"
                            :content="project.title"
                            :disabled="!titleIsOverflown"
                            :visible-arrow="false"
                        >
                            <span slot="reference">{{ project.title }}</span>
                        </el-popover>
                    </div>
                </div>
                <div
                    v-if="viewContext == 'project'"
                    class="col-12 col-sm-6 v-project__group v-project__group--customer"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.customer") }}
                    </div>
                    <div class="v-project__value" ref="projectCustomer">
                        <el-popover
                            placement="bottom-start"
                            trigger="hover"
                            :content="project.customer.name"
                            :disabled="!customerIsOverflown"
                            :visible-arrow="false"
                        >
                            <span slot="reference">
                                {{ project.customer.name }}
                            </span>
                        </el-popover>
                    </div>
                </div>
                
                <div
                    class="col-12 col-sm-2 v-project__group v-project__group--status"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.status") }}
                    </div>
                    <div class="v-project__value">
                        <span :class="statusColor">{{
                            project.status_name
                        }}</span>
                    </div>
                </div>
                <div
                    class="col-12 col-sm-4 v-project__group v-project__group--project-model"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.settlement_method") }}
                    </div>
                    <div class="v-project__value">
                        {{ project.project_model_name }}
                    </div>
                </div>
                <div
                    class="col-12 col-sm-4 v-project__group v-project__group--project-type"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.type") }}
                    </div>
                    <div class="v-project__value">
                        {{ project.project_type_name }}
                    </div>
                </div>
                                
                <div
                    v-if="false"
                    class="col-12 col-sm-4 v-project__group v-project__group--budget"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.budget") }}
                    </div>
                    <div class="v-project__value">
                        <template v-if="project.budget_amount > 0">
                            {{
                                humanize(
                                    project.budget_amount,
                                    project.currency
                                )
                            }}
                        </template>
                        <template v-else>---</template>
                    </div>
                </div>
                <div
                    v-if="false"
                    class="col-12 col-sm-4 v-project__group v-project__group--budget-usage"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.budget_usage") }}
                    </div>
                    <div class="v-project__value">
                        <div class="v-project__budget-usage">
                            <div class="v-project__budget-percent">
                                {{ project.perc_budget_usage }}%
                            </div>
                            <div class="v-project__budget-progress">
                                <base-progress
                                    class="progress-xs mb-0"
                                    :type="progressColor"
                                    :value="progressBudgetUsage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 col-sm-2 v-project__group v-project__group--deadline"
                    @click="navigate('ProjectViewTasks', project.id)"
                >
                    <div class="v-project__label">
                        {{ $t("project.deadline") }}
                    </div>
                    <div class="v-project__value">
                        <template v-if="project.deadline_date">
                            <v-deadline-color
                                :context="project.deadline_date"
                            />
                        </template>
                        <template v-else>---</template>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<style>
</style>