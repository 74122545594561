<script>
import { mapGetters } from "vuex";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VProjectFilter",
    props: {
        value: {
            description: "v-model support",
        },
    },
    components: {
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    data() {
        return {
            filterViews: {
                all: this.$t("project_filter.invoice_setup_BOTH"),
                meg: this.$t("project_filter.invoice_setup_MEG"),
                projects: this.$t("project_filter.invoice_setup_PROJECTS"),
            },
            currentFilterView: "",
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        invoiceSetup() {
            return this.value.invoice_setup;
        }
    },
    watch: {
        invoiceSetup: function (nV) {
            this.currentFilterView = nV;
        }
    },
    created() {
        this.currentFilterView = this.value.invoice_setup;
    },
    methods: {
        handleInput() {
            if (parseInt(this.value) > 0) {
                //this.$emit("input", null);
            } else {
                //this.$emit("input", this.getMyId);
            }
        },
        handleCommand(cmd) {

            let copy = Cloner.clone(this.value);
            this.currentFilterView = cmd;

            if (cmd === 'all') {
                delete copy.invoice_setup;
            } else {
                copy.invoice_setup = cmd;
            }

            console.log("VDept, copy", copy);

            this.$emit("input", copy);
        },
        showCheck(idx) {
            if (idx == "null") {
                idx = null;
            }

            if (idx == this.currentFilterView) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<template>
    <div>
        <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
                <i class="far fa-warehouse"></i>

                <template v-if="currentFilterView">
                    <span class="text-default">{{
                        filterViews[currentFilterView]
                    }}</span></template
                >
            </span>
            <el-dropdown-menu slot="dropdown">
                <!-- Hash object {a:b, c:d ...} -->

                <el-dropdown-item
                    v-for="(filter, index) in filterViews"
                    :key="index"
                    :command="index"
                >
                    {{ filter }}
                    <i v-if="showCheck(index)" class="far fa-check"></i>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<style lang="scss">
.v-favorite-filter {
    i.fal {
        color: rgb(96, 98, 102);
    }

    .fav-label {
        margin-left: 3px;
        color: rgb(96, 98, 102);
        font-weight: 400;
    }
}
.el-dropdown-link i {
    margin-right: 5px;
}
</style>
