<script>
export default {
    name: "MultiStat",
    props: {
        header: {
            type: String,
        },
        boxClass: {            
            default: ""
        }
    },
};
</script>


<template>
    <div class="multi-stat" :class="boxClass">
        <div class="multi-stat__header">
            {{ header }}
        </div>

        <div class="multi-stat__body">
            <slot />
        </div>

        <div class="multi-stat__footer" @click="$emit('click-footer')">
            <slot name="footer" />
        </div>
    </div>
</template>