<script>
import InvoiceService from "@/services/InvoiceService";
import { pick } from "lodash";

export default {
    name: "VManualInvoiceForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        contract: {
            description: "Contract object",
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            invoice: {
                id: null,
                currency: null,
                total_net_amount: null,
            },
        };
    },
    created() {

        if (!this.context.empty) {
            console.log("VManualInvoiceForm edit", this.context);
            this.editing = true;
            this.invoice = pick(this.context, [
                "id",
                "currency",
                "total_net_amount",
            ]);
        }
    },
    methods: {
        async saveForm() {
            try {
                let data = {
                    id: this.invoice.id,
                    currency: this.contract.currency,
                    total_net_amount: this.invoice.total_net_amount,
                    contract_id: this.contract.id
                };

                const r = await InvoiceService.PUT_ManualInvoice(
                    data,
                    this.contract.id
                );
                this.$emit("saved");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>

<template>
    <modal :show="true" @close="$emit('close')" class="manual-invoice-modal">
        <template slot="header">
            Omsætning for 2020
        </template>

        <template slot="default">
            <FormulateForm v-model="invoice" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="total_net_amount"
                            type="currency"
                            :currency="contract.currency"
                            label="Omsætning for 2020"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>
